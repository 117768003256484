import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "assets/css/App.css";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import PricingPage from "layouts/pricing";
import { Router } from "react-router-dom";
import SignUp from "views/auth/signup";
import DataSets from "layouts/datasets";
import SignIn from "views/auth/signIn";
import PriceList from "layouts/pricing/pricinglist";
import UserBilling from "layouts/userprofile/components/userbilling";
import Voices from "layouts/voices";
import Dashboard from "layouts/admin";
import { Provider } from "react-redux";
import store from "./reduxStore/store";
// toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "navigation";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Suspense>
        <Provider store={store}>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Navigation />

          {/* comment */}
          {/* <React.StrictMode> */}
          {/* <ThemeEditorProvider> */}
          {/* <BrowserRouter> */}
          {/* <Routes>
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          <Navigate from="/" to="/admin" />
          <Route path="/signup" component={SignUp} />
          <Route path="/datasets" element={<DataSets />} />
        </Routes> */}
          {/* <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/admin" element={<AdminLayout />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/user-billing" element={<UserBilling />} />
          <Route path="/datasets" element={<DataSets />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/voiceclone" element={<Voices />} /> */}

          {/* <Route
            path="/auth"
            element={<Navigate to="/auth/sign-up/default" replace />}
          /> */}
          {/* <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes>
        </Provider>
        </Suspense>
      </BrowserRouter>
    </React.StrictMode> */}
          {/* comment end */}
        </Provider>
      </Suspense>
    </BrowserRouter>
  </ChakraProvider>
);
// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import React from "react";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import {
  MdNotificationsNone,
  MdInfoOutline,
  MdRecordVoiceOver,
  MdSettingsVoice,
} from "react-icons/md";
import { FaEthereum, FaRegUser } from "react-icons/fa";
import routes from "routes.js";
import { ThemeEditor } from "./ThemeEditor";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import {
  IoDocument,
  IoDocumentOutline,
  IoLanguageOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import Card from "components/card/Card";
import {
  RiBillLine,
  RiUserLocationFill,
  RiVoiceRecognitionFill,
} from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";
import { useRouter } from "components/use-hook";
import { userLogout } from "reduxStore/action/authAction";
import { useDispatch, useSelector } from "react-redux";
export default function HeaderLinks(props) {
  const { secondary } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const { userToken, userDetail } = useSelector(state => state.login)

  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  // const Logout = () => {
  //   // setOpen(null);
  //   dispatch(userLogout());
  //   localStorage.clear();
  //   router.push('/sign-in')
  // };

  const Logout = () => {
    // Get the value of the 'keepLoggedIn' key
    const keepLoggedIn = localStorage.getItem('keepLoggedIn');
    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    
    // Clear all local storage
    localStorage.clear();
  
    // Restore the 'keepLoggedIn' key after clearing
    if (keepLoggedIn) {
      localStorage.setItem('keepLoggedIn', keepLoggedIn);
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    }
  
    // Dispatch the logout action
    dispatch(userLogout());
  
    // Navigate to the sign-in page
    router.push('/sign-in');
  };

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      // bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      justifyContent={{ base: 'center' }}
    // boxShadow={shadow}
    >
      {/* <SearchBar
        mb={secondary ? {base: '10px', md: 'unset'} : 'unset'}
        me="10px"
        borderRadius="30px"
      /> */}
      <Flex
        bg={ethBg}
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: "none", md: "unset" }}>
            {" "}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={routes} />
      {/* <NavLink
        to="/datasets"
        style={({ isActive }) => ({
          textDecoration: "none",
          color: isActive ? "blue" : "gray", // Apply active styles
          margin: "0 10px",
        })}
      >
        <Button variant="outline" colorScheme="teal">
          Datasets
        </Button>
      </NavLink> */}
      {/* <Box mx="2">
        <Tooltip label='Datasets' fontSize='sm'>
          <Button
            h='40px'
            w='40px'
            zIndex='99'
            // position='fixed'
            // variant='no-effects'
            // left={document.documentElement.dir === "rtl" ? "35px" : ""}
            // right={document.documentElement.dir === "rtl" ? "" : "70px"}
            // bottom='20px'
            border='1px solid'
            borderColor='#6A53FF'
            borderRadius='50px'
            display='flex'
            p='0px'
            align='center'
            onClick={() => router.push("/datasets")}
            justify='center'>
            <IoDocumentOutline
              h='18px'
              w='18px'
            />
          </Button>
        </Tooltip>
      </Box>
      <Box mx="2">
        <Tooltip label='Voice Cloning' fontSize='sm'>
          <Button
            h='40px'
            w='40px'
            zIndex='99'
            // position='fixed'
            // 5653232
            // left={document.documentElement.dir === "rtl" ? "35px" : ""}
            // right={document.documentElement.dir === "rtl" ? "" : "70px"}
            // bottom='20px'
            border='1px solid'
            borderColor='#6A53FF'
            borderRadius='50px'
            display='flex'
            p='0px'
            align='center'
            onClick={() => router.push("/voiceclone")}
            justify='center'>
            <MdSettingsVoice
              h='18px'
              w='18px'
            />
          </Button>
        </Tooltip>
      </Box> */}
      <Menu>
        <MenuButton
          h="40px"
          w="40px"
          zIndex="99"
          border="1px solid"
          borderColor="#6A53FF"
          mx="3"
          borderRadius="50px"
        >
          <Icon
            h="18px"
            as={IoLanguageOutline}
            w="18px"
            display="flex"
            m="auto"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
        // me={{base: '30px', md: 'unset'}}
        // minW={{base: 'unset', md: '400px', xl: '450px'}}
        // maxW={{base: '360px', md: 'unset'}}
        >
          <Flex jusitfy="space-between" w="100%" m="10px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Languages
            </Text>
            {/* <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Languages
            </Text> */}
          </Flex>
          {/* <MenuItem
              _hover={{bg: 'none'}}
              _focus={{bg: 'none'}}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon UI Dashboard PRO" aName="Alicia" />
            </MenuItem> */}

          <ItemContent />
        </MenuList>
      </Menu>

      {/* <Menu>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdInfoOutline}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          me={{base: '30px', md: 'unset'}}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          minW={{base: 'unset'}}
          maxW={{base: '360px', md: 'unset'}}
        >
          <Image src={navImage} borderRadius="16px" mb="28px" />
          <Flex flexDirection="column">
            <Link
              w="100%"
              href="https://horizon-ui.com/pro?ref=horizon-chakra-free"
            >
              <Button w="100%" h="44px" mb="10px" variant="brand">
                Buy Horizon UI PRO
              </Button>
            </Link>
            <Link
              w="100%"
              href="https://horizon-ui.com/documentation/docs/introduction?ref=horizon-chakra-free"
            >
              <Button
                w="100%"
                h="44px"
                mb="10px"
                border="1px solid"
                bg="transparent"
                borderColor={borderButton}
              >
                See Documentation
              </Button>
            </Link>
            <Link
              w="100%"
              href="https://github.com/horizon-ui/horizon-ui-chakra"
            >
              <Button
                w="100%"
                h="44px"
                variant="no-hover"
                color={textColor}
                bg="transparent"
              >
                Try Horizon Free
              </Button>
            </Link>
          </Flex>
        </MenuList>
      </Menu> */}

      <FixedPlugin />

      {/* <ThemeEditor navbarIcon={navbarIcon} /> */}
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name="Adela Parkson"
            bg="#11047A"
            src="https://images.unsplash.com/photo-1506863530036-1efeddceb993?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2244&q=80"
            size="sm"
            w="40px"
            h="40px"
            ml="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          border="none"
        >
          <Flex w="100%" p="3" pb="0">
            <Avatar
              bg="#11047A"
              size="sm"
              m="8px"
              mt="4px"
              src="https://images.unsplash.com/photo-1506863530036-1efeddceb993?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2244&q=80"
            />
            <Text
              fontSize="md"
              fontWeight="600"
              color={textColor}
              lineHeight="20px"
            > {userDetail?.firstname}{" "}
              <Text fontSize="sm" fontWeight="500" color={textColor}>
              {userDetail?.email}
              </Text>
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* <MenuItem
              borderRadius="8px"
              icon={<IoDocumentOutline />}
              px="14px"
              onClick={() => router.push("/datasets")}
            >
              <Text fontSize="sm">DataSets</Text>
            </MenuItem>
            <MenuItem
              borderRadius="8px"
              icon={<RiVoiceRecognitionFill />}
              px="14px"
              onClick={() => router.push("/voiceclone")}
            >
              <Text fontSize="sm">Voice Cloning</Text>
            </MenuItem> */}
            <MenuItem
              borderRadius="8px"
              icon={<RiBillLine />}
              px="14px"
              onClick={() => router.push("/pricing")}
            >
              <Text fontSize="sm">Billing</Text>
            </MenuItem>
            <MenuItem
              borderRadius="8px"
              px="14px"
              icon={<IoSettingsOutline />}
              onClick={() => router.push("/user-profile")}
            >
              <Text fontSize="sm">Settings</Text>
            </MenuItem>
            <MenuItem
              color="red.400"
              borderRadius="8px"
              icon={<IoMdLogOut />}
              px="14px"
              onClick={Logout}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

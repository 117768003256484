import { combineReducers } from 'redux';
import { authStore } from './authReducer';
import { LOGOUT } from '../type';

const appReducer = combineReducers({
  login: authStore,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    const resetState = {};
    Object.keys(state).forEach((key) => {
      resetState[key] = undefined;
    });
    // var selectElement = document.querySelector('#google_translate_element select');
    // selectElement.value = 'en';
    // selectElement.dispatchEvent(new Event('change'));
    return appReducer(resetState, action);
  }
  return appReducer(state, action);
};




// if (action.type === LOGOUT) {
//   const resetState = {};
//   Object.keys(state).forEach((key) => {
//     if(key === 'languageReducer'){

//     } else {
//       resetState[key] = undefined;
//     }
//   });
//   console.log('resetState: ', resetState);
//   var selectElement = document.querySelector('#google_translate_element select');
//   selectElement.value = 'en';
//   selectElement.dispatchEvent(new Event('change'));
//   return appReducer(resetState, action);
// }
// return appReducer(state, action);


export default rootReducer;

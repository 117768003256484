const apiEndPoint = {
    LOGIN: 'login',
    CHECKEMAIL: 'checkemail',
    REGISTER: 'signup',
    VOICEPARAMETER:'voice_generation_parameters',
    VOICECREATE:'voice_generation',
    VOICECLONE:'voice_cloning',
    VOICELIST:'voice_details',
    UPDATEPASSWORD:'/update-password',
    FORGETPASSWORDREQUEST:'/forget-password-request',
    SAVEPASSWORD:'/save-password',
    ENHANCENEWS:'/enhance_news',
    DUPLICATENEWS:'/duplicate_news',
    SIGNUPWITHGOOGLE:'/signup/google',
    GETVOICE:'/get_voice',
    SPEECHTOTEXTGENERATION:'/speech_generation',
    SPEECHTOSPEECHGENERATION:'speech_to_speech',
    GENERATEDSPEECH:'/generated_speech',
    SPECIFICVOICEDETAIL:'/specific_voice_detail',
    HISTORYVOICEDETAIL: 'specific_speech_detail',
    VOICEGENERATIONOPTIONS:'/voice_generation_options',
    VOICECLONINGOPTIONS:'voice_cloning_options',
    GENERATEDSPEECHPARAMETER:'/generated_speech_parameter',
    UPLOADDATASET:'dataset_upload',
    DATASETLIST:'dataset_details',
    EDITDELETEDATASET:'dataset_options',
    DELETEACCOUNT:'delete_account',
    AUDIOTRANSCRIBEPARAMETER:'asr_parameters',
    ADDAUDIOTRANSCRIBE:'asr_generation',
    GETTRANSCRIBEDAUDIO:'asr_generation_list',
    GETTRANSCRIBEDAUDIODETAILS:'specific_asr_generation',
    GETTRANSCRIBEDAUDIODETAILSWITHTIME:'specific_asr_generation_timestamp',
    UPDATEDELETETRANSCRIBEDAUDIO: 'asr_generation_options',
    EDITTRANSCRIBEDTEXT:'edited_ASR',
    GETDUBBINGPARAMETER:'dubbed_parameter',
    CREATEDUBBING:'dubbed_file',
    GETDUBBEDFILE:'dubbed_file_details',
    DELETEDUBBEDFILE:'dubbed_file_options',
    CHANGEPASSWORD:'change_password',
    UPDATEPROFILE:'update_profile',
}

export default apiEndPoint

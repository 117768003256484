import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Checkbox,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useRef, useState } from "react";
import { ImUpload } from "react-icons/im";
import { IoCloudUploadOutline, IoCopyOutline, IoFishSharp, IoLogoAmplify } from "react-icons/io5";
// Assets
import { MdAlternateEmail, MdCheckCircle, MdSettings, MdTranslate, MdUpload } from "react-icons/md";
import { RiFileUploadLine } from "react-icons/ri";
import Dropzone from "views/admin/profile/components/Dropzone";
import { GiDolphin, GiTiger } from "react-icons/gi";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import AudioRecentfile from "./components/fileuploaded";
import AudioTranslations from "./components/audiotranslations";
import { FaFileAudio, FaLanguage } from "react-icons/fa";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { ChevronDownIcon } from "@chakra-ui/icons";
import { BsStopwatch } from "react-icons/bs";
import AddTranscribeFile from "./components/addtranscribefile";
import { apiCall } from "utils/httpClient";
import apiEndPoint from "utils/apiEndPoint";
import { useSelector } from "react-redux";
import { isEmpty } from "utils/utils";
import { failedMessage } from "utils/httpClient";
import { successMessage } from "utils/httpClient";
export default function AiAudioTrans(props) {
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [showRow, setShowRow] = useState(false);
  const [selectedGender, setSelectedGender] = useState('Select gender');
  const { userToken } = useSelector(state => state.login)

  const [parameterLoading, setParameterLoading] = useState(false);
  const [allParameter, setAllParameter] = useState(null);
  const [formDetail, setFormDetail] = useState(null);
  console.log('formDetail:1111111111 ', formDetail);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [transcribedList, setTranscribedList] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const buttonRef = useRef(null);

  const getAudioTranscribeParameter = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setParameterLoading(true);
    const { data } = await apiCall('get', apiEndPoint.AUDIOTRANSCRIBEPARAMETER, params, header);
    if (data.status !== 200) {
      setAllParameter(null);
    } else {
      setAllParameter(data?.data);
    }
    setParameterLoading(false);
  }

  const getTranscribedAudio = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setListLoading(true);
    const { data } = await apiCall('get', apiEndPoint.GETTRANSCRIBEDAUDIO, params, header);
    if (data.status !== 200) {
      setTranscribedList(null);
    } else {
      setTranscribedList(data?.asr_generations);
    }
    setListLoading(false);
  }

  const validation = () => {
    let isError = true;

    if (isEmpty(formDetail?.name) || isEmpty(formDetail?.audio_sample) || isEmpty(formDetail?.language) || isEmpty(formDetail?.model) ) 
      {
        isError = false;
        failedMessage ("Please Fill Name, Choose Audio File, Language and Model")
      } 
    return isError;
  }
  
  const handleAddAudioTranscribe = async () => {
    if (validation()) {
      const header = { "Authorization": `${userToken}` }
      const form = new FormData();
      form.append("name", formDetail?.name);
      form.append("language", formDetail?.language);
      form.append("model", formDetail?.model);
      form.append("english_transcription", formDetail?.english_transcription ? true : false);
      if (formDetail?.audio_sample) {
        form.append("audio_sample", formDetail.audio_sample);
      }
      setSubmitLoading(true);
      const { data } = await apiCall('post', apiEndPoint.ADDAUDIOTRANSCRIBE, form, header);
      if (data.status !== 200) {
        
      failedMessage(data?.message);
      } else {
        successMessage(data?.message);
        setFormDetail(null);
        getTranscribedAudio();
        onClose();
        if (buttonRef.current) {
        buttonRef.current.click();
      }
      }
      setSubmitLoading(false);
    }
  }

  useEffect(() => {
    getAudioTranscribeParameter();
    getTranscribedAudio();
  }, []);

  return (
    <Box mt={'10rem'}>
      {/* {showRow &&
        <Box mt={5}>
        </Box>
      }

      {showRow === false &&
      } */}
      <Box>
        <Box m={8}>
          <Tabs>
            <TabList>
              <Tab>Generate</Tab>
              <Tab ref={buttonRef} >History</Tab>
              {/* <Tab>Uploaded Audio</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex justifyContent={'space-around'} mt={20}>
                  <Card w={{ base: "100%", md: "75%", xl: "50%" }} m={'auto'}>
                    <Text my={4} textAlign={'center'} fontSize={'2xl'} fontWeight={'500'}>Welcome to Audio Transcription</Text>
                    <Box textAlign={'center'}>
                      <Dropzone onClick={onOpen}
                        w={{ base: "100%", "2xl": "100%" }}
                        maxH={{ base: "250px", lg: "250px", "2xl": "250px" }}
                        minH={{ base: "250px", lg: "250px", "2xl": "268px" }}
                        content={
                          <Box>
                            <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                            <Flex justify="center" mx="auto" mb="12px">
                            </Flex>
                            <Flex alignItems={'center'} justifyContent={'center'} my={8}>
                              <ImUpload fontSize={14} />
                              <Text ml={3} fontSize="xs" fontWeight="400" >
                                Drag and drop files to upload them into AIPAJTI
                              </Text>
                            </Flex>
                          </Box>
                        }

                        onFileUpload={(file) => { setFormDetail({
                          ...formDetail,
                          audio_sample: file
                      }); onOpen();}}
                      
                      />
                      <Button leftIcon={<RiFileUploadLine />} mt={4} variant='outline'>
                        <Text fontSize="sm" fontWeight="400" >
                          Transcribe Your First File
                        </Text>
                      </Button>
                    </Box>
                  </Card>
                  <AddTranscribeFile isOpen={isOpen} onClose={onClose} allParameter={allParameter} parameterLoading={parameterLoading} setFormDetail={setFormDetail} formDetail={formDetail} handleAddAudioTranscribe={handleAddAudioTranscribe} submitLoading={submitLoading} />
                </Flex>
              </TabPanel>
              <TabPanel>
                <AudioRecentfile transcribedList={transcribedList} listLoading={listLoading} getTranscribedAudio={getTranscribedAudio} />
              </TabPanel>
              {/* <TabPanel>
                  <AudioTranslations />
                </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
}
import { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Divider,
  TableContainer,
  useColorModeValue, Image
} from '@chakra-ui/react';
import { FiDelete, FiMoreVertical } from 'react-icons/fi';
import { MdReport } from 'react-icons/md';
import { CiFileOn } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import { CiEdit, CiShare2, CiTrash } from "react-icons/ci";
import { IoIosInfinite } from 'react-icons/io';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { FiDownload } from "react-icons/fi";
import { BsFiletypeDocx, BsFiletypeTxt, BsFiletypePdf, BsFiletypeCsv, BsStopwatch } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { isEmpty } from 'utils/utils';
import { truncateText } from 'utils/utils';
import AudioTranslations from '../audiotranslations';
import { useSelector } from 'react-redux';
import apiEndPoint from 'utils/apiEndPoint';
import { apiCall } from 'utils/httpClient';
import { successMessage } from 'utils/httpClient';
import { failedMessage } from 'utils/httpClient';
import { SpinnerIcon } from '@chakra-ui/icons';
import NoDataFound from '../../../../assets/img/no_found.png';

const AudioRecentfile = (props) => {
  const { listLoading, transcribedList, getTranscribedAudio } = props
  const { userToken } = useSelector(state => state.login)
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  console.log('selectedData:11111111 ', selectedData);
  const [dataLoading, setDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [showDetails, setShowDetails] = useState(false);
  const [newName, setNewName] = useState(null);
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );

  // State management for modal 2
  const { isOpen: isOpenModal1, onOpen: onOpenModal1, onClose: onCloseModal1 } = useDisclosure();
  const { isOpen: isOpenModal2, onOpen: onOpenModal2, onClose: onCloseModal2 } = useDisclosure();
  const { isOpen: isOpenModal3, onOpen: onOpenModal3, onClose: onCloseModal3 } = useDisclosure();
  const { isOpen: isOpenModal4, onOpen: onOpenModal4, onClose: onCloseModal4 } = useDisclosure();
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const handleShowDetails = (itemData) => {
    getTranscribedDetails(itemData?.name);
    setShowDetails(true)
  }

  const getTranscribedDetails = async (Itemname) => {
    console.log('Itemname: ', Itemname);
    const params = { name: Itemname };
    const header = { "Authorization": `${userToken}` }
    setDataLoading(true);
    const { data } = await apiCall('post', apiEndPoint.GETTRANSCRIBEDAUDIODETAILSWITHTIME, params, header);
    if (data.status !== 200) {
      setSelectedData(null);
    } else {
      setSelectedData(data);
    }
    setDataLoading(false);
  }

  const handleEditDelete = async (type) => {

    if (type === 'edit') {
      if (!isEmpty(selectedItem?.name)) {
        var params = { action: 'edit', name: selectedItem?.name, new_name: newName };
      } else {
        failedMessage("Please Fill New Name");
      }
    } else {
      if (type === 'delete') {
        var params = { action: 'delete', name: selectedItem?.name };
      } else {
        var params = {};
      }
    }
    const header = { "Authorization": `${userToken}` }
    setIsLoading(true);
    const { data } = await apiCall('post', apiEndPoint.UPDATEDELETETRANSCRIBEDAUDIO, params, header);
    if (data.status !== 200) {
      failedMessage(data?.message);
    } else {
      successMessage(data?.message);
      onCloseModal3();
      onCloseModal2();
      setNewName(null);
      setSelectedItem(null);
      getTranscribedAudio();
    }
    setIsLoading(false);
  }


  return (
    <>
      {showDetails ? <AudioTranslations showDetails={showDetails} setShowDetails={setShowDetails} selectedData={selectedData} setSelectedData={setSelectedData} dataLoading={dataLoading} getTranscribedAudio={getTranscribedAudio} /> :
        <Box m={2}>
          <Flex justifyContent='space-between'>
            <Text my={4} fontSize={'xl'} fontWeight={'500'}>Recent Files</Text>
            {/* <Button leftIcon={<IoIosInfinite />} mt={4} variant='outline'>
          <Text fontSize="sm" fontWeight="400" >
            Go Unlimited
          </Text>
        </Button> */}
          </Flex>
          {isEmpty(transcribedList) ?

            <center>
              <Image src={NoDataFound} width={200} />
              <Text fontSize="16" fontWeight="600" mt={5} >
                No Any Transcribed Data Available.
              </Text>
            </center> :
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Transcribed Data</Th>
                    <Th>Duration</Th>
                    <Th>Mode</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>

                  {transcribedList.map((item, i) => (
                    <Tr key={i} cursor={"pointer"} _hover={bgHover}>
                      <Td onClick={() => { handleShowDetails(item) }} >
                        {!isEmpty(item?.name) ? truncateText(item?.name, 30) : '---'}
                      </Td>
                      <Td onClick={() => { handleShowDetails(item) }}>
                        {!isEmpty(item?.transcribed_text) ? truncateText(item?.transcribed_text, 35) : '---'}
                      </Td>
                      <Td onClick={() => { handleShowDetails(item) }}>
                        {!isEmpty(item?.duration) ? item?.duration : '---'}
                      </Td>
                      <Td onClick={() => { handleShowDetails(item) }} >
                        {!isEmpty(item?.model) ? item?.model?.replace(/_/g, ' ') : '---'}
                      </Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<FiMoreVertical />}
                            variant="outline"
                            size="xs"
                          />
                          <MenuList>
                            <MenuItem onClick={() => { handleShowDetails(item) }}>
                              <CiFileOn style={{ marginRight: '5px' }} /> Open Transcript
                            </MenuItem>
                            {/* <MenuItem onClick={onOpenModal1}>
                              <CiExport style={{ marginRight: '5px' }} />
                              Export Transcript
                              <Modal isOpen={isOpenModal1} onClose={onCloseModal1} isCentered>
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalCloseButton />
                                  <ModalHeader textAlign={'center'}>
                                    <Text fontSize="16" fontWeight="600" >
                                      Export 1 Transcript
                                    </Text>
                                  </ModalHeader>
                                  <ModalBody>
                                    <Text textAlign={'center'} fontSize="14" fontWeight="400" >
                                      Choose one or more formats.
                                    </Text>
                                    <Box mt={6}>
                                      <Flex my={2}>
                                        <Checkbox defaultChecked />
                                        <Button leftIcon={<BsFiletypePdf style={{ fontSize: '25px' }} />} variant='ghost'>
                                          <Text fontSize="sm" fontWeight="400" >
                                            Export as PDF
                                          </Text>
                                        </Button>
                                      </Flex>
                                      <Flex my={2}>
                                        <Checkbox />
                                        <Button leftIcon={<BsFiletypeDocx style={{ fontSize: '25px' }} />} variant='ghost'>
                                          <Text fontSize="sm" fontWeight="400" >
                                            Export as DOCX
                                          </Text>
                                        </Button>
                                      </Flex>
                                      <Flex my={2}>
                                        <Checkbox />
                                        <Button leftIcon={<BsFiletypeTxt style={{ fontSize: '25px' }} />} variant='ghost'>
                                          <Text fontSize="sm" fontWeight="400" >
                                            Export as TXT
                                          </Text>
                                        </Button>
                                      </Flex>
                                      <Flex my={2}>
                                        <Checkbox defaultChecked />
                                        <Button leftIcon={<BsFiletypeCsv style={{ fontSize: '25px' }} />} variant='ghost'>
                                          <Text fontSize="sm" fontWeight="400" >
                                            Export as CSV
                                          </Text>
                                        </Button>
                                      </Flex>
                                      <Flex my={2}>
                                        <Checkbox />
                                        <Button leftIcon={<IoCopyOutline style={{ fontSize: '20px' }} />} variant='ghost'>
                                          <Text fontSize="sm" fontWeight="400" >
                                            Export as SRT
                                          </Text>
                                        </Button>
                                      </Flex>
                                      <Flex my={2}>
                                        <Checkbox />
                                        <Button leftIcon={<IoCopyOutline style={{ fontSize: '20px' }} />} variant='ghost'>
                                          <Text fontSize="sm" fontWeight="400" >
                                            Export as VTT
                                          </Text>
                                        </Button>
                                      </Flex>
                                    </Box>
                                    <Box mt={2}>
                                      <Text textAlign={'center'} fontSize="14" fontWeight="400" >
                                        Settings
                                      </Text>
                                      <Flex my={2}>
                                        <Checkbox />
                                        <Box>
                                          <Button leftIcon={<BsStopwatch style={{ fontSize: '16px' }} />} variant='none'>
                                            <Text fontSize="sm" fontWeight="400" >
                                              Section Timestamps
                                            </Text>
                                          </Button>
                                          <Text fontSize="11" ml={4} fontWeight="400" >
                                            Add timestamps before each section in PDF, DOCX, and TXT formats.
                                          </Text>
                                        </Box>
                                      </Flex>
                                    </Box>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button onClose={onCloseModal1} isFullWidth leftIcon={<FiDownload style={{ fontSize: '18px' }} />}  >
                                      <Text fontSize="sm" fontWeight="400" >
                                        DOWNLOAD
                                      </Text></Button>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal>
                            </MenuItem> */}
                            <MenuItem onClick={() => { setSelectedItem(item); onOpenModal2(); }}>
                              <CiEdit style={{ marginRight: '5px' }} />
                              Rename File
                              <Modal isOpen={isOpenModal2} onClose={onCloseModal2} isCentered>
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalCloseButton />
                                  <ModalHeader textAlign={'center'}>
                                    <Flex direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                      <CiEdit style={{ marginRight: '8px' }} />
                                      <Text fontSize="16" fontWeight="600" >
                                        Rename File
                                      </Text>
                                    </Flex>
                                  </ModalHeader>
                                  <ModalBody>
                                    <Text fontSize="14" fontWeight="400" >
                                      Name
                                    </Text>
                                    <Input placeholder='Rename here..' size='md' onChange={(e) => setNewName(e.target.value)} color={textColor} />
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button onClick={() => onCloseModal2()} isFullWidth disabled={isLoading} mr={2}>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Cancel
                                      </Text></Button>
                                    <Button
                                      onClick={() => handleEditDelete('edit')}
                                      isFullWidth
                                    >
                                      <Text fontSize="sm" fontWeight="400" >
                                        {isLoading ? <SpinnerIcon size={"md"} /> : 'Rename File'}
                                      </Text></Button>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal>
                            </MenuItem>
                            {/* <MenuItem onClick={onOpenModal4}>
                          <CiShare2 style={{ marginRight: '5px' }} />
                          Share Transcript
                          <Modal isOpen={isOpenModal4} onClose={onCloseModal4} isCentered>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalCloseButton />
                              <ModalHeader textAlign={'center'}>
                                <Flex direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                  <CiShare2 style={{ marginRight: '8px' }} />
                                  <Text fontSize="16" fontWeight="600" >
                                    Share Transcript
                                  </Text>
                                </Flex>
                              </ModalHeader>
                              <ModalBody>
                                <Text fontSize="14" fontWeight="400" >
                                  Anyone with the following secure link can view this transcript, the
                                  associated media file, and your email address
                                  (loremipsuem@gmail.com).
                                </Text>
                                <Box mt={4}>
                                  <Text fontSize="14" fontWeight="400" >
                                    Secure Link
                                  </Text>
                                  <Input placeholder='https://turboscribe.ai/transcript/share/79263353441' size='md' />
                                </Box>
                              </ModalBody>
                              <ModalFooter>
                                <Button onClose={onCloseModal1} isFullWidth leftIcon={<IoCopyOutline style={{ fontSize: '18px' }} />}  >
                                  <Text fontSize="sm" fontWeight="400" >
                                    COPY LINK
                                  </Text></Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </MenuItem> */}
                            <MenuItem onClick={() => { setSelectedItem(item); onOpenModal3(); }}>
                              <CiTrash style={{ marginRight: '5px' }} />
                              Delete File
                              <Modal isOpen={isOpenModal3} onClose={onCloseModal3} isCentered>
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalCloseButton />
                                  <ModalHeader textAlign={'center'}>
                                    <Flex direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                      <MdDeleteOutline style={{ marginRight: '8px' }} />
                                      <Text fontSize="16" fontWeight="600" >
                                        Delete File
                                      </Text>
                                    </Flex>
                                  </ModalHeader>
                                  <ModalBody>
                                    <Text fontSize="14" fontWeight="400" >
                                      Are you sure you want to delete this Transcribed Audio
                                      <b> {selectedItem?.name} {' '}</b> ?
                                    </Text>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button onClick={() => onCloseModal3()} isFullWidth disabled={isLoading} mr={2}>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Cancel
                                      </Text></Button>
                                    <Button onClick={() => handleEditDelete('delete')} isFullWidth>
                                      <Text fontSize="sm" fontWeight="400" >
                                        {isLoading ? <SpinnerIcon size={"md"} /> : 'Delete File'}
                                      </Text></Button>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          }
        </Box>
      }
    </>
  );
};

export default AudioRecentfile;
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.gif";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { apiCall } from "utils/httpClient";
import apiEndPoint from "utils/apiEndPoint";
import { useRouter } from "components/use-hook";
import { useDispatch } from "react-redux";
import { signUpDetailsUpdate } from "reduxStore/action/authAction";
import { failedMessage } from "utils/httpClient";
import { isEmpty } from "utils/utils";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue({ bg: "gray.200" },{ bg: "whiteAlpha.300" } );
  const googleActive = useColorModeValue( { bg: "secondaryGray.300" },{ bg: "whiteAlpha.200" });
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const router = useRouter();
  const reduxDispatch = useDispatch();

  async function handleInput(item) {
    var valuess = {
      ...formData,
      [item.target.name]: item.target.value,
    };
    setFormData(valuess);
  }

  const validation = () => {
    let isError = true;
    let errorMessage = {};
    if (isEmpty(formData?.firstname)) {
      isError = false;
      errorMessage["firstname"] = "Please Enter Full Name";
    }
    if (isEmpty(formData?.lastname)) {
      isError = false;
      errorMessage["lastname"] = "Please Enter Full Name";
    }

    if (isEmpty(formData?.email)) {
      isError = false;
      errorMessage["email"] = "Please Enter Email ID";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData?.email)) {
      isError = false;
      errorMessage["email"] = "Invalid Email address";
    }

    if (isEmpty(formData?.password)) {
      isError = false;
      errorMessage["password"] = "Please Enter Password";
    }
    setErrorMessage(errorMessage);
    return isError;
  };

  const handleSignUp = async () => {
    if (validation()) {
      setIsLoading(true);
      const { data } = await apiCall('post', apiEndPoint.REGISTER, formData)
      if (data.status!==200) {
        failedMessage(data?.message)
      } else {
        await reduxDispatch(signUpDetailsUpdate(formData))
        router.push('/verify-email');
      }
      setIsLoading(false);
    }
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign Up
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Create an account
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign up with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <Flex gap={6}>
              <Box>
              <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              First name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              mb='24px'
              fontWeight='500'
              size='lg'
              name="firstname"
              onChange={handleInput}
              error={isEmpty(formData?.firstname) && !isEmpty(errorMessage?.firstname) && true}
              value={formData?.firstname}
              placeholder={isEmpty(formData?.firstname) && !isEmpty(errorMessage?.firstname) ? errorMessage?.name: "First Name"}
            />
              </Box>
              <Box>
              <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Last name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              mb='24px'
              fontWeight='500'
              size='lg'
              name="lastname"
              onChange={handleInput}
              error={isEmpty(formData?.lastname) && !isEmpty(errorMessage?.lastname) && true}
              value={formData?.lastname}
              placeholder={isEmpty(formData?.lastname) && !isEmpty(errorMessage?.lastname) ? errorMessage?.name: "Last Name"}
            />
              </Box>
            </Flex>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              mb='24px'
              fontWeight='500'
              size='lg'
              name="email"
              value={formData?.email} default
              onChange={handleInput}
              error={isEmpty(formData?.email) && !isEmpty(errorMessage?.email) && true}
              placeholder={isEmpty(formData?.email) && !isEmpty(errorMessage?.email) ? errorMessage?.email: "mail@Pajti.com"}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                name="password"
                onChange={handleInput}
                value={formData?.password}
                error={isEmpty(formData?.password) && !isEmpty(errorMessage?.password) && true}
                placeholder={isEmpty(formData?.password) && !isEmpty(errorMessage?.password) ? errorMessage?.password : "Min. 8 characters"}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {/* <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleSignUp}
              >
              Sign Up
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              If you have an account
              <NavLink to='/sign-in'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Login here
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;

import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Container,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { successMessage } from 'utils/httpClient';
import { useRouter } from 'components/use-hook';
import { failedMessage } from 'utils/httpClient';
import apiEndPoint from 'utils/apiEndPoint';
import { apiCall } from 'utils/httpClient';
import { userLogout } from 'reduxStore/action/authAction';

const DeleteAccountCard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const router = useRouter();
  const { userToken } = useSelector(state => state.login);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async() => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setDeleteLoading(true);
    const { data } = await apiCall('post', apiEndPoint.DELETEACCOUNT, params, header);
    if (data.status!==200) {
      failedMessage(data?.message)
    } else {
      successMessage(data?.message)
      onClose();
      localStorage.clear();
      dispatch(userLogout());
      router.push("/sign-in");
    }
    setDeleteLoading(false);
  };

  return (
    <Container maxW="xxl" >
      <Box borderWidth={1} borderRadius="xl" p={8} textAlign={'center'}>
        <Stack spacing={3}>
          <Text>
            Are you sure you want to delete your account? <br /> This action is irreversible and will permanently remove all your data.
          </Text>
        </Stack>
        <Box mt={6}>
          <Button variant='brand' size="md" fontWeight={500} fontSize={14} colorScheme="red" onClick={onOpen}>
            Delete Account
          </Button>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Account Deletion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize='lg'>
                Are you sure you want to delete your account? This action is irreversible and will permanently remove all your data.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete}>
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Container>
  );
};

export default DeleteAccountCard;
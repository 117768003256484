import { AddIcon, ArrowForwardIcon, ChevronDownIcon, ChevronRightIcon, CloseIcon, SettingsIcon, SmallCloseIcon, SpinnerIcon } from '@chakra-ui/icons'
import {
  AspectRatio, Box, Icon, Button, ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Flex, FormControl, FormLabel, Image, Input, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useNumberInput,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  ModalFooter,
  ModalBody,
  Checkbox,
  ModalCloseButton,
  useStatStyles,
  HStack,
  Grid,
  GridItem,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  IconButton,
  CircularProgress,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import {
} from '@chakra-ui/react'
import {
  useColorModeValue,
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import { Select } from "@chakra-ui/react";
import { useState } from "react";
import { MdAudioFile, MdUpload } from 'react-icons/md';
import Dropzone from "views/admin/profile/components/Dropzone";
import { IoAddCircleOutline } from 'react-icons/io5';
import { ImFilePlay, ImLab } from "react-icons/im";
import { GiAudioCassette, GiClosedDoors, GiProgression, GiRapidshareArrow } from "react-icons/gi";
import { IoLockClosed } from "react-icons/io5";
import { Progress } from '@chakra-ui/react';
import { GiSpeaker } from "react-icons/gi";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'

import { useRouter } from 'components/use-hook';
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from 'utils/httpClient';
import apiEndPoint from 'utils/apiEndPoint';
import { isEmpty } from 'utils/utils';
import { successMessage } from 'utils/httpClient';
import { failedMessage } from 'utils/httpClient';
import { ReactMediaRecorder } from 'react-media-recorder';
import { CgMusic, CgSearchLoading, CgSpinner, CgSpinnerAlt, CgSpinnerTwo } from 'react-icons/cg';
import { FcAudioFile, FcVoicePresentation } from 'react-icons/fc';
import { FaAudioDescription, FaBackward, FaClosedCaptioning, FaDownload, FaForward, FaPause, FaPlay, FaShareAlt, FaTruckLoading, FaVoicemail } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';

export default function Voices(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  let menuBg = useColorModeValue("white", "navy.900");

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const { isOpen: isOpenModal1, onOpen: onOpenModal1, onClose: onCloseModal1 } = useDisclosure();

  // State management for modal 2
  const { isOpen: isOpenModal2, onOpen: onOpenModal2, onClose: onCloseModal2 } = useDisclosure();
  const { isOpen: isOpenModal3, onOpen: onOpenModal3, onClose: onCloseModal3 } = useDisclosure();
  const { isOpen: isOpenModal4, onOpen: onOpenModal4, onClose: onCloseModal4 } = useDisclosure();
  const { isOpen: isOpenModal5, onOpen: onOpenModal5, onClose: onCloseModal5 } = useDisclosure();
  const { isOpen: isOpenModal6, onOpen: onOpenModal6, onClose: onCloseModal6 } = useDisclosure();
  const { userToken } = useSelector(state => state.login)
  const router = useRouter();
  const reduxDispatch = useDispatch();
  const [voiceParameter, setVoiceParameter] = useState(null);
  const [parameterLoading, setParameterLoading] = useState(false);
  const [allVoicesLoading, setAllVoicesLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState('Select gender');
  const [selectedAge, setSelectedAge] = useState('Select age');
  const [selectedAccent, setSelectedAccent] = useState('Select Accent');
  const [formDetail, setFormDetail] = useState(null);
  const [voiceCloneFormData, setVoiceCloneFormData] = useState({
    voice_name: "",
    voice_sample: null,
    voice_description: "",
    confirm: false,
  });
  const [allVoices, setAllVoices] = useState(null);
  const [allGeneratedVoices, setAllgeneratedVoices] = useState([]);
  const [allClonedVoices, setAllClonedVoices] = useState([]);
  const [recordedVoice, setRecordedVoice] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [voiceData, setVoiceData] = useState(null);
  const [editVoicedata, setEditVoiceData] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  

  const totalQuota = 10000;
  const characterCount = formDetail?.text?.length;
  const remainingQuota = !isEmpty(characterCount) ? totalQuota - characterCount : totalQuota;

  const getVoiceParameter = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setParameterLoading(true);
    const { data } = await apiCall('get', apiEndPoint.VOICEPARAMETER, params, header);
    if (data.status!==200) {
      setVoiceParameter(null);
    } else {
      setVoiceParameter(data);
    }
    setParameterLoading(false);
  };

  const getAllVoices = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setAllVoicesLoading(true);
    const { data } = await apiCall('get', apiEndPoint.VOICELIST, params, header);
    if (data.status!==200) {
      setAllVoices(null);
      setAllgeneratedVoices([]);
      setAllClonedVoices([])
    } else {
 
      setAllgeneratedVoices(data?.voices);
      setAllClonedVoices(data?.cloned_voices)
      const allData = [...data?.cloned_voices, ...data?.voices]
      setAllVoices(allData);
    }
    setAllVoicesLoading(false);
  };

  const handleCreateVoice = async () => {
    // const header = { "Authorization": `Bearer ${userToken}` }
    const header = { "Authorization": `${userToken}` }
    const params = { ...formDetail }
    if (!(formDetail?.accent_strength) || isEmpty(formDetail?.accent_strength)) {
      params.accent_strength = voiceParameter?.minimum_accent_strength;
    }
    setSubmitLoading(true);
    const { data } = await apiCall('post', apiEndPoint.VOICECREATE, params, header);
    if (data.status!==200) {
      failedMessage(data?.message);
     } else {
      successMessage(data?.message);
      onCloseModal1();
      onCloseModal2();
      getAllVoices();
      setFormDetail(null);

    }
    setSubmitLoading(false);
  }

  const handleVoiceCloneInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setVoiceCloneFormData({
      ...voiceCloneFormData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleVoiceCloneFileUpload = (file) => {
    setVoiceCloneFormData({
      ...voiceCloneFormData,
      voice_sample: file
    });
    setRecordedVoice(null); // Disable recording option
  };

  const handleRecordingComplete = (mediaBlobUrl) => {
    fetch(mediaBlobUrl)
      .then((res) => res.blob())
      .then((blob) => {

        
        const file = new File([blob], "recordedVoice.mp3", { type: "audio/mp3" });
        setRecordedVoice(file);
        setVoiceCloneFormData({
          ...voiceCloneFormData,
          voice_sample: file,
        });
      });
  };

  const isFormValid = () => {
    return (
      voiceCloneFormData.voice_name &&
      voiceCloneFormData.voice_sample &&
      voiceCloneFormData.voice_description &&
      voiceCloneFormData.confirm
    );
  };

  const handleVoiceCloneSubmit = async () => {
    if (isFormValid()) {
      const header = { "Authorization": `${userToken}` }
      //  const params = { ...voiceCloneFormData }
      //  if (!isEmpty(voiceCloneFormData?.voice_sample)) {
      //    params.voice_sample = voiceCloneFormData?.voice_sample?.name;
      //  }
      //  delete params.confirm;
      const form = new FormData();
      form.append("voice_name", voiceCloneFormData?.voice_name);
      form.append("voice_description", voiceCloneFormData?.voice_description);
      if (voiceCloneFormData?.voice_sample) {
        form.append("voice_sample", voiceCloneFormData.voice_sample);
      }
      setSubmitLoading(true);
      const { data } = await apiCall('post', apiEndPoint.VOICECLONE, form, header);
      if (data.status!==200) {
        failedMessage(data?.message);
       } else {
        successMessage(data?.message);
        onCloseModal5();
        getAllVoices();
        setVoiceCloneFormData({
          voice_name: "",
          voice_sample: null,
          voice_description: "",
          confirm: false,
        });
      }
      setSubmitLoading(false);
    }
  }

  const VoiceDelete = async() =>{
    const header = { "Authorization": `${userToken}` }
    const params = { action:"delete", generated_voice_id: voiceData?.generated_voice_id }
    setSubmitLoading(true);
    if(voiceData?.type === 'generated_voice'){
    var { data } = await apiCall('post', apiEndPoint.VOICEGENERATIONOPTIONS, params, header);
    } else {
      if(voiceData?.type === 'cloned_voice'){
      var { data } = await apiCall('post', apiEndPoint.VOICECLONINGOPTIONS, params, header);
      }
    }
    if (data.status!==200) {
      failedMessage(data?.message);
    } else {
      successMessage(data?.message);
      onCloseModal4();
      getAllVoices();
    }
    setSubmitLoading(false);
  } 

  const validation = () => {
    let isError = true;
    let errorMessage = {};
    if (isEmpty(editVoicedata.voice_name)) {
      isError = false;
      errorMessage["voice_name"] = "Please fill the voice name";
  
    } 
    if (isEmpty(editVoicedata.voice_description)) {
      isError = false;
      errorMessage["voice_description"] = "Please fill the voice description";
    }
    setErrorMessage(errorMessage);
    return isError;
  };

  const EditVoice = async()=>{
    if(validation()){
    const header = { "Authorization": `${userToken}` }
    const params = { action:"edit", generated_voice_id: voiceData?.generated_voice_id, new_name:editVoicedata?.voice_name, new_description: editVoicedata?.voice_description }
    setSubmitLoading(true);
    if(voiceData?.type === 'generated_voice'){
    var { data } = await apiCall('post', apiEndPoint.VOICEGENERATIONOPTIONS, params, header);
    } else {
      if(voiceData?.type === 'cloned_voice'){
      var { data } = await apiCall('post', apiEndPoint.VOICECLONINGOPTIONS, params, header);
      }
    }
    if (data.status!==200) {
      failedMessage(data?.message);
    } else {
      successMessage(data?.message);
      onCloseModal3();
      getAllVoices();
    }
    setSubmitLoading(false);
  }
  } 


  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showPlaybar, setShowPlaybar] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [selectedVoiceId, setSelectedVoiceId] = useState(null);
  const [downloadFormat, setDownloadFormat] = useState('');

  const audioRef = useRef(null);
  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        
      } else {
        audioRef.current.play().catch((error) => {
          failedMessage("Error playing audio:", error);
        });
      }
      setIsPlaying(!isPlaying);
    } else {
      failedMessage("audioRef.current is not available");
    }
  };
  const handleUseClick = (voice, index) => {
    setSelectedVoice(voice?.voice_sample_path ? voice?.voice_sample_path : voice?.audio_file_path)
    setSelectedVoiceId(index);
    setShowPlaybar(true);
  };
  const handleAudioCanPlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
      // setDuration( audioRef.current.duration)
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }
  };

  const handleAudioLoaded = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSliderChange = (value) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
  };

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - 5);
    }
  };

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(duration, audioRef.current.currentTime + 5);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const downloadVoice = (format) => {
    setDownloadFormat(format);
    const voiceUrl = selectedVoice;
    const newSelectedvoice = selectedVoice?.split('/').pop().split('.').shift();
    const fileName = `${newSelectedvoice}.${format}`;

    if (format === 'mp3' || format === 'wav') {
      // For this example, we're assuming the format is already correct.
      saveAs(voiceUrl, fileName);
    }

    // Optionally reset download format after action
    setDownloadFormat('');
  };
  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    setIsPlaying(false);
  };
  const handleClosePlaybar = () => {
    handleStop();
    setSelectedVoice(null);
    setSelectedVoiceId(null);
    setShowPlaybar(false);
  };

  useEffect(()=>{
      setEditVoiceData(voiceData);
  },[voiceData])
  useEffect(() => {
    getAllVoices();
    getVoiceParameter();
  }, [])
  return (
    <Box>
      <Box p={4} mt={{ sm: '12rem', md: '8rem' }}>
        <Box mb='2rem'>
          <Box my='1'>
            <Flex>
              <Text fontSize="2xl" fontWeight="600">
                Voices
              </Text>
              {/* <Button fontWeight={400} fontSize={14} ml={'auto'} onClick={onOpenModal1}>
                Add a new voice
              </Button> */}
            </Flex>
          </Box>
          <Modal isOpen={isOpenModal1} onClose={onCloseModal1} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize={'15'} fontWeight="400">
                  Type of Generated voice to create
                </Text>
              </ModalHeader>
              <ModalBody>
                <Card onClick={() => { onCloseModal1(); onOpenModal2() }} style={{ cursor: 'pointer' }} borderWidth={1}>
                  <Flex alignItems={'center'}>
                    <ImLab />
                    <Text fontSize="16" fontWeight="500" ml={2} >
                      Voice Design
                    </Text>
                  </Flex>
                  <Box my={4}>
                    <Text fontSize="13" fontWeight="400" opacity={.8}>
                      Design entirely new voices by adjusting their parameters. Every voice you create is randomly generated and is entirely unique even if the same settings are applied.
                    </Text>
                  </Box>
                </Card>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpenModal6} onClose={onCloseModal6} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize={'15'} fontWeight="400">
                  Type of Cloned voice to create
                </Text>
              </ModalHeader>
              <ModalBody>
                <Card mt={4} borderWidth={1} onClick={() => { onCloseModal6(); onOpenModal5() }} style={{ cursor: 'pointer' }}>
                  <Flex alignItems={'center'} my={2}>
                    <ImFilePlay fontSize="16" />
                    <Text fontSize="13" fontWeight="400" ml={2} >
                    Voice Cloning
                    </Text>
                  </Flex>
                  <Box my={4}>
                    <Text fontSize="13" fontWeight="400" opacity={.8}>
                      Clone a voice from a clean sample recording. Samples should contain 1 speaker and be over 1 minute long and not contain background noise.
                    </Text>
                  </Box>
                </Card>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpenModal2} onClose={onCloseModal2} size="xl">
            <ModalOverlay />
            <ModalContent maxHeight={600} overflowY={'auto'} zIndex={99999} position={'relative'}>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize={'15'} fontWeight="400" >
                  Generate voice
                </Text>
              </ModalHeader>
              <ModalBody>
                <Grid templateColumns='repeat(3, 1fr)' gap={4} display={{ sm: 'block', md: 'flex' }}>
                  <GridItem w='100%' my={{ sm: '2', md: '0' }}>
                    <Box>
                      <Text fontSize={'12'} fontWeight="400" mb={1}>
                        Gender
                      </Text>
                      <Menu width="100%">
                        <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                          {!isEmpty(formDetail?.gender) ? formDetail?.gender?.replace(/_/g, ' ') : "Select Gender"}
                        </MenuButton>
                        <MenuList>
                          {!isEmpty(voiceParameter) && !isEmpty(voiceParameter?.genders) ?
                            voiceParameter?.genders.map((gender, i) => (
                              <>
                                <MenuItem fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, gender: e.target.value })} value={gender?.code} ke={i} >{gender?.name}</MenuItem></>))
                            :
                            <MenuItem fontSize={14}>No gender found!</MenuItem>
                          }
                        </MenuList>
                      </Menu>
                    </Box>
                  </GridItem>
                  <GridItem w='100%' my={{ sm: '2', md: '0' }}>
                    <Box>
                      <Text fontSize={'12'} fontWeight="400" mb={1}>
                        Age
                      </Text>
                      <Menu width="100%">
                        <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                          {!isEmpty(formDetail?.age) ? formDetail?.age?.replace(/_/g, ' ') : "Select Age"}
                        </MenuButton>
                        <MenuList>
                          {!isEmpty(voiceParameter) && !isEmpty(voiceParameter?.ages) ?
                            voiceParameter?.ages.map((age, i) => (
                              <>
                                <MenuItem fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, age: e.target.value })} value={age?.code} key={i}>{age?.name}</MenuItem></>))
                            :
                            <MenuItem fontSize={14}>No age found!</MenuItem>
                          }
                        </MenuList>
                      </Menu>
                    </Box>
                  </GridItem>
                  <GridItem w='100%' my={{ sm: '2', md: '0' }}>
                    <Box>
                      <Text fontSize={'12'} fontWeight="400" mb={1}>
                        Accent
                      </Text>
                      <Menu width="100%">
                        <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                          {!isEmpty(formDetail?.accent) ? formDetail?.accent?.replace(/_/g, ' ') : "Select Accent"}
                        </MenuButton>
                        <MenuList>
                          {!isEmpty(voiceParameter) && !isEmpty(voiceParameter?.accents) ?
                            voiceParameter?.accents.map((accent, i) => (
                              <>
                                <MenuItem fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, accent: e.target.value })} value={accent?.code} key={i}>{accent?.name}</MenuItem></>))
                            :
                            <MenuItem fontSize={14}>No age found!</MenuItem>
                          }
                        </MenuList>
                      </Menu>
                    </Box>
                  </GridItem>
                </Grid>

                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400">
                    Accent Strength
                  </Text>
                  <Slider
                    size="sm"
                    width="100%"
                    value={formDetail?.accent_strength}
                    onChange={(e) => setFormDetail({ ...formDetail, accent_strength: e })}
                    min={voiceParameter?.minimum_accent_strength}
                    max={voiceParameter?.maximum_accent_strength} // Set your desired minimum and maximum values here
                    my={2}
                    aria-label="Accent Strength Slider"
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <Tooltip
                      label={formDetail?.accent_strength}
                      placement="top"
                      hasArrow
                      bg="secondary.400"
                      color="primary"
                      borderRadius="md"
                    >
                      <SliderThumb />
                    </Tooltip>
                  </Slider>
                  <Flex direction='row' alignItems={'center'} justifyContent={'space-between'}>
                    <Text fontSize='xs' fontWeight={400}>Low</Text>
                    <Text fontSize='xs' fontWeight={400}>High</Text>
                  </Flex>
                </Box>
                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400" mb={1}>
                    Voice Text
                  </Text>
                  <Textarea placeholder='Here you can add voice text..' onChange={(e) => setFormDetail({ ...formDetail, text: e.target.value })} />
                  <Flex direction='row' alignItems={'center'} justifyContent={'space-between'} my={2} opacity={.5}>
                    <Text fontSize='xs' fontWeight={400}> {characterCount}/{totalQuota}</Text>
                    <Text fontSize='xs' fontWeight={400}>Total quota remaining: {remainingQuota}</Text>
                  </Flex>
                </Box>

                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400" mb={1}>
                    Name
                  </Text>
                  <Input color={textColor} placeholder='Lorem Ipsum eleu dolor' onChange={(e) => setFormDetail({ ...formDetail, voice_name: e.target.value })} />
                </Box>
                {/* <Box>
                  <Text fontSize={'12'} fontWeight="400" my={2}>
                    Labels 3 / 5
                  </Text>
                </Box>
                <Box my={4}>
                  <Flex>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      age :young
                    </Button>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      gender :female
                    </Button>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      accent :american
                    </Button>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      +
                    </Button>
                  </Flex>
                </Box> */}
                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400" mb={1}>
                    Description
                  </Text>
                  <Textarea placeholder='Here is a sample placeholder' onChange={(e) => setFormDetail({ ...formDetail, voice_description: e.target.value })} />

                </Box>

                <Box my={4}>
                  <Button fontWeight={500} fontSize={14} isFullWidth variant='outline' onClick={handleCreateVoice}>
                    Create Voice
                  </Button>
                </Box>
                {/* <Divider />
                <Box my={4}>
                  <Button fontWeight={500} fontSize={14} rightIcon={<ArrowForwardIcon />} isFullWidth>
                    Use Voice
                  </Button>
                </Box> */}
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpenModal3} onClose={onCloseModal3} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize={'15'} fontWeight="400" >
                  Edit voice
                </Text>
              </ModalHeader>
              <ModalBody>
                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400" mb={1}>
                    Name
                  </Text>
                  <Input 
                  placeholder={errorMessage.voice_name ? errorMessage.voice_name : "Please fill voice name"}
                  color={textColor}
                  name="voice_name"
                  value={editVoicedata?.voice_name}
                  onChange={(e) => setEditVoiceData({ ...editVoicedata, voice_name: e.target.value })}
                  isInvalid={!!errorMessage.voice_name} 
                  errorBorderColor='red.300'
                  />
                </Box>
                {/* <Box>
                  <Text fontSize={'12'} fontWeight="400" my={2}>
                    Labels 3 / 5
                  </Text>
                </Box>
                <Box my={4}>
                  <Flex>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      age :young
                    </Button>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      gender :female
                    </Button>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      accent :american
                    </Button>
                    <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                      +
                    </Button>
                  </Flex>
                </Box> */}
                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400" mb={1}>
                    Description
                  </Text>
                  <Textarea 
                  placeholder={errorMessage.voice_description ? errorMessage.voice_description : "Please fill voice description"}
                  name="voice_description"
                  value={editVoicedata?.voice_description}
                  onChange={(e) => setEditVoiceData({ ...editVoicedata, voice_description: e.target.value })}
                  isInvalid={!!errorMessage.voice_description} 
                  errorBorderColor='red.300'
                  color={textColor}
                   />
                </Box>
                <Box my={4} textAlign={'end'}>
                  <Button fontWeight={500} fontSize={14} variant='outline' onClick={onCloseModal3}>
                    Cancel
                  </Button>
                  <Button fontWeight={500} fontSize={14} rightIcon={<ArrowForwardIcon />} ml={4}
                  onClick={EditVoice} >
                    Edit Voice
                  </Button>
                </Box>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpenModal4} onClose={onCloseModal4} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize={'15'} fontWeight="400" >
                  Remove Voice Generated
                </Text>
              </ModalHeader>
              <ModalBody>
                <Box>
                  <Text fontSize={'12'} fontWeight="400" my={2}>
                    Do you really want to remove the voice ?
                  </Text>
                </Box>
                <Box mt={4} textAlign={'end'}>
                  <Button fontWeight={500} fontSize={14} variant='outline' onClick={onCloseModal4}>
                    Cancel
                  </Button>
                  <Button fontWeight={500} fontSize={14} ml={4} onClick={VoiceDelete}>
                    Remove Voice
                  </Button>
                </Box>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpenModal5} onClose={onCloseModal5} isCentered>
            <ModalOverlay />
            <ModalContent maxHeight={800} overflowY={'auto'} zIndex={99999} position={'relative'}>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize={'15'} fontWeight="400" >
                  Add voice
                </Text>
              </ModalHeader>
              <ModalBody>
                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400" mb={1}>
                    Name
                  </Text>
                  <Input placeholder='Lorem ipsum dolor epeu' color={textColor} name="voice_name" value={voiceCloneFormData.voice_name}
                    onChange={handleVoiceCloneInputChange} />
                </Box>
                {voiceCloneFormData?.voice_sample && !isEmpty(voiceCloneFormData?.voice_sample) ?
                  <>
                    <HStack textAlign={"center"} display={"flex"} alignItems={"center"}>
                      <Box display={'flex'} gap={2} bgColor={boxBg} p={2} borderRadius="md">
                        <CgMusic />
                        <Text fontSize="sm">{voiceCloneFormData?.voice_sample?.name}</Text>
                      </Box>
                      <Box ml={'auto'}>
                        <CloseIcon fontSize={12} onClick={() => {
                          setVoiceCloneFormData({
                            ...voiceCloneFormData,
                            voice_sample: null
                          });
                        }} />
                      </Box>
                    </HStack>
                  </> :
                  <Box textAlign={'center'}>

                    <Dropzone
                      w={{ base: "100%", "2xl": "100%" }}
                      maxH={{ base: "180px", lg: "180px", "2xl": "200px" }}
                      minH={{ base: "180px", lg: "180px", "2xl": "200px" }}
                      content={
                        <Box>
                          <Icon as={MdUpload} w="20px" h="20px" color={"#3182CE"} />
                          <Flex justify="center" mx="auto" mb="12px"></Flex>
                          <Box alignItems={"center"} justifyContent={"center"}>
                            <Text fontSize="sm" fontWeight="400">
                              Click to upload a file or drag and drop
                            </Text>
                            <Text mt={2} fontSize="xs" fontWeight="400" opacity={0.5}>
                              Audio or Video files, up to 10MB each
                            </Text>
                          </Box>
                        </Box>
                      }
                      onFileUpload={(file) => handleVoiceCloneFileUpload(file)}
                      disabled={!!recordedVoice || isRecording}
                    />
                    {/* <Box mt={4}>
                    <Button
                      borderRadius={50}
                      mb={2}
                      variant="outline"
                      size="sm"
                      onClick={() => setIsRecording(true)}
                      disabled={!!voiceCloneFormData.voice_sample || !!recordedVoice}
                    >
                      <Text fontSize="sm" fontWeight="400">
                        Record audio
                      </Text>
                    </Button>
                  </Box> */}
                    {isRecording && (
                      <Box my={4} textAlign="center">
                        <ReactMediaRecorder
                          audio
                          onStop={handleRecordingComplete}
                          render={({ startRecording, stopRecording }) => (
                            <>
                              <Button onClick={startRecording} colorScheme="red">
                                Start Recording
                              </Button>
                              <Button onClick={stopRecording} ml={4}>
                                Stop Recording
                              </Button>
                            </>
                          )}
                        />
                      </Box>
                    )}
                  </Box>
                }
                {!voiceCloneFormData?.voice_sample && isEmpty(voiceCloneFormData?.voice_sample) &&
                  <Box my={4}>
                    <Alert status='info' borderRadius={10}>
                      <AlertIcon />
                      <Text fontSize="xs" fontWeight="400" >
                        No items uploaded yet. Upload audio samples of the voice you would like to clone.
                        Sample quality is more important than quantity. Noisy samples may give bad results. Providing more than 5 minutes of audio in total brings little improvement.
                      </Text>
                    </Alert>
                  </Box>}
                {/* <Box>
                  <Text fontSize={'12'} fontWeight="400" my={2}>
                    Labels 3 / 5
                  </Text>
                  <Box my={4}>
                    <Alert status='info' borderRadius={10}>
                      <AlertIcon />
                      <Text fontSize="xs" fontWeight="400">
                        No labels. Click + to add a first one.
                      </Text>
                    </Alert>
                  </Box>
                </Box> */}
                <Box my={6}>
                  <Text fontSize={'12'} fontWeight="400" mb={1}>
                    Description
                  </Text>
                  <Textarea placeholder='Here is a sample placeholder' name="voice_description"
                    value={voiceCloneFormData.voice_description}
                    onChange={handleVoiceCloneInputChange} />
                  <Box my={4}>
                    <Flex alignItems={'flex-start'}>
                      <Checkbox defaultChecked name="confirm"
                        checked={voiceCloneFormData.confirm}
                        onChange={handleVoiceCloneInputChange} />
                      <Text fontSize={'14'} fontWeight="400" ml={2}>
                        I hereby confirm that I have all necessary rights or consents to upload and clone these voice samples and that I will not use the platform-generated content for any illegal, fraudulent, or harmful purpose. I reaffirm my obligation to abide by ElevenLabs' Terms of Service and Privacy Policy.
                      </Text>
                    </Flex>
                  </Box>
                </Box>
                <Box my={4} textAlign={'end'}>
                  <Button fontWeight={500} fontSize={14} variant='outline' onClick={onCloseModal5}>
                    Cancel
                  </Button>
                  <Button fontWeight={500} fontSize={14} ml={4} onClick={handleVoiceCloneSubmit}
                    disabled={!isFormValid()}>
                    Add Voice
                  </Button>
                </Box>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Box>
            <Text fontSize="md" fontWeight="400">
              Craft lifelike voices, clone your own, and discover community featured ones
            </Text>
          </Box>
        </Box>
        <Tabs>
          <TabList>
            <Tab fontSize={14}>Generated Voices</Tab>
            <Tab fontSize={14}>Cloned Voices</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              { allVoicesLoading ? <center style={{marginTop:"50px"}}>  <Text fontSize="md" fontWeight="400">
              Loading...
            </Text><Spinner size={"sm"}/> </center> :
              <Box mt={5} position={'relative'}>
                <Flex gap={4} display={{ sm: 'block', md: 'flex' }} >
                  <Grid templateColumns={{
                      lg: 'repeat(3, 1fr) !important',
                      xl: 'repeat(4, 1fr) !important',
                    }} gap={4} my={2} w='100%'>
                    <GridItem>
                      <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline' borderWidth={1}
                      >
                        <Box>
                          <Box textAlign={'center'} onClick={onOpenModal1} sx={{cursor:"pointer"}}>
                            <IoAddCircleOutline style={{ margin: 'auto' }} fontSize={50} />
                            <Box my={2}>
                              <Text fontSize="sm" fontWeight="400">
                                Add Generated Voice
                              </Text>
                            </Box>
                            <Box my={2}>
                              <Text fontWeight={400} fontSize={12} opacity={.6}>You can generate a new voice from here</Text>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </GridItem>
                    {isEmpty(allGeneratedVoices) &&
                      <GridItem>
                        <Card
                          direction={{ base: 'column', sm: 'row' }}
                          overflow='hidden'
                          variant='outline' borderWidth={1}
                        >
                          <Box>
                            <Box textAlign={'center'}>
                              <MdAudioFile fontSize={14} size={50} style={{ margin: 'auto' }} />
                              <Box my={2}>
                                <Text fontSize="sm" fontWeight="400">
                                  No Cloned Voices Found!
                                </Text>
                              </Box>
                              <Box my={2}>
                                <Text fontWeight={400} fontSize={12} opacity={.6}>Please add/clone voice to show here.</Text>
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      </GridItem>
                    }
                  </Grid>
                </Flex>
                <Flex gap={4} display={{ sm: 'block', md: 'flex' }} >
                  <Grid
                    templateColumns={{
                      lg: 'repeat(3, 1fr) !important',
                      xl: 'repeat(4, 1fr) !important',
                    }} 
                    gap={4} my={2} w='100%'>
                    {!isEmpty(allGeneratedVoices) && allGeneratedVoices.map((voice, i) => (
                      <GridItem>
                        <Card
                          direction={{ base: 'column', sm: 'row' }}
                          overflow='hidden' borderWidth={1}
                          variant='outline'
                        >
                          <Box>
                            <Box display={'flex'} alignItems={"center"}>
                              <MdAudioFile fontSize={14} size={20} />
                              <Text ml={1} fontSize="16" fontWeight="500" my={2}>
                                {voice?.voice_name}
                              </Text>
                            </Box>
                            {/* <Text fontSize="14" fontWeight="400">
                          Young Female with American accent
                        </Text> */}
                            <Text fontSize="14" fontWeight="400" opacity={.5}>
                              {voice?.voice_description}
                            </Text>
                            <Box mt={4}>
                              <Button fontWeight={400} leftIcon={<GiSpeaker />} onClick={() => {handleUseClick(voice, i); setVoiceData(voice);}} fontSize={14} borderRadius={50} >
                                Use
                              </Button>
                              <Button fontWeight={400} leftIcon={<FiEdit />} fontSize={14} borderRadius={50} ml={2} onClick={()=> {setVoiceData(voice); onOpenModal3();}}>
                                Edit
                              </Button>
                              <Button fontWeight={400} leftIcon={<MdDeleteOutline />} fontSize={14} borderRadius={50} ml={2} onClick={()=>{setVoiceData(voice); onOpenModal4();} }>
                                Remove
                              </Button>
                              <audio 
                              ref={audioRef}
                              key={voice?.generated_voice_id}
                              src={selectedVoice}
                              onTimeUpdate={handleTimeUpdate}
                              onLoadedMetadatca={handleAudioLoaded}
                              type="audio/mp3"
                              onError={(e) => console.error("Error loading audio", e)}  // Handle load errors
                              onCanPlay={handleAudioCanPlay}  // Add this event handler
                               />
                            </Box>
                          </Box>
                        </Card>

                      </GridItem>
                    ))}
                  </Grid>
                </Flex>
              </Box> }
            </TabPanel>
            <TabPanel>
            { allVoicesLoading ? <center style={{marginTop:"50px"}}>  <Text fontSize="md" fontWeight="400">
              Loading...
            </Text><Spinner size={"sm"}/> </center> :
              <Box mt={5} position={'relative'}>
                <Flex gap={4} display={{ sm: 'block', md: 'flex' }} >
                  <Grid templateColumns={{
                      lg: 'repeat(3, 1fr) !important',
                      xl: 'repeat(4, 1fr) !important',
                    }}  gap={4} my={2} w='100%'>
                    <GridItem>
                      <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline' borderWidth={1}
                      >
                        <Box>
                          <Box textAlign={'center'} onClick={onOpenModal6} sx={{cursor:"pointer"}}>
                            <IoAddCircleOutline style={{ margin: 'auto' }} fontSize={50} />
                            <Box my={2}>
                              <Text fontSize="sm" fontWeight="400">
                                Add Cloned Voice
                              </Text>
                            </Box>
                            <Box my={2}>
                              <Text fontWeight={400} fontSize={12} opacity={.6}>You can add clone voice from here</Text>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </GridItem>
                    {isEmpty(allClonedVoices) &&
                      <GridItem>
                        <Card
                          direction={{ base: 'column', sm: 'row' }}
                          overflow='hidden'
                          variant='outline' borderWidth={1}
                        >
                          <Box>
                            <Box textAlign={'center'}>
                              <MdAudioFile fontSize={14} size={50} style={{ margin: 'auto' }} />
                              <Box my={2}>
                                <Text fontSize="sm" fontWeight="400">
                                  No Voices Found!
                                </Text>
                              </Box>
                              <Box my={2}>
                                <Text fontWeight={400} fontSize={12} opacity={.6}>Please add/clone voice to show here.</Text>
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      </GridItem>
                    }
                  </Grid>
                </Flex>
                <Flex gap={4} display={{ sm: 'block', md: 'flex' }} >
                  <Grid
                    templateColumns={{
                      lg: 'repeat(2, 1fr) !important',
                      xl: 'repeat(3, 1fr) !important',
                    }}
                    gap={4} my={2} w='100%'>
                    {!isEmpty(allClonedVoices) && allClonedVoices.map((voice, i) => (
                      <GridItem>
                        <Card
                          direction={{ base: 'column', sm: 'row' }}
                          overflow='hidden' borderWidth={1}
                          variant='outline'
                        >
                          <Box>
                            <Box display={'flex'} alignItems={"center"}>
                              <MdAudioFile fontSize={14} size={20} />
                              <Text ml={1} fontSize="16" fontWeight="500" my={2}>
                                {voice?.voice_name}
                              </Text>
                            </Box>
                            {/* <Text fontSize="14" fontWeight="400">
                          Young Female with American accent
                        </Text> */}
                            <Text fontSize="14" fontWeight="400" opacity={.5}>
                              {voice?.voice_description}
                            </Text>
                            <Box mt={4}>
                              <Button fontWeight={400} leftIcon={<GiSpeaker />} onClick={() => {handleUseClick(voice, i); setVoiceData(voice);}} fontSize={14} borderRadius={50} >
                                Use
                              </Button>
                              <Button fontWeight={400} leftIcon={<FiEdit />} fontSize={14} borderRadius={50} ml={2} onClick={()=> {setVoiceData(voice); onOpenModal3();}}>
                                Edit
                              </Button>
                              <Button fontWeight={400} leftIcon={<MdDeleteOutline />} fontSize={14} borderRadius={50} ml={2} onClick={()=>{setVoiceData(voice); onOpenModal4();} }>
                                Remove
                              </Button>
                              <audio 
                              ref={audioRef}
                              key={voice?.generated_voice_id}
                              src={selectedVoice}
                              onTimeUpdate={handleTimeUpdate}
                              onLoadedMetadatca={handleAudioLoaded}
                              type="audio/mp3"
                              onError={(e) => console.error("Error loading audio", e)}  // Handle load errors
                              onCanPlay={handleAudioCanPlay}  // Add this event handler
                               />
                            </Box>
                          </Box>
                        </Card>

                      </GridItem>
                    ))}
                  </Grid>
                </Flex>
              </Box> }
            </TabPanel>
          </TabPanels>
        </Tabs>
        {showPlaybar && (
          <Box
            position="fixed"
            bottom="0"
            bg={menuBg}
            boxShadow="lg"
            p={4}
            zIndex="1000"
            w={{
              base: "100%",
              xl: "calc(100vw - 305px)",
              "2xl": "calc(100vw - 305px)",
            }}
            right={{ base: "0", md: "0", lg: "0", xl: "0" }}
          >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text fontWeight="bold">{voiceData?.voice_name}</Text>
              {/* <Text fontSize="sm">20-8-2024</Text> */}
              <SmallCloseIcon sx={{cursor:"pointer"}} fontSize={"20px"} onClick={handleClosePlaybar} />
            </Flex>
            <Flex alignItems="center">
              <IconButton
                icon={isPlaying ? <FaPause /> : <FaPlay />}
                onClick={handlePlayPause}
                aria-label="Play/Pause"
                isRound
                size="sm"
                mr={2}
              />
              <IconButton
                icon={<FaBackward />}
                onClick={handleRewind}
                aria-label="Rewind 5 seconds"
                isRound
                size="sm"
                mr={2}
              />
              <Slider
                value={currentTime}
                max={duration}
                onChange={handleSliderChange}
                flex="1"
                mr={2}
              >
                <SliderTrack bg="gray.200">
                  <SliderFilledTrack bg="blue.500" />
                </SliderTrack>
                <SliderThumb boxSize={3} />
              </Slider>
              <Text fontSize="sm">
                {formatTime(currentTime)} / {formatTime(duration)}
              </Text>
              <IconButton
                icon={<FaForward />}
                onClick={handleForward}
                aria-label="Forward 5 seconds"
                isRound
                size="sm"
                ml={2}
              />
              <Popover>
              <PopoverTrigger>
              <IconButton
                icon={<FaDownload />}
                aria-label="Download"
                isRound
                size="sm"
                ml={2}
              />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Select Download Format</PopoverHeader>
                <PopoverBody>
                  <Button colorScheme="blue" onClick={() => downloadVoice('mp3')} mr={3}>MP3</Button>
                  <Button colorScheme="green" onClick={() => downloadVoice('wav')}>WAV</Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            </Flex>
          </Box>)}
      </Box>
    </Box>
  )
}
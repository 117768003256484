import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Avatar,
  useToast,
  Container,
  Flex,
  Center,
  Heading,
  Text,
  useColorModeValue,
  Spinner
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "utils/utils";
import { apiCall } from "utils/httpClient";
import apiEndPoint from "utils/apiEndPoint";
import { successMessage } from "utils/httpClient";
import { failedMessage } from "utils/httpClient";
import { userLogin } from "reduxStore/action/authAction";
// import { useForm } from "react-hook-form";

const EditProfile = () => {
  // const { register, handleSubmit, setValue } = useForm();
  const [avatar, setAvatar] = useState(null);
  const toast = useToast();
  const reduxDispatch = useDispatch();
  const { userDetail, userToken } = useSelector(state => state.login)
  const [formData, setFormData] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const textColor = useColorModeValue("navy.700", "white");

  useEffect(() => {
    setFormData(userDetail);
}, [userDetail]);

  const onSubmit = (data) => {
    // Handle form submission
    console.log(data);
    toast({
      title: "Profile updated.",
      description: "We've updated your profile.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
        // setValue("avatar", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const validation = () => {
      let isError = true;
      let errorMessage = {};
      if (isEmpty(formData.firstname)) {
          isError = false;
          errorMessage["firstname"] = "Please Enter First Name";
      }
      if (isEmpty(formData.lastname)) {
          isError = false;
          errorMessage["lastname"] = "Please Enter Last Name";
      }
      setErrorMessage(errorMessage);
      return isError;
  };

  const handleUpdateProfile = async() =>{
        if (validation()) {
          // const form = new FormData();
          // form.append("firstname", formData.firstname);
          // form.append("lastname", formData.lastname);
          // if (selectedFile) {
            //     form.append('profile_image_url', selectedFile); // Append the image file
            // }
            const header = {
              "Authorization": `${userToken}`,
              // "Content-Type": "application/x-www-form-urlencoded"
            }

            const params = {firstname:formData?.firstname, lastname: formData?.lastname}
            setSubmitLoading(true);
            const { data } = await apiCall("post", apiEndPoint.UPDATEPROFILE, params, header);
            if (data.status !== 200) {
              failedMessage(data.message);
            } else {
              //  const datauser = {
                //     token: !isEmpty(data?.data?.token) ? data?.data?.token : loginSelector?.authToken,
                //     userDetail: data?.data?.userDetail,
                //   };
                // reduxDispatch(userLogin(data?.data));
                reduxDispatch(userLogin(formData));
                successMessage(data.message);
            }
        }
        setSubmitLoading(false);
  }

  return (
      <Box borderWidth={1} borderRadius="xl" p={5}>
        {/* <Text  fontWeight={600} mb={4}>
          Edit Profile
        </Text> */}
        <VStack spacing={4} align="stretch">
          <FormControl>
            <Flex>
              <Input
                type="file"
                id="avatar"
                accept="image/*"
                onChange={handleAvatarChange}
                display="none"
              />
              <label htmlFor="avatar">
                <Avatar
                  size="xl"
                  src={avatar}
                  name="User Avatar"
                  cursor="pointer"
                />
              </label>
              <Center ml={4}>
                <FormLabel htmlFor="avatar" fontSize={13}>
                  Change Avatar
                </FormLabel>
              </Center>
            </Flex>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel htmlFor="firstname" fontSize={14} mb={1}>
              First Name
            </FormLabel>
            <Input
              id="firstname"
              placeholder="Enter your firstname"
              name="name"
              // {...register("firstname", { required: "firstname is required" })}
              value={formData?.firstname || ''}
              onChange={(e)=>{setFormData({...formData, firstname:e.target.value})}}
              onError={isEmpty(formData?.firstname) && !isEmpty(errorMessage?.firstname) && true}
              color={textColor}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="lastname" fontSize={14} mb={1}>
              Last Name
            </FormLabel>
            <Input
              id="lastname"
              placeholder="Enter your lastname"
              name='lastname'
              // {...register("lastname", { required: "lastname is required" })}
              value={formData?.lastname || ''}
              onChange={(e)=>{setFormData({...formData, lastname:e.target.value})}}
              onError={isEmpty(formData?.lastname) && !isEmpty(errorMessage?.lastname) && true}
              color={textColor}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email" fontSize={14} mb={1}>
              Email
            </FormLabel>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="Enter your email"
              // {...register("email", { required: "Email is required" })}
              value={formData?.email}
              onChange={(e)=>{setFormData({...formData, email:e.target.value})}}
              isDisabled
              color={textColor}
            />
          </FormControl>
          <Box>
            <Button variant='brand' size="md" fontWeight={500} fontSize={14} onClick={handleUpdateProfile} isDisabled={submitLoading}>
              { submitLoading ? <Spinner /> : 
              'Save Changes'
                }
            </Button>
          </Box>
        </VStack>
      </Box>
  );
};

export default EditProfile;
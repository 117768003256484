
// Mobile Number validation
export function mobileNumberValid(MobileNumber) {
    const mob = /^[1-9]{1}[0-9]{9}$/;
    if (mob.test(MobileNumber) === false) {
      return false;
    }
    return true;
  }
  
  export function removeNullFromObj(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, value]) => value !== null && value !== 'null' && value !== '')
        .map(([key, value]) => [
          key,
          value === Object(value) ? removeNullFromObj(value) : value,
        ]),
    );
  }
  export const clearUndefinedArray = (arr) => {
    return Array.isArray(arr) ? arr.filter((item) => item !== undefined):[];
  };
  export const clearUndefinedObject = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        delete obj[key];
      } else if (obj[key] === "undefined") {
        delete obj[key];
      }
    });
    return obj;
  };
  export function isEmpty(value) {
    return (
      value === 'null' ||
      value === null ||
      value === undefined ||
      value === 'undefined' ||
      value === '' ||
      (Array.isArray(value) && value.length === 0) ||
      (!(value instanceof Date) &&
        typeof value === 'object' &&
        Object.keys(removeNullFromObj(value)).length === 0) ||
      (!(value instanceof Date) &&
        typeof value === 'object' &&
        // Object.keys(value).length === 1 &&
        Object.keys(removeNullFromObj(value)).some(i => value[i] === ''))
    );
  }
  export const isVideo = (value) => {
    const videoPattern = /\.(mp4|webm|ogg)/i; // You can add more video extensions
    return value.match(videoPattern)
  }
  // Validation_Image
  export const ValidateExtensionImge = (fileUpload) => {
    var allowedFiles = [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG"];
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:])+(" + allowedFiles.join("|") + ")$"
    );
    if (!regex.test(fileUpload.value.toLowerCase())) {
      return "Allowed extension " + allowedFiles.join(", ");
    }
    return true;
  };
  export const isImage = (value) => {
    const imagePattern = /\.(jpeg|jpg|gif|png|webp)/i; // You can add more image extensions
    return value.match(imagePattern)
  }
  
  export function handleDetailResponse(result) {
    let data = "";
    if (Array.isArray(result)) {
      data = result[0];
    } else {
      data = result;
    }
    return data ? clearUndefinedObject(data) : {};
  }
  
  export const validateWebsiteUrl = (websiteUrl) => {
    const urlRegEx = new RegExp("^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$")
    return urlRegEx.test(String(websiteUrl).toLowerCase());
  };
  
  export const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...'; // Adjust the ellipsis or customize the message
    }
    return text;
  };
  
  // Check_valid_JSON_stringfy_object
  export function isJSON(str) {
    if (/^\s*$/.test(str)) return false;
    str = str?.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, "@");
    str = str?.replace(
      /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
      "]"
    );
    str = str?.replace(/(?:^|:|,)(?:\s*\[)+/g, "");
    return /^[\],:{}\s]*$/.test(str);
  }
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.gif";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useRouter } from "components/use-hook";
import { useDispatch } from "react-redux";
import { isEmpty } from "utils/utils";
import { successMessage, failedMessage } from "utils/httpClient";
import { apiCall } from "utils/httpClient";
import apiEndPoint from "utils/apiEndPoint";
import { setDefaultHeader } from "utils/httpClient";
import { authTokenUpdate } from "reduxStore/action/authAction";
import { userLogin } from "reduxStore/action/authAction";
import doteenv from 'utils/doteenv';
function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue( { bg: "gray.200" },{ bg: "whiteAlpha.300" });
  const googleActive = useColorModeValue({ bg: "secondaryGray.300" },{ bg: "whiteAlpha.200" });
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);


  const router = useRouter();
  const reduxDispatch = useDispatch();
  const [email, setEmail] = useState(null);
  
  const [password, setPassword] = useState(null);
  
  const [errorMessage, setErrorMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);

  const handleEmailValidation = (email) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setErrorMessage({ ...errorMessage, email: "Invalid email address" })
    } else {
      setErrorMessage({ ...errorMessage, email: "" })
    }
  }
  const validation = () => {
    let isError = true;
    let errorMessage = {};
    if (isEmpty(email)) {
      isError = false;
      errorMessage["email"] = "Please Enter Email ID";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      isError = false;
      errorMessage["email"] = "Invalid email address";
    }
    if (isEmpty(password)) {
      isError = false;
      errorMessage["password"] = "Please Enter Password";
    }
    setErrorMessage(errorMessage);
    return isError;
  };

  const handleSignIn = async() => {
    if (validation()) {
      const params = {
        email: email,
        password: password,
      }
            
      // Store username and keepLoggedIn status in localStorage
      localStorage.setItem('email', keepLoggedIn ? email : null);
      localStorage.setItem('password', keepLoggedIn ? password : null);
      localStorage.setItem('keepLoggedIn', keepLoggedIn);
      setIsLoading(true)
      const { data } = await apiCall('post', apiEndPoint.LOGIN, params)
      
      if (data.status !== 200) {
         failedMessage(data?.message)
      } else {
        await setDefaultHeader("Authorization", `Bearer ${data?.data?.token}`);
        await reduxDispatch(userLogin(data?.data))
        await reduxDispatch(authTokenUpdate(data?.data?.token))
        // const lang = {
        //   value: "en",
        //   lang: englishLang
        // }
        // await reduxDispatch(selectLanguage(lang));
        router.push('/admin');
      }
      setIsLoading(false)
    }
  }

    // Get the current URL
    const currentUrl = window.location.href;
    // Parse the URL to get the search parameters
    const urlParams = new URLSearchParams(currentUrl);
    // Get the value of the 'code' parameter from the URL
    const code = urlParams.get('code');
  
    const googleAuthSessions= async (code)=>{
      
      if (!isEmpty(code) && code!=false) {
        const parms = {
          "code": code,
          "redirect_uri": `${doteenv.REDIRECTURL}`
        }
        const { data } = await apiCall('post', apiEndPoint.SIGNUPWITHGOOGLE, parms);
        if (data.error) {
          router.push('/');
        } else {
          await setDefaultHeader("Authorization", `Bearer ${data?.data?.token}`);
          await reduxDispatch(userLogin(data?.data))
          await reduxDispatch(authTokenUpdate(data?.data?.token))

          // Save the state of the "KeepLoggedIn" checkbox in local storage
          localStorage.setItem('keepLoggedIn', JSON.stringify(keepLoggedIn));

          // const lang = {
          //   value: "en",
          //   lang: englishLang
          // }
          // await reduxDispatch(selectLanguage(lang));
          router.push('/admin');
        }
      }
    }
  
    useEffect(() => {
      googleAuthSessions(code)
    }, [code])

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  const handleSignupGoogle = async () => {
    window.location.href = doteenv.GOOGLE_SIGNUP_LINK + makeid(30)
  }

   useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    console.log('storedEmail: ', storedEmail);
    const storedPassword = localStorage.getItem('password');
    console.log('storedPassword: ', storedPassword);
    const storedRememberMe = localStorage.getItem('keepLoggedIn') === 'true';
    console.log('storedRememberMe: ', storedRememberMe);
    if (storedRememberMe) {
      setEmail(storedEmail || null);
      setPassword(storedPassword || null);
      setKeepLoggedIn(storedRememberMe);
    }
  }, [])

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={() => handleSignupGoogle()}
            >
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => setEmail(e.target.value)}
              placeholder={!isEmpty(errorMessage?.email) ?  errorMessage?.email : "mail@Pajti.com"}
              error={isEmpty(email) && !isEmpty(errorMessage?.email) && true}
              value={email}

            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(e) => setPassword(e.target.value)}
                placeholder={isEmpty(password) && !isEmpty(errorMessage?.password) ? errorMessage?.password : "Min. 8 characters"}
                error={isEmpty(password) && !isEmpty(errorMessage?.password) && true}
                value={password}

              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                  isChecked={keepLoggedIn} 
                  onChange={(e) => setKeepLoggedIn(e.target.checked)}
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              onClick={handleSignIn}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;

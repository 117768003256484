import { ChevronDownIcon, HamburgerIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import { Avatar, Box, Button, Divider, useColorModeValue, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, HStack, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Select, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Stack, Switch, Text, Tooltip, useDisclosure, useNumberInput } from '@chakra-ui/react'
import React from 'react'
import { FaMicrophone } from 'react-icons/fa'

export default function ImgGeneration() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 5,
    })
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <Box>
      <Box m={6} pt={{ base: "200px", md: "80px", xl: "100px" }}>
        <Flex>
          <Box>
            <Text fontSize="2xl" fontWeight="500">
              Image Generation
            </Text>
          </Box>
          <Spacer />
          <Box>
            <Flex alignItems={'center'}>
              {/* <Text fontSize="xs" fontWeight="500">
                This will use 4 tokens
              </Text> */}
              <Button ml={3} ref={btnRef} borderRadius={5} onClick={onOpen}>
                <HamburgerIcon />
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Stack spacing={5} my={5}>
          <InputGroup>
            <InputRightElement
              children={<Flex><Box mr='5' mt='2'><FaMicrophone opacity={.25} cursor="pointer" size='20px' /></Box> </Flex>}
            />
            <Input size='lg' placeholder="" />
          </InputGroup>
          {/* <InputGroup>
            <InputRightElement
              children={<Flex><Box mr='5' mt='2'><FaMicrophone opacity={.25} cursor="pointer" size='20px' /></Box> </Flex>}
            />
            <Input size='lg'
              placeholder="" />
          </InputGroup> */}
        </Stack>
        <FormControl display='flex' alignItems='center' justifyContent={'end'}>
          {/* <Switch id='email-alerts' />
          <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0' ml='4'>
            Negative Prompt
          </FormLabel> */}
          <Button borderRadius={50} fontSize="sm" variant='outline'>Generate Button</Button>
        </FormControl>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Box m={5}>
              <Flex w="100%" alignItems={'center'}>
                <Avatar
                  bg="#11047A"
                  size="md"
                  borderRadius={5} src='https://images.unsplash.com/photo-1506863530036-1efeddceb993?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2244&q=80'
                />
                <Text ml={2}
                  fontSize="xs"
                  fontWeight="400"
                  lineHeight='20px'
                >
                  Model <Text fontSize="sm" fontWeight="500" >
                    ArtShaper V3
                  </Text>
                </Text>
              </Flex>
            </Box>
            <Divider />
            <DrawerCloseButton />
            <DrawerBody>
              <Box mt={5}>
                <Text fontSize='sm' fontWeight={500} my={2}>Model :</Text>
                <Menu width="100%">
                  <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                    Select Option
                  </MenuButton>
                  <MenuList>
                    <MenuItem fontSize={14}>Creative Canvas</MenuItem>
                    <MenuItem fontSize={14}>Fluid Fantasy</MenuItem>
                    <MenuItem fontSize={14}>Imaginarium</MenuItem>
                    {/* <MenuItem fontSize={14}>German</MenuItem> */}
                    <MenuItem fontSize={14}>Deep Dreamer</MenuItem>
                    <MenuItem fontSize={14}>Leonardo</MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              <Box mt={10}>
                <Text fontSize='sm' fontWeight={500} my={2}>Size :</Text>
                <Menu width="100%">
                  <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                    For dalle
                  </MenuButton>
                  <MenuList>
                    <MenuItem fontSize={14}>HD 1024×1024</MenuItem>
                    <MenuItem fontSize={14}>HD 1024×1792 </MenuItem>
                    <MenuItem fontSize={14}>1792×1024</MenuItem>
                  </MenuList>
                </Menu>
                <Box mt={4}>
                  <Menu width="100%">
                    <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                      For Stable Diffusion/Imagen/DeepAI
                    </MenuButton>
                    <MenuList>
                      <MenuItem fontSize={14}>512 x 512px </MenuItem>
                      <MenuItem fontSize={14}>768 x 768px </MenuItem>
                      <MenuItem fontSize={14}>512×1024px</MenuItem>
                      <MenuItem fontSize={14}>768×1024px</MenuItem>
                      <MenuItem fontSize={14}>1024×1024px</MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
              <Box mt={10} w={'60%'}>
                <Text fontSize='sm' fontWeight={500} my={2}>Number of Images</Text>
                <HStack>
                  <Button {...dec}>-</Button>
                  <Text>
                    <Input textAlign={'center'} color={textColor} {...input} />
                  </Text>
                  <Button {...inc}>+</Button>
                </HStack>
              </Box>
              <Box mt={10}>
                <Text fontSize='sm' fontWeight={500} my={2}>Styles :</Text>
                <Menu width="100%">
                  <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                    For Stable Diffusion/Imagen/DeepAI
                  </MenuButton>
                  <MenuList>
                    <MenuItem fontSize={14}>Fantasy Character Generator</MenuItem>
                    <MenuItem fontSize={14}>Anime Portrait Generator</MenuItem>
                    <MenuItem fontSize={14}>Old Drawing Generator</MenuItem>
                    <MenuItem fontSize={14}>3d Objects Generator</MenuItem>
                    <MenuItem fontSize={14}>Abstract Painting Generator</MenuItem>
                    <MenuItem fontSize={14}>3d Character Generator</MenuItem>
                    <MenuItem fontSize={14}>AI Logo Generator</MenuItem>
                    <MenuItem fontSize={14}>3D Cartoon Character Generator</MenuItem>
                    <MenuItem fontSize={14}>Haunted Portrait Generator</MenuItem>
                    <MenuItem fontSize={14}>Children’s Book Illustration Generator</MenuItem>
                    <MenuItem fontSize={14}>Romantic Art Generator</MenuItem>
                    <MenuItem fontSize={14}>Solar System Generator</MenuItem>
                    <MenuItem fontSize={14}>Zodiac Design Generator</MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              {/* <Text fontSize='md' fontWeight={500} my={5}>Image Dimensions <Tooltip label='Select the resolution of images' fontSize='xs'>
                                <QuestionOutlineIcon ml='1' fontSize='sm' />
                            </Tooltip></Text>
                            <Flex>
                                <Slider aria-label='slider-ex-1' defaultValue={30}>
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb />
                                </Slider>
                                <Text fontSize='md' fontWeight={500} ml='4'>12</Text>
                            </Flex>
                            <Box my='6'>
                                <FormControl display='flex' alignItems='center' justifyContent={'space-between'}>
                                    <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0'>
                                        Prompt Magic  <Tooltip label='Select the resolution of images' fontSize='xs'>
                                            <QuestionOutlineIcon ml='1' fontSize='sm' />
                                        </Tooltip>
                                    </FormLabel>
                                    <Switch id='email-alerts' />
                                </FormControl>

                            </Box>
                            <Box my='6'>
                                <FormControl display='flex' alignItems='center' justifyContent={'space-between'}>
                                    <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0'>
                                        High Contrast  <Tooltip label='Select the resolution of images' fontSize='xs'>
                                            <QuestionOutlineIcon ml='1' fontSize='sm' />
                                        </Tooltip>
                                    </FormLabel>
                                    <Switch id='email-alerts' />
                                </FormControl>
                            </Box> */}
            </DrawerBody>
            <DrawerFooter>
              {/* <Button variant='outline' mr={3} onClick={onClose}>
                                Cancel
                            </Button> */}
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
      <Divider />
    </Box>
  )
}
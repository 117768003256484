import { Fragment, useEffect, useRef, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Text,
    Box,
    Button,
    Flex,
    Modal,
    Stack,
    Avatar,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Input,
    Divider,
    TableContainer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Image,
} from '@chakra-ui/react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'
import { FiDelete, FiMoreVertical } from 'react-icons/fi';
import { MdBlindsClosed, MdCheck, MdCheckBox, MdHistory, MdManageHistory, MdManageSearch, MdOutlineClose, MdOutlineManageHistory, MdReport } from 'react-icons/md';
import { CiFileOn } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import { CiEdit, CiShare2, CiTrash } from "react-icons/ci";
import { IoIosInfinite, IoMdCheckbox, IoMdCloseCircle } from 'react-icons/io';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { FiDownload } from "react-icons/fi";
import { BsFiletypeDocx, BsFiletypeTxt, BsFiletypePdf, BsFiletypeCsv, BsStopwatch, BsPlay, BsPause, BsTicketDetailedFill, BsCircleSquare } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { DownloadIcon, PhoneIcon, TimeIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FaBackward, FaForward, FaHistory, FaPause, FaPlay, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import apiEndPoint from 'utils/apiEndPoint';
import { apiCall } from 'utils/httpClient';
import { isEmpty } from 'utils/utils';
import { saveAs } from 'file-saver';
import { failedMessage } from 'utils/httpClient';
import { successMessage } from 'utils/httpClient';
import { truncateText } from 'utils/utils';
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    useColorModeValue
} from '@chakra-ui/react'
import Card from 'components/card/Card';
import { ImFilePlay } from 'react-icons/im';
import NoDataFound from '../../../assets/img/no_found.png';

const HistorySpeech = ({ voiceHistory, getHistoryList }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const { userToken } = useSelector(state => state.login)
    const [downloadFormat, setDownloadFormat] = useState('');
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [selectedVoiceId, setSelectdVoiceId] = useState('');
    const [playingVoice, setPlayingVoice] = useState(null);
    const [historyVoiceDetail, setHistoryVoiceDetail] = useState(null);
    const [detailLoading, setDetailLoading] = useState(false);
    let menuBg = useColorModeValue("white", "navy.900");
    const { isOpen, onOpen, onClose } = useDisclosure();

    function getInitials(name) {
        return name.split('')?.map(word => word[0]).join('').substring(0, 3); // Get up to three characters
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const today = new Date();

        // Remove the time part by setting to midnight
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        const diffTime = today - date;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
            return "Today";
        } else if (diffDays === 1) {
            return "Yesterday";
        } else {
            return `${diffDays} days ago`;
        }
    }
    function formatText(text) {
        return text
          .replace(/_/g, ' ')               // Replace underscores with spaces
          .split(' ')                       // Split the string into words
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
          .join(' ');                       // Join the words back into a single string
      }
    const handleShowCheckbox = () => {
        setShowCheckbox(true);
    }

    const handleSelectItem = (item) => {
        setSelectedItem(item);
    };

    const getHistoryDetails = async (historyData) => {
        const params = { filename: historyData?.filename };
        const header = { "Authorization": `${userToken}` }
        setDetailLoading(true);
        const { data } = await apiCall('post', apiEndPoint.HISTORYVOICEDETAIL, params, header);
        console.log("data44444444444", data);
        if (data.status !== 200) {
            setHistoryVoiceDetail(null);
            failedMessage(data?.message);
        } else {
            setHistoryVoiceDetail(data?.voice_detail);
        }
        setDetailLoading(false);
    }

    // const audioRef = useRef(null);
    const [playAudio, setPlayAudio] = useState(null)
    const [audioUrl, setAudioUrl] = useState(null)
    const [deleteVoice, setDeleteVoice] = useState([])
    const [currentPlaying, setCurrentPlaying] = useState(null);
    const audioRefs = useRef([]);
    const avatarRef = useRef(null);
    const flexRef = useRef(null);

    const downloadVoice = (format) => {
        setDownloadFormat(format);
        const voiceUrl = selectedItem?.file_path;
        const newSelectedvoice = selectedItem?.file_path?.split('/').pop().split('.').shift();
        const fileName = `${newSelectedvoice}.${format}`;

        if (format === 'mp3' || format === 'wav') {
            // For this example, we're assuming the format is already correct.
            saveAs(voiceUrl, fileName);
        }

        // Optionally reset download format after action
        setDownloadFormat('');
    };

    //voice play & playbar code 

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = useRef(new Audio());

    useEffect(() => {
        const audio = audioRef.current;
        if (playingVoice) {
            audio.src = playingVoice?.file_path;
            audio?.play();
            setIsPlaying(true);

            audio?.addEventListener('timeupdate', handleTimeUpdate);
            audio?.addEventListener('loadedmetadata', () => {
                setDuration(audio.duration);
            });

            return () => {
                audio?.pause();
                audio?.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, [playingVoice]);

    const togglePlayPause = (voice) => {
        if (playingVoice?.filename === voice?.filename) {
            if (isPlaying) {
                audioRef?.current?.pause();
            } else {
                audioRef?.current?.play();
            }
            setIsPlaying(!isPlaying);
        } else {
            setPlayingVoice(voice);
        }
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleSeek = (value) => {
        audioRef.current.currentTime = value;
        setCurrentTime(value);
    };

    const handleRewind = () => {
        audioRef.current.currentTime = Math.max(0, currentTime - 5);
    };

    const handleForward = () => {
        audioRef.current.currentTime = Math.min(duration, currentTime + 5);
    };

    const handleStop = () => {
        audioRef?.current?.pause();
        setIsPlaying(false);
        setPlayingVoice(null);
        setCurrentTime(0);
    };
    /*End-------------------- here*/

    const handleSelectDelete = (selectedData) => {
        let arr = [...deleteVoice];
        console.log('selectedData: ', selectedData);
        const filename = selectedData?.filename;

        if (arr.includes(filename)) {
            arr = arr.filter(selected => selected !== filename);
        } else {
            arr.push(filename);
        }

        setDeleteVoice(arr);
    };
    const handleDeleteVoice = async () => {
        if (deleteVoice.length > 0) {
            const formDetail = { filename: deleteVoice, action: "delete" }
            const header = { "Authorization": `${userToken}`, 'Content-Type': 'application/json' }
            const { data } = await apiCall('post', apiEndPoint.GENERATEDSPEECHPARAMETER, formDetail, header);
            if (data.status !== 200) {
               
                failedMessage(data?.message);
            }
            else {
                successMessage(data?.message);
                setDeleteVoice([])
                setShowCheckbox(false);
                getHistoryList()
            }
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            try {
                if (
                    flexRef.current && !flexRef.current.contains(event.target) ||
                    avatarRef.current && !avatarRef.current.contains(event.target)
                ) {
                    handleStop();
                }
            } catch (error) {
                console.error("An error occurred while checking the click outside:", error);
                // Additional error handling logic can go here if needed
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
     <Box m={'auto'} width={{ md: '100%', lg: '70%'}} mt={10}>
         { isEmpty(voiceHistory) ? 
         <center>
          <Image src={NoDataFound} width={200}/>
          <Text fontSize={14}>There is No Generated Speech Synthesis. Please Generate. </Text> 
          </center> :
          <Fragment>
            <Flex justifyContent='space-between' alignItems={"center"}>
                <Text my={4} fontSize={'md'} fontWeight={'500'}>
                    Speech Synthesis History
                </Text>
                <Flex justifyContent='space-evenly' alignItems={"center"}>
                    {deleteVoice.length > 0 && showCheckbox &&
                        <Tooltip label='Delete History' hasArrow fontSize='xs '>
                            <DeleteIcon onClick={handleDeleteVoice} sx={{ marginRight: '5px' }} cursor={"pointer"} />
                        </Tooltip>
                    }
                    {showCheckbox ?
                        <IoMdCloseCircle onClick={() => setShowCheckbox(false)} size={20} cursor={"pointer"} />
                        :
                        <MdManageHistory onClick={handleShowCheckbox} aria-label='Manage Histroy' size={20} cursor={"pointer"} />
                    }
                </Flex>
            </Flex>
            <TableContainer>
                <Table variant="simple">
                    <Tbody>
                        {voiceHistory != undefined && voiceHistory.length > 0 && voiceHistory.map((item, index) => (
                            <Tr key={item.id}>

                                {showCheckbox ?
                                    <Td>
                                        {/* <Checkbox defaultChecked={deleteSpeechId.length > 0 ? deleteSpeechId.includes(item?.voice_id) ? true:false:false} onChange={(e)=>handleSelectDelete(item?.voice_id)}  /> */}

                                        {deleteVoice.length > 0 && deleteVoice.includes(item?.filename) ?
                                            // <Checkbox defaultChecked={true} onChange={(e)=>handleSelectDelete(item?.voice_id)}/>
                                            <input type="checkbox" name="vehicle1" checked={true} onChange={(e) => handleSelectDelete(item)} />
                                            // <input type="checkbox" name="vehicle1" />
                                            :
                                            <input type="checkbox" name="vehicle1" checked={false} onChange={(e) => handleSelectDelete(item)} />
                                            // <input type="checkbox" name="vehicle1" />
                                            // <Checkbox defaultChecked={false} onChange={(e)=>handleSelectDelete(item?.voice_id)}/>
                                        }
                                    </Td> : ''}
                                <Td p={0} onClick={(e) => { e.stopPropagation(); togglePlayPause(item); }} cursor={"pointer"}>
                                    {playingVoice?.filename === item.filename && isPlaying ?
                                        <IconButton
                                            icon={playingVoice?.filename === item.filename && isPlaying ? <FaPause /> : <FaPlay />}
                                            //   onClick={(e) => {
                                            //     e.stopPropagation();
                                            //     togglePlayPause(item);
                                            //   }}
                                            size="sm"
                                            mr={2}
                                            aria-label={playingVoice?.filename === item.generated_voice_id ? "Pause" : "Play"}
                                        /> :
                                        <Avatar ref={avatarRef} size="sm" borderRadius={15} name={item?.type} bg="gray.100" color="gray.500" getInitials={() => getInitials(item?.type)} />
                                    }
                                </Td>
                                <Td onClick={(e) => { e.stopPropagation(); togglePlayPause(item); }} cursor={"pointer"}>
                                    {truncateText(item?.text ? item?.text : item?.name, 50)}
                                    {!isEmpty(item?.date) &&
                                        <Text opacity={.6} fontSize={13} fontWeight={400}>{formatDate(item?.date)}
                                        </Text>}
                                </Td>

                                <Td justifyContent={'end'} display={'flex'}>
                                    <Stack direction='row' spacing={4}>
                                        <Button borderRadius={50}>
                                            <BsTicketDetailedFill fontSize={20}
                                                onClick={() => { getHistoryDetails(item); onOpen(); }} />
                                        </Button>
                                        <Popover>
                                            <PopoverTrigger>
                                                <IconButton
                                                    icon={<DownloadIcon fontSize={18} />}
                                                    aria-label="Download"
                                                    isRound
                                                    size="sm"
                                                    ml={2}
                                                    onClick={() => handleSelectItem(item)}
                                                />
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverCloseButton />
                                                <PopoverHeader>Select Download Format</PopoverHeader>
                                                <PopoverBody>
                                                    <Button colorScheme="blue" onClick={() => downloadVoice('mp3')} mr={3}>MP3</Button>
                                                    <Button colorScheme="green" onClick={() => downloadVoice('wav')}>WAV</Button>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>

                                    </Stack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            {playingVoice && (
                <Flex
                    ref={flexRef}
                    position="fixed"
                    bottom="0"
                    bg={menuBg}
                    boxShadow="lg"
                    p={4}
                    zIndex="1000"
                    w={{
                        base: "100%",
                        xl: "calc(100vw - 305px)",
                        "2xl": "calc(100vw - 305px)",
                    }}
                    right={{ base: "0", md: "0", lg: "0", xl: "0" }}
                >
                    <IconButton
                        icon={<FaBackward />}
                        onClick={handleRewind}
                        aria-label="Rewind 5 seconds"
                        mr={2}
                        size="sm"
                    />
                    <IconButton
                        icon={isPlaying ? <FaPause /> : <FaPlay />}
                        onClick={() => togglePlayPause(playingVoice)}
                        aria-label={isPlaying ? 'Pause' : 'Play'}
                        mr={2}
                        size="sm"
                    />
                    <IconButton
                        icon={<FaForward />}
                        onClick={handleForward}
                        aria-label="Forward 5 seconds"
                        mr={2}
                        size="sm"
                    />
                    <Slider
                        aria-label="Voice Progress"
                        value={currentTime}
                        min={0}
                        max={duration}
                        onChange={handleSeek}
                        flex="1"
                        mx={2}
                    >
                        <SliderTrack bg="gray.600">
                            <SliderFilledTrack bg="blue.400" />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Text color="white" mr={2}>
                        {Math.floor(currentTime / 60)}:{Math.floor(currentTime % 60).toString().padStart(2, '0')}
                    </Text>
                    <Text color="white" mr={2}>
                        / {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}
                    </Text>
                    <IconButton
                        icon={<FaTimes />}
                        onClick={handleStop}
                        aria-label="Close Playbar"
                        size="sm"
                    />
                </Flex>
            )}
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay />
                <ModalContent>
                    {detailLoading ?
                        <Box p={4}>
                            <center> <Text fontSize={'15'} fontWeight="400" mb={2}>
                            Loading...  </Text> 
                            <CircularProgress isIndeterminate color='navy.800' size={"20px"} /> 
                        </center>
                        </Box> :
                        <>
                            <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' m={4}>
                                <Avatar width={8} height={8} src={historyVoiceDetail?.name} />
                                <Box display={'flex'} alignItems={'center'}>
                                    <Text fontSize={16} fontWeight={500}>{historyVoiceDetail?.name}</Text>
                                    <Text fontSize={14} opacity={.5} ml={2}>{formatDate(historyVoiceDetail?.date)}</Text>
                                </Box>
                                <ModalCloseButton />
                            </Flex>
                            <ModalBody>
                                <Box maxHeight={'150px'} overflowY={'auto'} p={2}>
                                    <Text fontSize={'15'} fontWeight="400">
                                        {historyVoiceDetail?.text}
                                    </Text>
                                </Box>
                                {/* <Box my={4}>
                        <Button colorScheme="blue" my={3} borderRadius={50} width={'100%'}>Restore text</Button>
                        </Box> */}
                                <Divider mt={2} />
                                <Card mt={4} borderWidth={1} style={{ cursor: 'pointer' }}>
                                    <Text fontSize={16} fontWeight={500}>Settings</Text>
                                    <Box gap={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={2}>
                                        <Text fontSize={14} opacity={.5}>Model</Text>
                                        <Box
                                            borderStyle="dotted"
                                            borderBottomWidth={'1px'}
                                            borderColor="gray.400"
                                            width="60%" // Optional: Width of the box
                                        >        </Box>
                                        <Text fontSize={14} opacity={.5}>{historyVoiceDetail?.model ? formatText(historyVoiceDetail?.model) : ''}</Text>
                                    </Box>
                                    <Box gap={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={4}>
                                        <Text fontSize={14} opacity={.5}>Stability</Text>
                                        <Box
                                            borderStyle="dotted"
                                            borderBottomWidth={'1px'}
                                            borderColor="gray.400"
                                            width="-webkit-fill-available" // Optional: Width of the box
                                        >        </Box>
                                        <Text fontSize={14} opacity={.5}>{historyVoiceDetail?.stability}%</Text>
                                    </Box>
                                    <Box gap={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={4}>
                                        <Text fontSize={14} opacity={.5}>Similarity</Text>
                                        <Box
                                            borderStyle="dotted"
                                            borderBottomWidth={'1px'}
                                            borderColor="gray.400"
                                            width="-webkit-fill-available" // Optional: Width of the box
                                        >        </Box>
                                        <Text fontSize={14} opacity={.5}>{historyVoiceDetail?.similarilty}%</Text>
                                    </Box>
                                    <Box gap={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={4}>
                                        <Text fontSize={14} opacity={.5}>Style</Text>
                                        <Box
                                            borderStyle="dotted"
                                            borderBottomWidth={'1px'}
                                            borderColor="gray.400"
                                            width="-webkit-fill-available" // Optional: Width of the box
                                        >        </Box>
                                        <Text fontSize={14} opacity={.5}>{historyVoiceDetail?.style_exaggeration}%</Text>
                                    </Box>
                                    <Box gap={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={4}>
                                        <Text fontSize={14} opacity={.5}>Speaker boost</Text>
                                        <Box
                                            borderStyle="dotted"
                                            borderBottomWidth={'1px'}
                                            borderColor="gray.400"
                                            width="60%" // Optional: Width of the box
                                        >        </Box>
                                        <Text fontSize={14} opacity={.5}>{historyVoiceDetail?.speaker_boost === "true" ? "Enabled" : "Disabled"}</Text>
                                    </Box>
                                    {/* <Box mt={8}>
                            <Button colorScheme="blue" borderRadius={50} width={'100%'}>Restore settings</Button>
                            </Box> */}
                                </Card>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </>
                    }
                </ModalContent>
            </Modal>
            </Fragment>
        }
        </Box>
    );
};

export default HistorySpeech;
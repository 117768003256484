import { Box, Flex, Grid, Heading, IconButton, SimpleGrid, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { MdDriveFileRenameOutline, MdOutlineCreateNewFolder, MdOutlineTranslate } from "react-icons/md";
import { BsDownload, BsFileCheck, BsFileDiff, BsFileLock, BsFiletypeMp3, BsGrid3X3Gap } from "react-icons/bs";
import { FiDelete, FiMoreVertical } from 'react-icons/fi';
import { MdReport } from 'react-icons/md';
import { CiFileOn } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import { CiEdit, CiShare2, CiTrash } from "react-icons/ci";
import { IoIosInfinite } from 'react-icons/io';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { FiDownload } from "react-icons/fi";
import { BsFiletypeDocx, BsFiletypeTxt, BsFiletypePdf, BsFiletypeCsv, BsStopwatch } from "react-icons/bs";
import { IoBackspaceSharp, IoCopyOutline, IoSettings } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Card from 'components/card/Card';
import { ArrowBackIcon, DeleteIcon, EditIcon, RepeatIcon, SpinnerIcon } from '@chakra-ui/icons';
import { FaBackward, FaDownload, FaForward, FaPause, FaPlay, FaTimes } from 'react-icons/fa';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Input,
  VStack,
  HStack,
  Tooltip,
  Icon
} from '@chakra-ui/react'
import { Fragment, useEffect, useRef, useState } from 'react';
import { HiSpeakerWave } from 'react-icons/hi2';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { failedMessage } from 'utils/httpClient';
import { apiCall } from 'utils/httpClient';
import apiEndPoint from 'utils/apiEndPoint';
import { successMessage } from 'utils/httpClient';
import { ArrowForwardIcon } from '@chakra-ui/icons';


function AudioTranslations(props) {
  let menuBg = useColorModeValue("white", "navy.900");
  const { showDetails, setShowDetails, selectedData, setSelectedData, dataLoading, getTranscribedAudio } = props
  console.log('selectedData:111111111 ', selectedData);
  const { userToken } = useSelector(state => state.login)
  const { isOpen: isOpenModal2, onOpen: onOpenModal2, onClose: onCloseModal2 } = useDisclosure();
  const { isOpen: isOpenModal3, onOpen: onOpenModal3, onClose: onCloseModal3 } = useDisclosure();
  const { isOpen: isOpenModal4, onOpen: onOpenModal4, onClose: onCloseModal4 } = useDisclosure();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const [newName, setNewName] = useState(null);
  const [isLoading, setIsLoading] = useState(false)


  //voice play & playbar code 
  const [playingVoice, setPlayingVoice] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [hoveredSentenceIndex, setHoveredSentenceIndex] = useState(null);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(null); // For tracking the playing sentence
  const [showTimestamp, setShowTimestamp] = useState(false);
  const [downloadShowTimestamp, setDownloadShowTimestamp] = useState(false);
  const audioRef = useRef(new Audio());
  // State to keep track of selected formats
  const [selectedFormats, setSelectedFormats] = useState({
    pdf: false,
    docx: false,
    txt: false,
    csv: false,
    srt: false,
    vtt: false,
  });


  const [isEditing, setIsEditing] = useState(false);
  const [textToEdit, setTextToEdit] = useState('');
  const [sentenceIndexToEdit, setSentenceIndexToEdit] = useState(null);
  const { isOpen: isOpenModal5, onOpen: onOpenModal5, onClose: onCloseModal5 } = useDisclosure();

  const [findText, setFindText] = useState('');
  const [replaceText, setReplaceText] = useState('');
  const [matchCase, setMatchCase] = useState(false);
  const [matchWholeWords, setMatchWholeWords] = useState(false);
  const { isOpen: isOpenModal6, onOpen: onOpenModal6, onClose: onCloseModal6 } = useDisclosure();

  const [originalData, setOriginalData] = useState(null); // Store original data
  const [hasChanges, setHasChanges] = useState(false); // Track if there are changes

  useEffect(() => {
    // Initialize the original data on the first render
    if (originalData === null && selectedData) {
      setOriginalData(JSON.parse(JSON.stringify(selectedData))); // Deep copy the data
    }
  }, [selectedData, originalData]);

  // Enable "Discard Changes" if selectedData has been modified
  useEffect(() => {
    if (originalData) {
      setHasChanges(JSON.stringify(originalData) !== JSON.stringify(selectedData));
    }
  }, [selectedData, originalData]);

  // Discard changes and revert to the original data
  const handleDiscardChanges = () => {
    setSelectedData(JSON.parse(JSON.stringify(originalData))); // Deep copy the original data
  };

  const handleEditTranscriptToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleEditClick = (index) => {
    setSentenceIndexToEdit(index);
    setTextToEdit(selectedData.transcribed_text_with_timestamp[index].text);
    onOpenModal5();
  };

  const handleUpdateText = () => {
    const updatedData = [...selectedData.transcribed_text_with_timestamp];
    console.log('updatedData: ', updatedData);
    updatedData[sentenceIndexToEdit].text = textToEdit;
    console.log('updatedData[sentenceIndexToEdit].text: ', updatedData[sentenceIndexToEdit].text);
    setSelectedData({ ...selectedData, transcribed_text_with_timestamp: updatedData });
    onCloseModal5();
  };

  // Utility function to convert time string to seconds
  const convertTimeToSeconds = (timeString) => {
    // Check if timeString is not a string, convert it to a string (assuming valid numbers)
    if (typeof timeString !== "string") {
      console.warn("Expected string but received:", timeString); // Add a warning log for numbers
      return timeString; // If it's a number, assume it's already in seconds and return it
    }

    const cleanTimeString = timeString.replace(/[()]/g, "");
    const timeParts = cleanTimeString.split(":").map(Number);

    if (timeParts.length !== 2 || isNaN(timeParts[0]) || isNaN(timeParts[1])) {
      console.error("Failed to parse timeString:", timeString);
      return 0;
    }

    return timeParts[0] * 60 + timeParts[1];
  };

  // Load audio metadata to set the duration
  useEffect(() => {
    if (audioRef.current) {
      // Event listener for setting the duration when metadata is loaded
      const setAudioDuration = () => {
        const duration = audioRef.current?.duration;
        if (duration && !isNaN(duration)) {
          setDuration(duration);
        }
      };

      audioRef.current.addEventListener("loadedmetadata", setAudioDuration);
      audioRef.current.addEventListener("canplay", setAudioDuration); // Fallback if metadata is not enough

      return () => {
        // Cleanup event listeners only if audioRef.current exists
        if (audioRef.current) {
          audioRef.current.removeEventListener("loadedmetadata", setAudioDuration);
          audioRef.current.removeEventListener("canplay", setAudioDuration);
        }
      };
    }
  }, []);

  // Handle clicking on a sentence to jump to that time
  const handleSentenceClick = (time, index) => {
    const seconds = convertTimeToSeconds(time);
    if (audioRef.current && !isNaN(seconds)) {
      audioRef.current.currentTime = seconds; // Jump to the specific time
      setCurrentTime(seconds); // Update the currentTime state
      audioRef.current.play(); // Play the audio after jumping
      setIsPlaying(true); // Set playing state to true
      setCurrentSentenceIndex(index); // Update the current sentence index to mark it as playing
    } else {
      console.error("Invalid time:", time); // Log an error if time is invalid
    }
  };
  // Update current time for highlighting text
  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  // Handle slider value change (when user seeks)
  const handleSliderChange = (value) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value; // Set audio time to slider value
      setCurrentTime(value); // Update state for currentTime
    }
  };


  // Handle play/pause button click
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  const handleStop = () => {
    audioRef?.current?.pause();
    setIsPlaying(false);
    setPlayingVoice(null);
    setCurrentTime(0);
    setCurrentSentenceIndex(null);
    setHoveredSentenceIndex(null)
  };
  /*End-------------------- here*/

  // Helper function to download a file
  const downloadFile = (filename, content, contentType) => {
    const blob = new Blob([content], { type: contentType });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // PDF Download handler using jsPDF
  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text(selectedData.name, 10, 10); // Title at the top
    doc.setFontSize(12);
    doc.text(downloadShowTimestamp ? selectedData?.transcribed_text_with_timestamp.map((sentence, index) =>
      (`${sentence.time} ${sentence.text}`)) : selectedData.transcribed_text_without_timestamp, 10, 30); // Text below the title
    doc.save(`${selectedData.name}.pdf`);
  };


  // DOCX Download handler
  const handleDownloadDocx = () => {
    const docxContent = `Title: ${selectedData.name}\n\n${downloadShowTimestamp ? selectedData?.transcribed_text_with_timestamp.map((sentence, index) =>
      (`${sentence.time} ${sentence.text}`)) : selectedData.transcribed_text_without_timestamp}`;
    downloadFile(`${selectedData.name}.docx`, docxContent, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
  };

  // TXT Download handler
  const handleDownloadTxt = () => {
    const txtContent = `Title: ${selectedData.name}\n\n${downloadShowTimestamp ? selectedData?.transcribed_text_with_timestamp.map((sentence, index) =>
      (`${sentence.time} ${sentence.text}`)) : selectedData.transcribed_text_without_timestamp}`;
    downloadFile(`${selectedData.name}.txt`, txtContent, 'text/plain');
  };

  // SRT Download handler
  const handleDownloadSrt = () => {
    const srtContent = `1\n00:00:00,000 --> 00:00:05,000\nTitle: ${selectedData.name}\n\n${downloadShowTimestamp ? selectedData?.transcribed_text_with_timestamp.map((sentence, index) =>
      (`${sentence.time} ${sentence.text}`)) : selectedData.transcribed_text_without_timestamp}`;
    downloadFile(`${selectedData.name}.srt`, srtContent, 'text/plain');
  };

  // VTT Download handler
  const handleDownloadVtt = () => {
    const vttContent = `WEBVTT\n\n00:00:00.000 --> 00:00:05.000\n${selectedData.name}\n\n${downloadShowTimestamp ? selectedData?.transcribed_text_with_timestamp.map((sentence, index) =>
      (`${sentence.time} ${sentence.text}`)) : selectedData.transcribed_text_without_timestamp}`;
    downloadFile(`${selectedData.name}.vtt`, vttContent, 'text/vtt');
  };

  // CSV Download handler
  const handleDownloadCsv = () => {
    const csvContent = `Title,Text\n"${selectedData.name}","${downloadShowTimestamp ? selectedData?.transcribed_text_with_timestamp.map((sentence, index) =>
      (`${sentence.time} ${sentence.text}`)) : selectedData.transcribed_text_without_timestamp}"`;
    downloadFile(`${selectedData.name}.csv`, csvContent, 'text/csv');
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (format) => {
    setSelectedFormats((prev) => ({
      ...prev,
      [format]: !prev[format],
    }));
  };

  // Function to handle download action
  const handleDownload = () => {
    // Check if the user selected PDF
    if (selectedFormats.pdf) {
      handleDownloadPdf(); // Call PDF download handler
    }

    // Check if the user selected DOCX
    if (selectedFormats.docx) {
      handleDownloadDocx(); // Call DOCX download handler
    }

    // Check if the user selected TXT
    if (selectedFormats.txt) {
      handleDownloadTxt(); // Call TXT download handler
    }

    // Check if the user selected CSV
    if (selectedFormats.csv) {
      handleDownloadCsv(); // Call CSV download handler
    }

    // Check if the user selected SRT
    if (selectedFormats.srt) {
      handleDownloadSrt(); // Call SRT download handler
    }

    // Check if the user selected VTT
    if (selectedFormats.vtt) {
      handleDownloadVtt(); // Call VTT download handler
    }
  };

  // Check if any format is selected
  const isAnyFormatSelected = Object.values(selectedFormats).some(
    (isSelected) => isSelected
  );

  const downloadVoice = (format) => {
    // setDownloadFormat(format);
    const voiceUrl = selectedData?.preview_url;
    const newSelectedvoice = selectedData?.preview_url?.split('/').pop().split('.').shift();
    const fileName = `${newSelectedvoice}.${format}`;

    if (format === 'mp3' || format === 'wav') {
      // For this example, we're assuming the format is already correct.
      saveAs(voiceUrl, fileName);
    }

    // Optionally reset download format after action
    // setDownloadFormat('');
  };


  const handleEditDelete = async (type) => {

    if (type === 'edit') {
      if (!isEmpty(selectedData?.name)) {
        var params = { action: 'edit', name: selectedData?.name, new_name: newName };
      } else {
        failedMessage("Please Fill New Name");
      }
    } else {
      if (type === 'delete') {
        var params = { action: 'delete', name: selectedData?.name };
      } else {
        var params = {};
      }
    }
    const header = { "Authorization": `${userToken}` }
    setIsLoading(true);
    const { data } = await apiCall('post', apiEndPoint.UPDATEDELETETRANSCRIBEDAUDIO, params, header);
    if (data.status !== 200) {

      failedMessage(data?.message);
    } else {
      successMessage(data?.message);
      onCloseModal3();
      onCloseModal2();
      setNewName(null);
      setShowDetails(false);
      getTranscribedAudio();
    }
    setIsLoading(false);
  }

  // Helper function to format time (if needed)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };


  // Find and Replace Logic
  const handleFindAndReplace = () => {
    const regexFlags = matchCase ? 'g' : 'gi'; // 'g' for global and 'i' for case-insensitive
    const wordBoundary = matchWholeWords ? '\\b' : ''; // Add word boundary if "Match whole words" is checked
    const regex = new RegExp(`${wordBoundary}${findText}${wordBoundary}`, regexFlags);

    const updatedData = selectedData.transcribed_text_with_timestamp.map((sentence) => {
      return {
        ...sentence,
        text: sentence.text.replace(regex, replaceText),
      };
    });

    setSelectedData({ ...selectedData, transcribed_text_with_timestamp: updatedData });
    onCloseModal6(); // Close the find and replace modal
    setFindText('');
    setReplaceText('');
  };

  const handleDoneEditing = async () => {
    const header = { "Authorization": `${userToken}` }
    const params = { name: selectedData?.name, edited_data: selectedData.transcribed_text_with_timestamp.map((sentence) => (`${sentence.time} ${sentence.text}`)) }
    setIsLoading(true);
    const { data } = await apiCall('post', apiEndPoint.EDITTRANSCRIBEDTEXT, params, header);
    if (data.status !== 200) {
      failedMessage(data?.message);
    } else {
      successMessage(data?.message);
      setIsEditing(false);
    }
    setIsLoading(false);
  }

  return (
    <>
      <Box mt={'1rem'}>
        <Button mb={'1rem'} onClick={() => { handleStop(); setShowDetails(false) }}>
          < ArrowBackIcon />
          <Text fontSize="md" fontWeight="400" pl={1} >
            Back
          </Text>
        </Button>
        {dataLoading ? <center><Spinner mt={5} /></center> :
          <HStack spacing={6} width={'100%'} alignItems={'flex-start'} display={{ sm: 'block', md: 'flex' }}>
            {/* <Card width={'20%'}>
            <Stack direction='column' spacing={4}>
              <Box>
                <Text fontSize="14" fontWeight="600" >
                  Shortcuts
                </Text>
                <Button my={2} leftIcon={<BsGrid3X3Gap />} variant='solid' fontWeight="400">
                  Recent Files
                </Button>
              </Box>
              <Box>
                <Text fontSize="14" fontWeight="600" >
                  Folder
                </Text>
                <Button my={2} leftIcon={<MdOutlineCreateNewFolder />} variant='solid' fontWeight="400">
                  New Folder
                </Button>
              </Box>
            </Stack>
          </Card> */}
            <Box w={{ sm: "100%", md: "60%", xl: '70%' }}>
              <Card overflow={'hidden'} overflowY={'auto'} h={{
                 sm: "calc(60vh - 300px)",
                 xl: "calc(100vh - 420px)",
                 "2xl": "calc(100vh - 200px)",
              }}>
                <Text fontSize="32" fontWeight="600" >
                  {selectedData?.name}
                </Text>
                {isEditing &&
                  <Text my={6} fontSize="14" fontWeight="500" opacity={.7} >
                    Note: Hover over any segment to make changes, After update or replace any changes , Please click on Done Editing to save Changes. If you want Discard, click on Discard Changes.
                  </Text>}
                <Text my={6} fontSize="14" fontWeight="500" opacity={.7} >
                  {selectedData?.date} {' '} {' '} {selectedData?.time}
                </Text>
                {/* <Text opacity={.8} fontSize="16" fontWeight="400" lineHeight={7}>
                {selectedData?.transcribed_text}
              </Text> */}
                <Text>
                  {selectedData?.transcribed_text_with_timestamp.map((sentence, index) => {
                    // Calculate end time: next sentence's start time minus 1 second
                    const nextStartTime = selectedData?.transcribed_text_with_timestamp[index + 1]?.time;
                    const endTime = nextStartTime ? convertTimeToSeconds(nextStartTime) - 1 : convertTimeToSeconds(sentence?.time) + 1;
                    return (
                      <Fragment key={index}>
                        <Popover trigger={isEditing ? "hover" : ''} placement="top" closeOnBlur={false}>
                          <PopoverTrigger>
                            <Text
                              as="span"
                              color={textColor}
                              p={0}
                              bgColor={currentSentenceIndex === index || hoveredSentenceIndex === index ? 'secondaryGray.400' : 'unset'}
                              cursor="pointer"
                              onClick={() => handleSentenceClick(convertTimeToSeconds(sentence.time, index))}
                              onMouseEnter={() => setHoveredSentenceIndex(index)} // Track when mouse enters
                              onMouseLeave={() => setHoveredSentenceIndex(null)}
                            >
                              {showTimestamp ? `${sentence.time} ${sentence.text}` : sentence.text}
                            </Text>
                          </PopoverTrigger>
                          <PopoverContent maxWidth={'200px'}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              {isEditing && (
                                <span
                                  onClick={() => handleEditClick(index)}
                                  style={{ cursor: 'pointer' }}
                                >

                                  <EditIcon style={{ fontSize: '18px' }} mr={2} />
                                  {`${formatTime(convertTimeToSeconds(sentence.time))} - ${formatTime(endTime)}`}
                                </span>
                              )
                                // : (
                                //   `${formatTime(convertTimeToSeconds(sentence.time))} - ${formatTime(endTime)}`
                                // )
                              }
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Fragment>
                    );
                  })}
                </Text>

              </Card>
              <Modal isOpen={isOpenModal5} onClose={onCloseModal5}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Edit Sentence</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Input value={textToEdit} onChange={(e) => setTextToEdit(e.target.value)} color={textColor} />
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleUpdateText}>
                      Update
                    </Button>
                    <Button variant="ghost" onClick={onCloseModal5}>Cancel</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              {/* Find and Replace Modal */}
              <Modal isOpen={isOpenModal6} onClose={onCloseModal6}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Find and Replace</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <VStack spacing={4} alignItems={'left'}>
                      <Input
                        placeholder="Find"
                        value={findText || ''}
                        onChange={(e) => setFindText(e.target.value)}
                        color={textColor}
                      />
                      <Input
                        placeholder="Replace"
                        value={replaceText || ''}
                        onChange={(e) => setReplaceText(e.target.value)}
                        color={textColor}
                      />
                      <Checkbox
                        isChecked={matchCase}
                        onChange={(e) => setMatchCase(e.target.checked)}

                      >
                        Match case
                      </Checkbox>
                      <Checkbox
                        isChecked={matchWholeWords}
                        onChange={(e) => setMatchWholeWords(e.target.checked)}
                      >
                        Match whole words only
                      </Checkbox>
                    </VStack>
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleFindAndReplace}>
                      Replace All
                    </Button>
                    <Button variant="ghost" onClick={onCloseModal6}>Cancel</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
            <Box w={{ sm: "100%", md: "40%", xl: '30%' }}>
              <Card overflow={'hidden'} overflowY={'auto'} h={{
                 sm: "calc(60vh - 300px)",
                 xl: "calc(100vh - 420px)",
                 "2xl": "calc(100vh - 200px)",
              }}>
                <Box>
                  <Card>
                    {isEditing &&
                      <Fragment>
                        <Text fontSize="18" fontWeight="600" >
                          Edit
                        </Text>
                        <Box mt={1}>
                          <Flex my={2}>
                            <Button leftIcon={<BsFileLock style={{ fontSize: '25px' }} />} variant='ghost'
                              onClick={onOpenModal6} >
                              <Text fontSize="sm" fontWeight="400" >
                                Find and Replace
                              </Text>
                            </Button>
                          </Flex>
                          <Flex my={2}>
                            <Button leftIcon={<Icon as={RepeatIcon} style={{ fontSize: '25px' }} />} variant='ghost'
                              onClick={handleDiscardChanges} isDisabled={!hasChanges} >
                              <Text fontSize="sm" fontWeight="400" >
                                Discard Changes
                              </Text>
                            </Button>
                          </Flex>
                          <Flex my={2}>
                            <Button leftIcon={<BsFileCheck style={{ fontSize: '25px' }} />} variant='ghost'
                              onClick={handleDoneEditing} >
                              <Text fontSize="sm" fontWeight="400" >
                                Done Editing
                              </Text>
                            </Button>
                          </Flex>
                        </Box>
                      </Fragment>
                    }
                    <Text fontSize="18" fontWeight="600" mt={3}>
                      Export
                    </Text>
                    <Box mt={1}>
                      <Flex my={2}>
                        <Button leftIcon={<BsFiletypePdf style={{ fontSize: '25px' }} />} variant='ghost'
                          onClick={handleDownloadPdf}
                        >
                          <Text fontSize="sm" fontWeight="400" >
                            Download PDF
                          </Text>
                        </Button>
                      </Flex>
                      <Flex my={2}>

                        <Button leftIcon={<BsFiletypeDocx style={{ fontSize: '25px' }} />} variant='ghost'
                          onClick={handleDownloadDocx}
                        >
                          <Text fontSize="sm" fontWeight="400" >
                            Download DOCX
                          </Text>
                        </Button>
                      </Flex>
                      <Flex my={2}>
                        <Button leftIcon={<BsFiletypeTxt style={{ fontSize: '25px' }} />} variant='ghost'
                          onClick={handleDownloadTxt}
                        >
                          <Text fontSize="sm" fontWeight="400" >
                            Download TXT
                          </Text>
                        </Button>
                      </Flex>
                      {/* <Flex my={2}>
                                        <Button leftIcon={<BsFiletypeCsv style={{ fontSize: '25px' }} />} variant='ghost'>
                                            <Text fontSize="sm" fontWeight="400" >
                                                Download CSV
                                            </Text>
                                        </Button>
                                    </Flex> */}
                      <Flex my={2}>
                        <Button leftIcon={<IoCopyOutline style={{ fontSize: '20px' }} />} variant='ghost'
                          onClick={handleDownloadSrt} >
                          <Text fontSize="sm" fontWeight="400" >
                            Download SRT
                          </Text>
                        </Button>
                      </Flex>
                      <Box my={2}>
                      <Button textAlign={'left'} leftIcon={<FiDownload style={{ fontSize: '20px' }} />} variant='ghost' onClick={onOpenModal4}>
                      <Text fontSize="sm" fontWeight="400" >
                        Advanced Export
                      </Text>
                    </Button>
                    <Text ml={4} fontSize="12px" fontWeight="400" >
                      Export with timestamps
                      and in more formats
                    </Text>
                        <Modal isOpen={isOpenModal4} onClose={onCloseModal4} isCentered>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalCloseButton />
                            <ModalHeader textAlign={'center'}>
                              <Text fontSize="16" fontWeight="600" >
                                {selectedData?.name}
                              </Text>
                            </ModalHeader>
                            <ModalBody>
                              <Text textAlign={'center'} fontSize="14" fontWeight="400" >
                                Choose one or more formats.
                              </Text>
                              <Box mt={6}>
                                <Flex my={2}>
                                  <Checkbox
                                    isChecked={selectedFormats.pdf}
                                    onChange={() => handleCheckboxChange('pdf')} >
                                    <Button leftIcon={<BsFiletypePdf style={{ fontSize: '25px' }} />} variant='ghost'>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Export as PDF
                                      </Text>
                                    </Button>
                                  </Checkbox>
                                </Flex>
                                <Flex my={2}>
                                  <Checkbox
                                    isChecked={selectedFormats.docx}
                                    onChange={() => handleCheckboxChange('docx')} >
                                    <Button leftIcon={<BsFiletypeDocx style={{ fontSize: '25px' }} />} variant='ghost'>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Export as DOCX
                                      </Text>
                                    </Button>
                                  </Checkbox>
                                </Flex>
                                <Flex my={2}>
                                  <Checkbox
                                    isChecked={selectedFormats.txt}
                                    onChange={() => handleCheckboxChange('txt')}>
                                    <Button leftIcon={<BsFiletypeTxt style={{ fontSize: '25px' }} />} variant='ghost'>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Export as TXT
                                      </Text>
                                    </Button>
                                  </Checkbox>
                                </Flex>
                                <Flex my={2}>
                                  <Checkbox
                                    isChecked={selectedFormats.csv}
                                    onChange={() => handleCheckboxChange('csv')} >
                                    <Button leftIcon={<BsFiletypeCsv style={{ fontSize: '25px' }} />} variant='ghost'>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Export as CSV
                                      </Text>
                                    </Button>
                                  </Checkbox>
                                </Flex>
                                <Flex my={2}>
                                  <Checkbox
                                    isChecked={selectedFormats.srt}
                                    onChange={() => handleCheckboxChange('srt')} >
                                    <Button leftIcon={<IoCopyOutline style={{ fontSize: '20px' }} />} variant='ghost'>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Export as SRT
                                      </Text>
                                    </Button>
                                  </Checkbox>
                                </Flex>
                                <Flex my={2}>
                                  <Checkbox
                                    isChecked={selectedFormats.vtt}
                                    onChange={() => handleCheckboxChange('vtt')}>
                                    <Button leftIcon={<IoCopyOutline style={{ fontSize: '20px' }} />} variant='ghost'>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Export as VTT
                                      </Text>
                                    </Button>
                                  </Checkbox>
                                </Flex>
                              </Box>
                              <Box mt={2}>
                                <Text textAlign={'center'} fontSize="14" fontWeight="400" >
                                  Settings
                                </Text>
                                <Flex my={2}>
                                  <Checkbox onChange={(e) => setDownloadShowTimestamp(e.target.checked)} isChecked={downloadShowTimestamp} >
                                    <Box>
                                      <Button leftIcon={<BsStopwatch style={{ fontSize: '16px' }} />} variant='none'>
                                        <Text fontSize="sm" fontWeight="400" >
                                          Section Timestamps
                                        </Text>
                                      </Button>
                                      <Text fontSize="11" ml={4} fontWeight="400" >
                                        Add timestamps before each section in PDF, DOCX, and TXT formats.
                                      </Text>
                                    </Box>
                                  </Checkbox>
                                </Flex>
                              </Box>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                isDisabled={!isAnyFormatSelected}
                                onClick={handleDownload}
                                isFullWidth
                                leftIcon={<FiDownload style={{ fontSize: '18px' }} />}  >
                                <Text fontSize="sm" fontWeight="400" >
                                  DOWNLOAD
                                </Text></Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Box>
                    </Box>

                    <Text fontSize="18" fontWeight="600" mt={3}>
                      More
                    </Text>
                    <Box>
                      <Flex my={2}>
                        <Button variant='ghost' textAlign={'left'} ml={1} >
                          <Checkbox onChange={(e) => setShowTimestamp(e.target.checked)} isChecked={showTimestamp} >
                            <Text fontSize="sm" fontWeight="400" ml={2} >
                              Show Timestamps
                            </Text>
                          </Checkbox>
                        </Button>
                      </Flex>
                      <Flex my={2}>
                        <Button variant='ghost' textAlign={'left'} ml={1} leftIcon={<EditIcon style={{ fontSize: '18px' }} />} onClick={handleEditTranscriptToggle} >
                          <Text fontSize="sm" fontWeight="400" ml={2} >
                            Edit Transcript
                          </Text>
                        </Button>
                      </Flex>
                      <Flex my={2}>
                        <Popover>
                          <PopoverTrigger>
                            <Button textAlign={'left'} leftIcon={<BsDownload style={{ fontSize: '20px' }} />} variant='ghost'>
                              <Text fontSize="sm" fontWeight="400" ml={2}>
                                Download Audio
                                {/* <Text mt={1} fontSize="12px" fontWeight="400" >
                                1.78 MB
                              </Text> */}
                              </Text>
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Select Download Format</PopoverHeader>
                            <PopoverBody>
                              <Button colorScheme="blue" onClick={() => downloadVoice('mp3')} mr={3}>MP3</Button>
                              <Button colorScheme="green" onClick={() => downloadVoice('wav')}>WAV</Button>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>

                      </Flex>
                      <Flex my={2}>
                        <Button textAlign={'left'} leftIcon={<MdDriveFileRenameOutline style={{ fontSize: '20px' }} />} variant='ghost' onClick={onOpenModal2} >
                          <Text fontSize="sm" fontWeight="400" ml={2} >
                            Rename File
                          </Text>
                        </Button>
                        <Modal isOpen={isOpenModal2} onClose={onCloseModal2} isCentered>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalCloseButton />
                            <ModalHeader textAlign={'center'}>
                              <Flex direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                <CiEdit style={{ marginRight: '8px' }} />
                                <Text fontSize="16" fontWeight="600" >
                                  Rename File
                                </Text>
                              </Flex>
                            </ModalHeader>
                            <ModalBody>
                              <Text fontSize="14" fontWeight="400" >
                                Name
                              </Text>
                              <Input placeholder='Rename here..' size='md' onChange={(e) => setNewName(e.target.value)} color={textColor} />
                            </ModalBody>
                            <ModalFooter>
                              <Button onClick={() => onCloseModal2()} isFullWidth disabled={isLoading} mr={2}>
                                <Text fontSize="sm" fontWeight="400" >
                                  Cancel
                                </Text></Button>
                              <Button
                                onClick={() => handleEditDelete('edit')}
                                isFullWidth
                              >
                                <Text fontSize="sm" fontWeight="400" >
                                  {isLoading ? <SpinnerIcon size={"md"} /> : 'Rename File'}
                                </Text></Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Flex>
                      <Flex my={2}>
                        <Button textAlign={'left'} leftIcon={<MdDeleteOutline style={{ fontSize: '20px' }} />} variant='ghost' onClick={onOpenModal3}>
                          <Text fontSize="sm" fontWeight="400" ml={2} >
                            Delete File
                          </Text>
                        </Button>
                        <Modal isOpen={isOpenModal3} onClose={onCloseModal3} isCentered>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalCloseButton />
                            <ModalHeader textAlign={'center'}>
                              <Flex direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                <MdDeleteOutline style={{ marginRight: '8px' }} />
                                <Text fontSize="16" fontWeight="600" >
                                  Delete File
                                </Text>
                              </Flex>
                            </ModalHeader>
                            <ModalBody>
                              <Text fontSize="14" fontWeight="400" >
                                Are you sure you want to delete this Transcribed Audio
                                <b> {selectedData?.name} {' '}</b> ?
                              </Text>
                            </ModalBody>
                            <ModalFooter>
                              <Button onClick={() => onCloseModal3()} isFullWidth disabled={isLoading} mr={2}>
                                <Text fontSize="sm" fontWeight="400" >
                                  Cancel
                                </Text></Button>
                              <Button onClick={() => handleEditDelete('delete')} isFullWidth>
                                <Text fontSize="sm" fontWeight="400" >
                                  {isLoading ? <SpinnerIcon size={"md"} /> : 'Delete File'}
                                </Text></Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Flex>
                    </Box>
                  </Card>
                </Box>
              </Card>
            </Box>
            <Box
              position="fixed"
              bottom="0"
              bg={menuBg}
              boxShadow="lg"
              p={4}
              zIndex="1000"
              w={{
                base: "100%",
                xl: "calc(100vw - 305px)",
                "2xl": "calc(100vw - 305px)",
              }}
              right={{ base: "0", md: "0", lg: "0", xl: "0" }}
            >
              <Text fontWeight="bold" textAlign={'center'} my={4}>{selectedData?.name}</Text>
              <Flex alignItems="center">
                <audio
                  ref={audioRef}
                  onTimeUpdate={handleTimeUpdate}
                  onLoadedMetadata={() => setDuration(audioRef.current.duration)}
                  src={selectedData.preview_url}
                  controls={false}
                />
                <IconButton
                  icon={isPlaying ? <FaPause /> : <FaPlay />}
                  // onClick={handlePlayPause}
                  aria-label="Play/Pause"
                  onClick={togglePlayPause}
                  isRound
                  size="sm"
                  mr={2}
                />
                {/* <IconButton
                icon={<FaBackward />}
                // onClick={handleRewind}
                aria-label="Rewind 10 seconds"
                isRound
                size="sm"
                mr={2}
              /> */}
                {/* <Slider aria-label='slider-ex-1' defaultValue={30}> */}
                <Slider
                  aria-label="Voice Progress"
                  value={currentTime}
                  min={0}
                  max={duration || 100}
                  onChange={handleSliderChange}
                  flex="1"
                  mx={8}
                // step={1}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
                <Text color="white" mr={2}>
                  {Math.floor(currentTime / 60)}:{Math.floor(currentTime % 60).toString().padStart(2, '0')}
                </Text>
                <Text color="white" mr={2}>
                  / {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}
                </Text>
                {/* <IconButton
                icon={<HiSpeakerWave />}
                // onClick={handleForward}
                aria-label="Forward 10 seconds"
                isRound
                size="sm"
                mx={4}
              />
              <Slider aria-label='slider-ex-1' defaultValue={30} width={150}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <IconButton
                icon={<IoSettings />}
                aria-label="Download"
                isRound
                size="sm"
                ml={4}
              /> */}
                {/* <IconButton
              icon={<FaTimes />}
              onClick={handleStop}
              aria-label="Close Playbar"
              size="sm"
            /> */}
              </Flex>
            </Box>
          </HStack>}
      </Box>
    </>
  );
}

export default AudioTranslations;
// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  Text,
  Button,
  Link,
  Heading,
} from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import AdminHome from "layouts/adminhome";
import AiAudioTrans from "layouts/aiaudiotranscription";
import AiMusicGeneration from "layouts/aimusicgeneration";
import AiVideoGeneration from "layouts/aivideogeneration";
import DataSets from "layouts/datasets";
import Dubbling from "layouts/dubbling";
import ImgGeneration from "layouts/imggeneration";
import PersonalFeed from "layouts/personalfeed";
import PricingPage from "layouts/pricing";
import SpeechTrans from "layouts/speechtrans";
import UserProfile from "layouts/userprofile";
import Voices from "layouts/voices";
import React, { useState } from "react";
import { TbTypography } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Navigate, Redirect, Route, Routes, Switch, useLocation } from "react-router-dom";
import routes from "routes.js";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  const { userDetail } = useSelector(state => state.login)
  console.log('userDetail: ', userDetail);

  const { pathname } = useLocation()

  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  document.documentElement.dir = "ltr";


  const moduleName = {
    '/pricing': <PricingPage />,
    '/voiceclone': <Voices />,
    '/admin': <AdminHome />,
    '/datasets': <DataSets />,
    '/user-profile': <UserProfile />,
    '/aispeechgeneration': <SpeechTrans />, 
    '/aivideogeneration': <AiVideoGeneration />, 
    '/audio-transcription': <AiAudioTrans />, 
    '/image-generation': <ImgGeneration />, 
    '/pfeed': <PersonalFeed />,
    '/dubbing': <Dubbling />,
    '/aimusicgeneration': <AiMusicGeneration />,
  }
  const handleRoutingPage = (pathName) => {
    return moduleName[pathName]
  }
  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            width={"100%"}
            w={{ base: "100%", xl: "calc( 100% - 305px )" }}
            maxWidth={{ base: "100%", xl: "calc( 100% - 305px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText={"UI Dashboard"}
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  message={getActiveNavbarText(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>

            {handleRoutingPage(pathname)}
            {/* <AdminHome/> */}

            {getRoute() ? (
              <Box>
                <Routes>
                  {/* {getRoutes(routes)} */}
                  <Route from="/" to="/admin" />
                  {/* <Navigate from="/datasets" to={<DataSets/>} /> */}
                  {/* <Route path="/" element={<Navigate to="/sign-in" />} /> */}
                </Routes>
              </Box>
            ) : null}
            <Box>{/* <Footer /> */}</Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import { thunk } from 'redux-thunk'
import rootReducer from './reducers';
import { decryptData, encryptData, getUTFStr } from '../utils/crypto';

function saveToLocalStorage(state) {
    try {
        localStorage.setItem("persistantState", encryptData(state));
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serialisedState = localStorage.getItem("persistantState");

        if (serialisedState === null) return undefined;
       
        return JSON.parse(getUTFStr(decryptData(serialisedState.toString())));
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

const middleware = [thunk]

const store = createStore(rootReducer, loadFromLocalStorage(), compose(applyMiddleware(...middleware)))

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Flex, FormControl, FormLabel, Icon, Image, Input, Menu, Progress, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useNumberInput } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { GoDatabase } from 'react-icons/go'
import NoDatasets from 'assets/img/layout/nodata.svg';
import {
} from '@chakra-ui/react'
import {
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import Uploadset from './components/uploadset';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import Banner from 'views/admin/profile/components/Storage';
import UploadStepper from './components/uploadstepper';
import { useRouter } from 'components/use-hook';
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from 'utils/httpClient';
import apiEndPoint from 'utils/apiEndPoint';
import { isEmpty } from 'utils/utils';
import IconBox from 'components/icons/IconBox';
import { MdOutlineCloudDone } from 'react-icons/md';
import Card from 'components/card/Card';
import { failedMessage } from 'utils/httpClient';
import { truncateText } from 'utils/utils';
import DatasetMenu from './components/datasetmenu';
import { successMessage } from 'utils/httpClient';

export default function DataSets(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorSecondary = "gray.400";
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 2,
      defaultValue: 1,
      min: 1,
      max: 6,
      precision: 2,
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  const router = useRouter();
  const reduxDispatch = useDispatch();
  const { userToken } = useSelector(state => state.login);
  const [datasetLoading, setDatasetLoading] = useState(false);
  const [allDataset, setAlldataset] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const getAllDataset = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setDatasetLoading(true);
    const { data } = await apiCall('get', apiEndPoint.DATASETLIST, params, header);
    if (data.status!==200) {
      failedMessage(data?.message)
      setAlldataset([]);
    } else {
      setAlldataset(data?.datasets);
      
    }
    setDatasetLoading(false);
  };


  // Filter datasets based on search query
  const filteredDatasets =  allDataset?.filter(dataset =>
    dataset.datasets_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    getAllDataset();
  }, [])

  return (
    <Box>
      <Box p={4} mt={{ sm: '12rem', md: '8rem' }}>
        <Tabs>
          <TabList>
            <Tab><GoDatabase style={{ marginRight: '10px' }} /> My Datasets</Tab>
            <Button colorScheme='blue' size={'sm'} ml={'auto'} variant="outline" onClick={onOpen}>Add Dataset</Button>
          </TabList>
          <SearchBar 
          my={5} 
          type="text"
          sx={{ border: '1px solid #808080', borderRadius: '8px' }}
          borderRadius="30px"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          />
          {!isEmpty(filteredDatasets) ?
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
              gap='20px'>
              {filteredDatasets.map((dataset, i) => (
                <Card mb={{ base: "0px", lg: "20px" }} align='center' borderWidth={1} id={i}>
                  <Flex w='100%'>
                    {/* <Menu ms='auto' /> */}
                    <DatasetMenu ms='auto' dataset={dataset} getAllDataset={getAllDataset} />
                  </Flex>
                  <IconBox
                    mx='auto'
                    h='100px'
                    w='100px'
                    icon={
                      <Icon as={MdOutlineCloudDone} color={brandColor} h='46px' w='46px' />
                    }
                    bg={box}
                  />
                  <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px'>
                    {dataset?.datasets_name}
                  </Text>
                  <Text
                    color={textColorSecondary}
                    fontSize='md'
                    maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
                    mx='auto'>
                    {truncateText(dataset?.datasets_description, 35)}
                  </Text>
                  {!isEmpty(dataset?.datasets_path) ?
                    <Box w='100%' mt='20px' >
                      <Flex w='100%' justify='space-between' mb='10px'>
                        <Text color={textColorSecondary} fontSize='sm' maxW='100%'>
                          {truncateText(dataset?.datasets_path?.substring(dataset?.datasets_path?.lastIndexOf('/') + 1), 25)}

                        </Text>
                        {/* <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                    {total} GB
                  </Text> */}
                      </Flex>
                      <Progress
                        align='start'
                        colorScheme='brandScheme'
                        // value={(used / total) * 100}
                        value={100}
                        w='100%'
                        size={"xs"}
                      />
                    </Box> : ''}
                </Card>
              ))}
              {/* <Banner /> */}
            </SimpleGrid>
            : ''
          }
          {isEmpty(filteredDatasets) &&
            <TabPanels>
              <TabPanel>
                <Box textAlign={'center'}>
                  <Box>
                    <Image m={'auto'} width={150} src={NoDatasets} borderRadius='0' />
                  </Box>
                  <Text fontSize='lg' fontWeight={500} my={5}>No Datasets</Text>
                  <Text fontSize='md' fontWeight={400} my={5}>Create one to start exploring your data.</Text>
                  <Button my={2} leftIcon={<AddIcon width={'10px'} />} variant="outline" onClick={onOpen}>
                    <Text fontSize={12} fontWeight={400}>
                      Dataset
                    </Text>{" "}
                  </Button>
                </Box>
              </TabPanel>
            </TabPanels>}
        </Tabs>
      </Box>
      <Drawer size='lg'
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <Text fontSize='md' fontWeight={500} my={5}>Create Dataset</Text>
              <UploadStepper onClose={onClose} getAllDataset={getAllDataset} />
            </DrawerBody>
            <DrawerFooter>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
    </Box>
  )
}
// Chakra Imports
import {
  Button,
  Icon,
  useColorMode,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Icons
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import React, { useState } from "react";
import { ButtonGroup, Box, IconButton } from "@chakra-ui/react";
export default function FixedPlugin2(props) {
  const { ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const [activeButton, setActiveButton] = useState("Advanced");
  const [disabledButtons, setDisabledButtons] = useState({
    Basic: true,
    Advanced: false
  });
  const bgButton = "linear-gradient(150deg, #868CFF 20%, #ffc600 100%)";

  const disabledStyle = {
    cursor: 'not-allowed',
    opacity: 1, // Make the disabled button look less interactive but still visible
  };

  const handleButtonClick = (buttonType) => {
    if (disabledButtons[buttonType]) return; // Exit if the button is disabled

    setActiveButton(buttonType);

    // Disable the clicked button and keep the other enabled
    setDisabledButtons((prevState) => ({
      ...prevState,
      [buttonType]: true,
      [buttonType === "Basic" ? "Advanced" : "Basic"]: false
    }));

    toggleColorMode();
  };
  // let bgButton = "linear-gradient(150deg, #868CFF 0%, #ffc600 100%)";
  return (
    <Box>
      <ButtonGroup size={{sm:'md', md: 'lg'}} isAttached variant="outline">
        <Button
            onClick={() => handleButtonClick("Basic")}
          bg={activeButton === "Basic" ? bgButton : undefined}
          style={disabledButtons.Basic ? disabledStyle : {}}
          isDisabled={disabledButtons.Basic} // Still set isDisabled for accessibility
        >
          <Text as="span" fontWeight={400} fontSize={16}>
            Basic
          </Text>
        </Button>
        <Button
          onClick={() => handleButtonClick("Advanced")}
          bg={activeButton === "Advanced" ? bgButton : undefined}
          style={disabledButtons.Advanced ? disabledStyle : {}}
          isDisabled={disabledButtons.Advanced} // Still set isDisabled for accessibility
          
        >
          <Text as="span" fontSize={16}>
            Advanced
          </Text>
        </Button>
      </ButtonGroup>
      {/* <Button
        {...rest}
        h="40px"
        w="40px"
        zIndex="99"
        border="1px solid"
        borderColor="#6A53FF"
        borderRadius="50px"
        display="flex"
        p="0px"
        align="center"
        justify="center"
      >
        <Icon
          h="18px"
          w="18px"
          color="white"
          // as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
        Basic
      </Button> */}
    </Box>
  );
}
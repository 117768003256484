import { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
  Box,
  AspectRatio,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Divider,
  TableContainer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Portal,
  usePopover,
  PopoverFooter,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  HStack,
} from '@chakra-ui/react';
import { FiDelete, FiMoreVertical } from 'react-icons/fi';
import { MdReport } from 'react-icons/md';
import { CiFileOn } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import { CiEdit, CiShare2, CiTrash } from "react-icons/ci";
import { IoIosInfinite } from 'react-icons/io';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { FiDownload } from "react-icons/fi";
import { BsFiletypeDocx, BsFiletypeTxt, BsFiletypePdf, BsFiletypeCsv, BsStopwatch } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Card from 'components/card/Card';
import { isEmpty } from 'utils/utils';
import { saveAs } from 'file-saver';


const AudioDubblingfile = (props) => {
  const { allDubbedFile, setAllDubbedFile, allParameter, handleDelete, setSelectedItem, selectedItem, isOpenModal2, onOpenModal2, onCloseModal2, isOpenModal3, onOpenModal3, onCloseModal3 } = props;
  const popoverProps = usePopover();


  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };


  const downloadVoice = (format) => {
    // setDownloadFormat(format);
    const voiceUrl = selectedItem?.preview_url;
    const newSelectedvoice = selectedItem?.preview_url?.split('/').pop().split('.').shift();
    const fileName = `${newSelectedvoice}.${format}`;

    if (format === 'mp3' || format === 'wav' || format === 'mp4' || format === 'mkv') {
      // For this example, we're assuming the format is already correct.
      saveAs(voiceUrl, fileName);
    }

    // Optionally reset download format after action
    // setDownloadFormat('');
  };

  function formatTime(seconds) {
    // Convert seconds to hours, minutes, and seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Format the time string
    let timeString = '';

    if (hours > 0) {
      timeString += `${hours}h`;
    }
    if (minutes > 0) {
      timeString += ` ${minutes}m`;
    }
    if (remainingSeconds > 0 || seconds === 0) {
      timeString += ` ${remainingSeconds}s`;
    }

    return timeString.trim(); // Remove any leading or trailing spaces
  }


  return (

    <Box m={2} >
      <TableContainer overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              {/* <Th></Th> */}
              <Th>Name</Th>
              <Th>Language</Th>
              <Th>Status</Th>
              <Th>Created</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isEmpty(allDubbedFile) && allDubbedFile.map((item, i) => (
              <Tr key={i}>
                {/* <Td>{item.id}</Td> */}
                <Td>{item?.name}</Td>
                <Td>{allParameter?.target_lang?.find(lang => lang.value === item.target_lang)?.name} </Td>
                <Td>Dubbed</Td>
                <Td>{item?.date}</Td>
                <Td></Td>
                <Td sx={{ textAlign: "right !important" }}>
                  <Button variant='outline' onClick={() => { handleSelectItem(item); onOpenModal2(); }}>
                    <Text fontSize="sm" fontWeight="400" >
                      Remove
                    </Text>
                  </Button>
                  <Popover>
                    <PopoverTrigger>
                      <Button variant='outline' ml={4}
                        onClick={() => handleSelectItem(item)} >
                        <Text fontSize="sm" fontWeight="400" >
                          Download
                        </Text>
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent >
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Select Download Format</PopoverHeader>
                        <PopoverBody>
                          {selectedItem?.preview_url?.split('.').pop() === 'mp4' || selectedItem?.preview_url?.split('.').pop() === 'mkv' ?
                            <>
                              <Button size="sm" colorScheme="blue" onClick={() => downloadVoice('mp4')} mr={3}>MP4</Button>
                              <Button size="sm" colorScheme="green" onClick={() => downloadVoice('mkv')}>MKV</Button></> :
                            <>
                              <Button size="sm" colorScheme="blue" onClick={() => downloadVoice('mp3')} mr={3}>MP3</Button>
                              <Button size="sm" colorScheme="green" onClick={() => downloadVoice('wav')}>WAV</Button>
                            </>

                          }
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  <Button variant='outline' ml={4} onClick={() => { handleSelectItem(item); onOpenModal3(); }}>
                    <Text fontSize="sm" fontWeight="400" >
                      View
                    </Text>
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal isOpen={isOpenModal2} onClose={onCloseModal2} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize={'15'} fontWeight="400" >
              Remove Dub
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box>
              <Text fontSize={'12'} fontWeight="400" my={2}>
                Do you really want to remove the dub "{selectedItem?.name}"?
              </Text>
            </Box>
            <Box mt={6} textAlign={'end'}>
              <Button fontWeight={500} fontSize={14} variant='outline' size='sm'
                onClick={onCloseModal2}>
                Cancels
              </Button>
              <Button fontWeight={500} fontSize={14} ml={4} size='sm' onClick={() => handleDelete(selectedItem)}>
                Remove Dub
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenModal3} onClose={onCloseModal3} isCentered size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize={'15'} fontWeight="400" >
              {selectedItem?.name}
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box>
              <Box>
                {selectedItem?.preview_url?.split('.').pop() === 'mp4' || selectedItem?.preview_url?.split('.').pop() === 'mkv' ?
                  <AspectRatio height={'250px'} ratio={1}>
                    <video src={selectedItem?.preview_url} controls />
                  </AspectRatio> :
                  <HStack border={'2px dashed'} borderRadius={10} p={2} width={"100%"} justifyContent={"space-between"}>
                    <Text fontSize="sm">
                      {selectedItem?.filename}
                    </Text>
                  </HStack>
                }
              </Box>
              <Box my={4}>
                <Flex gap={5} alignItems={'center'} justifyContent={'space-between'}>
                  <Box my={2}>
                    <Text fontSize={'14'} fontWeight="500">
                      {selectedItem?.name}
                    </Text>
                    <Text fontSize={'12'} fontWeight="400">
                      {selectedItem?.date}, {selectedItem?.time}
                    </Text>
                  </Box>
                  {/* <Button fontWeight={500} fontSize={14} variant='outline' size='sm'>
                                            Download
                                        </Button> */}

                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" borderRadius={50} fontSize={12} p={4}>Download</Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Select Download Format</PopoverHeader>
                      <PopoverBody>
                        {selectedItem?.preview_url?.split('.').pop() === 'mp4' || selectedItem?.preview_url?.split('.').pop() === 'mkv' ?
                          <>
                            <Button size="sm" colorScheme="blue" onClick={() => downloadVoice('mp4')} mr={3}>MP4</Button>
                            <Button size="sm" colorScheme="green" onClick={() => downloadVoice('mkv')}>MKV</Button></> :
                          <>
                            <Button size="sm" colorScheme="blue" onClick={() => downloadVoice('mp3')} mr={3}>MP3</Button>
                            <Button size="sm" colorScheme="green" onClick={() => downloadVoice('wav')}>WAV</Button>
                          </>

                        }
                      </PopoverBody>  </PopoverContent>
                  </Popover>

                </Flex>
              </Box>
              <Card>
                <Flex justifyContent={'space-between'} mx={2}>
                  <Box my={2}>
                    <Text fontSize={'14'} fontWeight="500">
                      Source language
                    </Text>
                    <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                      {!isEmpty(selectedItem) && !isEmpty(selectedItem?.source_lang) ?
                        allParameter?.source_lang?.find(lang => lang.value === selectedItem.source_lang)?.name
                        :
                        'Not Selected'
                      }
                    </Text>
                    <Box my={2}>
                      <Text fontSize={'14'} fontWeight="500">
                        Source
                      </Text>
                      <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                        Upload
                      </Text>
                    </Box>
                    {selectedItem?.preview_url?.split('.').pop() === 'mp4' || selectedItem?.preview_url?.split('.').pop() === 'mkv' ?
                      <Box my={2}>
                        <Text fontSize={'14'} fontWeight="500">
                          Video Resolution
                        </Text>
                        <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                          {selectedItem?.highest_resolution === 'true' ? "Highest" : "Medium"}
                        </Text>
                      </Box> : ''}
                    <Box my={2}>
                      <Text fontSize={'14'} fontWeight="500">
                        Dubbed Range
                      </Text>
                      <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                        {!isEmpty(selectedItem) && !isEmpty(selectedItem?.start_time) && !isEmpty(selectedItem?.end_time) && selectedItem?.start_time > '0' && selectedItem?.end_time > "0" ?
                          `${formatTime(selectedItem?.start_time)} - ${formatTime(selectedItem?.end_time)}` :
                          'No Range'}
                      </Text>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <Text fontSize={'14'} fontWeight="500">
                      Target language
                    </Text>
                    <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                      {!isEmpty(selectedItem) && !isEmpty(selectedItem?.target_lang) ?
                        allParameter?.target_lang?.find(lang => lang.value === selectedItem.target_lang)?.name
                        :
                        'Not Selected'
                      }
                    </Text>
                    <Box my={2}>
                      <Text fontSize={'14'} fontWeight="500">
                        No of speakers
                      </Text>
                      <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                        {!isEmpty(selectedItem) && !isEmpty(selectedItem?.num_speakers) ?
                          selectedItem?.num_speakers : 'Not Detect'}
                      </Text>
                    </Box>
                    <Box my={2}>
                      <Text fontSize={'14'} fontWeight="500">
                        Watermarked
                      </Text>
                      <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                        {!isEmpty(selectedItem) && selectedItem?.watermark === 'true' ?
                          "Yes" : "No"}
                      </Text>
                    </Box>
                    <Box my={2}>
                      <Text fontSize={'14'} fontWeight="500">
                        Drop Background Audio
                      </Text>
                      <Text fontSize={'12'} fontWeight="400" opacity={.6}>
                        {!isEmpty(selectedItem) && selectedItem?.drop_background_audio === 'true' ?
                          "Yes" : "No"}
                      </Text>
                    </Box>
                  </Box>
                </Flex>
              </Card>
            </Box>
            <Box mt={6} textAlign={'end'}>
              <Button fontWeight={500} fontSize={14} variant='outline' size='sm' onClick={onCloseModal3}>
                Cancel
              </Button>
              <Button fontWeight={500} fontSize={14} ml={4} size='sm' onClick={onOpenModal2}>
                Remove Dub
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>

  );
};

export default AudioDubblingfile;
import React, { useRef, useState } from 'react';
import { ChakraProvider, Flex, Input, Button, VStack, Text, InputGroup, InputRightElement, Box, useColorModeValue, Spacer, StackDivider, Divider, Menu, MenuButton, MenuItem, MenuList, ButtonGroup, IconButton, Select, Image } from "@chakra-ui/react";
import { IoSendOutline } from 'react-icons/io5';
import { IoIosMore, IoMdSend } from 'react-icons/io';
import { ImAttachment } from 'react-icons/im';
import { AddIcon } from '@chakra-ui/icons';
import { FaMicrophone } from 'react-icons/fa';
import loaderGif from 'assets/img/layout/gif61.gif';

import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react'
const ChatMessage = ({ message, isBot }) => {
  let menuBg = useColorModeValue("white", "secondaryGray.600");

  return (
    <Flex>
      <Text my={2}
        bg={menuBg}
        p={3}
        fontSize='md'
        maxW='95%'
        borderRadius="lg"
        boxShadow="md"
      >
        {message}
      </Text>
    </Flex>
  );
};

const Chatbot = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showBrainImage, setShowBrainImage] = useState(false);

  const addMessage = (text, isBot = false) => {
    setMessages([...messages, { text, isBot }]);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === "") return;
    addMessage(inputValue);
    // Here you can add logic for handling bot responses
    setInputValue("");
  };
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Handle file upload logic here
    console.log("File selected:", file);
  };
  const [sliderValue, setSliderValue] = useState(1000)

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }

  return (
    <Box pt={{ base: "80px", md: "50px", xl: "100px" }}>
      <Flex mt='5'>
        <Box w='100%'>
          <VStack p={4} pb='0' maxH="85vh" justifyContent="flex-end">
            <VStack width="100%" display='block' h='75vh' overflowY='auto'>
              {messages.map((msg, index) => (
                <ChatMessage key={index} message={msg.text} isBot={msg.isBot} />
              ))}
            </VStack>
            {showBrainImage &&
              <Image src={loaderGif} sx={{ position: 'absolute', top: '30%' }} />
            }
            <Flex width="100%">
              <InputGroup>
                <InputRightElement onClick={handleSendMessage}
                  children={<Flex> <Box mr='5' mt='2.5'><FaMicrophone cursor="pointer" /></Box><Box mr='5' mt='2.5' onClick={handleFileUpload}><ImAttachment />  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  </Box> <Box mr='20' mt='2'><IoMdSend onClick={() => setShowBrainImage(true)} cursor="pointer" size='20px' /></Box> </Flex>}
                />
                <Input size='lg' disabled value={inputValue} borderWidth={2.5} borderColor={textColor}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Send a message ..." />
              </InputGroup>
              {/* <Button onClick={handleSendMessage} colorScheme="blue">
                    Send
                </Button> */}
            </Flex>
          </VStack>
        </Box>
        {/* <Box w='25%' position={'relative'} sx={{ borderLeft: '1px solid #2d3748', paddingLeft: '1rem' }}>
                    <Button leftIcon={<AddIcon />} variant='outline' borderRadius={5} isFullWidth>
                        NEW CHAT
                    </Button>
                    <Divider my='10' />
                    <ButtonGroup my={2} size='lg' isFullWidth isAttached variant='outline' w='100%'>
                        <Button borderRadius={5} sx={{ width: '-webkit-fill-available' }} fontSize='md' fontWeight='500'>Chat Bot Definition</Button>
                        <IconButton borderRadius={5} aria-label='Add to friends' icon={<ButtonWithMenu />} />
                    </ButtonGroup>
                    <ButtonGroup my={2} size='lg' isFullWidth isAttached variant='outline' w='100%'>
                        <Button borderRadius={5} sx={{ width: '-webkit-fill-available' }} fontSize='md' fontWeight='500'>Chat Bot Definition</Button>
                        <IconButton borderRadius={5} aria-label='Add to friends' icon={<ButtonWithMenu />} />
                    </ButtonGroup>
                    <ButtonGroup my={2} size='lg' isFullWidth isAttached variant='outline' w='100%'>
                        <Button borderRadius={5} sx={{ width: '-webkit-fill-available' }} fontSize='md' fontWeight='500'>Chat Bot Definition</Button>
                        <IconButton borderRadius={5} aria-label='Add to friends' icon={<ButtonWithMenu />} />
                    </ButtonGroup>
                    <Box position={'absolute'} bottom={'1rem'} w={'100%'}>
                        <Select placeholder='Select Category'>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </Select>
                        <Text fontSize={'13'} fontWeight="500">
                            No. of characters
                        </Text>
                        <Slider aria-label='slider-ex-6' onChange={(val) => setSliderValue(val)}>
                            <SliderMark value={5} {...labelStyles}>
                                10
                            </SliderMark>
                            <SliderMark value={90} {...labelStyles}>
                                1000
                            </SliderMark>
                            <SliderMark
                                value={sliderValue}
                                textAlign='center'
                                bg='blue.500'
                                color='white'
                                mt='-10'
                                ml='-5'
                                w='12'
                            >
                                {sliderValue}
                            </SliderMark>
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </Box>
                </Box> */}
      </Flex>
    </Box>
  );
};
const ButtonWithMenu = () => {
  return (
    <Menu>
      <MenuButton >
        <IoIosMore />
      </MenuButton>
      <MenuList>
        <MenuItem >Edit</MenuItem>
        <MenuItem>Delete</MenuItem>
      </MenuList>
    </Menu>
  );
};

const AiChatBot = () => {
  return (
    <Box>
      <Chatbot />
    </Box>
  );
};

export default AiChatBot;
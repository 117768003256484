import React, { Fragment, useEffect, useRef, useState } from "react";

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  ModalFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Spacer,
  ButtonGroup,
  Textarea,
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdOutlinePerson,
  MdOutlineCardTravel,
  MdOutlineLightbulb,
  MdOutlineSettings,
  MdOutlineDelete,
  MdOutlineEdit,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { apiCall } from "utils/httpClient";
import apiEndPoint from "utils/apiEndPoint";
import { successMessage } from "utils/httpClient";
import { failedMessage } from "utils/httpClient";
import { isEmpty } from "utils/utils";

export default function DatasetMenu(props) {
  const { dataset, getAllDataset, ...rest } = props;
  console.log('dataset:1111111 ', dataset);
  const btnRef = useRef();
  const { userToken } = useSelector(state => state.login);
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [datasetFormData, setDatasetFormdata] = useState({});
  console.log('datasetFormData: 55555', datasetFormData);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const handleDeleteDataset = async () => {
    const params = { action: "delete", datasets_name: dataset?.datasets_name };
    const header = { "Authorization": `${userToken}` }
    setIsLoading(true);
    const { data } = await apiCall('post', apiEndPoint.EDITDELETEDATASET, params, header);
    if (data.status!==200) {
      failedMessage(data?.message)
    } else {
      successMessage(data?.message)
      getAllDataset();
      onClose2();
    }
    setIsLoading(false);
  };

  const validation = () => {
    let isError = true;
    let errorMessage = {};
    if (isEmpty(datasetFormData.datasets_name)) {
      isError = false;
      errorMessage["datasets_name"] = "Please fill the dataset name";

    }
    if (isEmpty(datasetFormData.datasets_description)) {
      isError = false;
      errorMessage["datasets_description"] = "Please fill the description";
    }
    setErrorMessage(errorMessage);
    return isError;
  };

  const handleCreateDataset = async () => {
    if (validation()) {
      const header = { "Authorization": `${userToken}` }
      const params = {
        action: "edit",
        datasets_name: dataset?.datasets_name,
        new_name: datasetFormData?.datasets_name,
        new_description: datasetFormData?.datasets_description
      }
      setSubmitLoading(true);
      const { data } = await apiCall('post', apiEndPoint.EDITDELETEDATASET, params, header);
      console.log('data88: ', data);
      if (data.status!==200) {
        
        failedMessage(data?.message);
      } else {
        successMessage(data?.message);
        getAllDataset();
        onClose3();
      }
      setSubmitLoading(false);
    }
  }

  useEffect(() => {
    setDatasetFormdata(dataset);
  }, [])


  return (
    <Fragment>
      <Menu isOpen={isOpen1} onClose={onClose1}>
        <MenuButton
          align='center'
          justifyContent='center'
          _hover={bgHover}
          w='37px'
          h='37px'
          lineHeight='100%'
          onClick={onOpen1}
          borderRadius='10px'
          {...rest}>
          <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
        </MenuButton>
        <MenuList
          w='150px'
          minW='unset'
          maxW='150px !important'
          border='transparent'
          backdropFilter='blur(63px)'
          boxShadow={bgShadow}
          borderRadius='10px'
          p='10px'>
          <MenuItem
            transition='0.2s linear'
            color={textColor}
            p='8px'
            borderRadius='8px'>
            <Flex align='center' onClick={onOpen3}>
              <Icon as={MdOutlineEdit} h='16px' w='16px' me='8px' />
              <Text fontSize='sm' fontWeight='400'>
                Edit
              </Text>
            </Flex>
          </MenuItem>
          <MenuItem
            transition='0.2s linear'
            p='8px'
            borderRadius='8px'
            color={textColor}>
            <Flex align='center' onClick={onOpen2}>
              <Icon as={MdOutlineDelete} h='16px' w='16px' me='8px' />
              <Text fontSize='sm' fontWeight='400'>
                Delete
              </Text>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpen2} onClose={onClose2} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Dataset Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize='lg'>
              Are you sure you want to delete this dataset? This action is irreversible and will permanently remove dataset.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose2}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={() => handleDeleteDataset(dataset)} >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer size='lg'
        isOpen={isOpen3}
        placement='right'
        onClose={onClose3}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Text fontSize='md' fontWeight={500} my={5}>Edit Dataset</Text>
            <FormControl>
              <FormLabel>Name *</FormLabel>
              <Input
                placeholder={errorMessage.datasets_name ? errorMessage.datasets_name : 'Enter name'}
                variant='search'
                name="datasets_name"
                value={datasetFormData?.datasets_name}
                onChange={(e) => setDatasetFormdata({ ...datasetFormData, datasets_name: e.target.value })}
                isInvalid={!!errorMessage.datasets_name}
                errorBorderColor='red.300'
                color={textColor}
              />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
              <Spacer my={4} />
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder={errorMessage.datasets_description ? errorMessage.datasets_description : 'Enter description'}
                variant='search'
                name="datasets_description"
                value={datasetFormData?.datasets_description}
                onChange={(e) => setDatasetFormdata({ ...datasetFormData, datasets_description: e.target.value })}
                isInvalid={!!errorMessage.datasets_description}
                errorBorderColor='red.300'
                color={textColor}
                size={"sm"}
              />
              <ButtonGroup variant='outline' spacing='6' mt={10}>
                <Button onClick={onClose3}>Cancel</Button>
                <Button colorScheme='blue' onClick={handleCreateDataset} disabled={submitLoading}>Continue</Button>
              </ButtonGroup>
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Fragment>
  );
}
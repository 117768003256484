import { USERLOGIN, USERTOKENUPDATE, LOGOUT, SIGNUPDETAILS } from "../type";


const initialState = {
    userDetail: {},
    userToken: null,
    signup_detail: {},
};

export function authStore(state = initialState, action) {
    switch (action.type) {
        case USERLOGIN:
            return { ...state, userDetail: action.payload };
        case USERTOKENUPDATE:
            return { ...state, userToken: action.payload };
        case SIGNUPDETAILS:
            return { ...state, signup_detail: action.payload };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};


import {
  Box,
  Container,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import { useRouter } from "components/use-hook";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import EditProfile from "./components/editprofile";
import ChangePassword from "./components/changepassword";
import DeleteAccountCard from "./components/deleteaccount";
export default function UserProfile() {
  const router = useRouter();
  return (
    <Container maxW="6xl">
      <Box p={4} mt={{ sm: '12rem' ,md: '8rem'}}>
      <Card borderWidth={1} borderRadius="xl">
          <Tabs variant='enclosed'>
            <TabList>
              <Tab>Profile</Tab>
              <Tab>Change Password</Tab>
              <Tab>Delete Account</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditProfile/>
              </TabPanel>
              <TabPanel>
                <ChangePassword/>
              </TabPanel>
              <TabPanel>
                <DeleteAccountCard/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>
      </Box>
    </Container>
  );
}
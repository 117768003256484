// chakra imports
import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { TbTypography } from "react-icons/tb";

function Notefound() {

  return (
    <Box>
      <Box py={4} px={12}>
        <Text fontWeight="500" fontSize="2xl">
          Note Found
        </Text>
      </Box>
    </Box>
  );
}

export default Notefound;
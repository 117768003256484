import { LOGOUT, SIGNUPDETAILS, USERLOGIN, USERTOKENUPDATE } from "../type";


export const userLogin = (params) => async (dispatch) => (
    dispatch({
        type: USERLOGIN,
        payload: params,
    })
)

export const authTokenUpdate = (params) => async (dispatch) => (
    dispatch({
        type: USERTOKENUPDATE,
        payload: params,
    })
)
export const signUpDetailsUpdate = (params) => async (dispatch) => (
    dispatch({
        type: SIGNUPDETAILS,
        payload: params,
    })
)
export const userLogout = (params) => async (dispatch) => (
    dispatch({
        type: LOGOUT,
        payload: params,
    })
)
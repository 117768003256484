import { AddIcon, ArrowBackIcon, ChevronDownIcon, ChevronRightIcon, SettingsIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { AspectRatio, Box, Icon, Button, ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Flex, FormControl, FormLabel, Image, Input, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useNumberInput, Menu, MenuButton, MenuList, MenuItem, useTab, Spinner, MenuGroup, Stack, Switch, IconButton, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import {
} from '@chakra-ui/react'
import {
  useColorModeValue,
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import { Select } from "@chakra-ui/react";
import { useState } from "react";
import { MdClose, MdPause, MdPlayArrow, MdUpload } from 'react-icons/md';
import Dropzone from "views/admin/profile/components/Dropzone";
import HistorySpeech from './components/history';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'components/use-hook';
import { apiCall } from 'utils/httpClient';
import { isEmpty } from 'utils/utils';
import apiEndPoint from 'utils/apiEndPoint';
import { position } from 'stylis';
import SelectSpeechVoice from './components/selectvoice';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import { BsBack, BsPlayCircleFill } from 'react-icons/bs';
import { FaBackward, FaDownload, FaForward, FaMicrophone, FaPause, FaPlay, FaTimes } from 'react-icons/fa';
import { failedMessage } from 'utils/httpClient';
import { successMessage } from 'utils/httpClient';
import { useDropzone } from 'react-dropzone';
import { truncateText } from 'utils/utils';
const items = [
  'Voice 1', 'Voice 2', 'Voice 3', 'Voice 4', 'Voice 5', 'Voice 6'
];

// Define the list of languages
const languages = [
  { code: 'bg', name: 'Bulgarian' },
  { code: 'ae', name: 'Classic Arabic' },
  { code: 'cn', name: 'Chinese' },
  { code: 'hr', name: 'Croatian' },
  { code: 'cz', name: 'Czech' },
  { code: 'dk', name: 'Danish' },
  { code: 'nl', name: 'Dutch' },
  { code: 'gb', name: 'English' },
  { code: 'ph', name: 'Filipino' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'gr', name: 'Greek' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'in', name: 'Indian' },
  { code: 'id', name: 'Indonesian' },
  { code: 'it', name: 'Italian' },
  { code: 'jp', name: 'Japanese' },
  { code: 'kr', name: 'Korean' },
  { code: 'my', name: 'Malay' },
  { code: 'no', name: 'Norwegian' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ru', name: 'Russian' },
  { code: 'sk', name: 'Slovak' },
  { code: 'se', name: 'Swedish' },
  { code: 'es', name: 'Spanish' },
  { code: 'in', name: 'Tamil' },
  { code: 'tr', name: 'Turkish' },
  { code: 'ua', name: 'Ukrainian' },
  { code: 'vn', name: 'Vietnamese' },
];
export default function SpeechTrans(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const buttonRef = useRef(null);
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  let menuBg = useColorModeValue("white", "navy.900");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen1, onOpen1, onClose1 } = useDisclosure()
  const { isOpen2, onOpen2, onClose2 } = useDisclosure()
  const btnRef = React.useRef()
  const [selectedName, setSelectedName] = useState('Select an option');
  const { getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 2,
      defaultValue: 1,
      min: 1,
      max: 6,
      precision: 2,
    })
  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const { userToken } = useSelector(state => state.login)
  const router = useRouter();
  const reduxDispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState("Select a Voice");
  const [selectedVoiceId, setSelectdVoiceId] = useState('');
  const { isOpen3, onOpen3, onClose3 } = useDisclosure();
  const [selectModal, setSelectModal] = useState('aipajti_multilingual');
  const [formDetail, setFormDetail] = useState(null);
  const [voiceText, setVoiceText] = useState('hello');
  const [allVoices, setAllVoices] = useState({});
  const [allVoicesLoading, setAllVoicesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [playingVoice, setPlayingVoice] = useState(null);
  const [voiceHistory, setVoiceHistory] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false)
  const [isTabActive, setIsTabActive] = useState('tts')
  const [activeModal, setActiveModal] = useState('aipajti_multilingual');
  const [selectedLanguage, setSelectedLanguage] = useState(null); // Start with no language selected

  const bgButton = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const handleModal = (modalData) => {
    setSelectModal(modalData);
    setActiveModal(modalData);
    // getAllVoices();
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleVoiceSelect = (voice) => {
    setSelectedItem(voice?.voice_name); // Update selected voice state
    setSelectdVoiceId(voice?.value ? voice?.value : voice?.generated_voice_id)
  };

  const filteredVoices = {
    Default: allVoices?.external_voices?.filter((voice) =>
      voice.voice_name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    Generated: allVoices?.user_voices?.filter((voice) =>
      voice.voice_name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    Cloned: allVoices?.cloned_voices?.filter((voice) =>
      voice.voice_name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
  };

  const getAllVoices = async () => {
    const params = { model: selectModal };
    const header = { "Authorization": `${userToken}` }
    setAllVoicesLoading(true);
    const { data } = await apiCall('post', apiEndPoint.GETVOICE, params, header);
    if (data.status !== 200) {
      setAllVoices(null);
    } else {
      // const allData = [...data?.external_voices, ...data?.cloned_voices, ...data?.user_voices]
      setAllVoices(data);
    }
    setAllVoicesLoading(false);
  };

  useEffect(() => {
    getAllVoices();
  }, [selectModal])

  const getHistoryList = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    const { data } = await apiCall('get', apiEndPoint.GENERATEDSPEECH, params, header);
    if (data.status !== 200) {
      setVoiceHistory([]);
    } else {
      // setVoiceHistory(data.voices);
      const allData = [...data?.stt_voices, ...data?.tts_voices]
      setVoiceHistory(allData)
    }
  }
  useEffect(() => {
    getHistoryList()
  }, [])

  // Load saved settings from local storage on component mount
  useEffect(() => {
    const savedFormDetails = localStorage.getItem('formDetails');
    if (savedFormDetails) {
      const parseddata = JSON.parse(savedFormDetails)
      setFormDetail(parseddata);
      setSelectModal(!isEmpty(parseddata?.model) ? parseddata?.model : 'aipajti_multilingual');
      setActiveModal(!isEmpty(parseddata?.model) ? parseddata?.model : 'aipajti_multilingual');
    }
  }, []);

  const validation = () => {
    let isError = true;

    if ((isTabActive === 'sts') && (isEmpty(selectedVoiceId) || isEmpty(selectModal) || !audioFile || isEmpty(audioFile))) {
      isError = false;
      failedMessage("Please Fill all fileds")
    }
    if ((isTabActive === 'tts') && (isEmpty(selectedVoiceId) || isEmpty(selectModal) || !formDetail?.text || isEmpty(formDetail?.text))) {
      isError = false;
      failedMessage("Please Fill all fileds")
    }
    return isError;
  }
  const submitForm = async () => {

    // if (formDetail?.voice_id != undefined && formDetail?.voice_id != '' && formDetail?.text != undefined && formDetail?.text != '') {
    if (validation()) {
      const header = { "Authorization": `${userToken}` }
      if (isTabActive === 'sts') {
        var form = new FormData();
        form.append("voice_id", selectedVoiceId);
        form.append("model", activeModal);
        form.append("stability", formDetail?.stability ? formDetail?.stability : 0);
        form.append("similarilty", formDetail?.similarilty ? formDetail?.similarilty : 0);
        form.append("style_exaggeration", formDetail?.style_exaggeration ? formDetail?.style_exaggeration : 0);
        form.append("speaker_boost", formDetail?.speaker_boost ? formDetail?.speaker_boost : false);
        if (activeModal === 'vocal_vista_turbo') {
          form.append("language", selectedLanguage?.code);
        }
        if (!isEmpty(audioFile)) {
          form.append("audio_file_path", audioFile?.file);
        }

      } else {
        var params = {
          voice_id: selectedVoiceId,
          model: activeModal,
          text: formDetail?.text,
          stability: formDetail?.stability ? formDetail?.stability : 0,
          similarilty: formDetail?.similarilty ? formDetail?.similarilty : 0,
          style_exaggeration: formDetail?.style_exaggeration ? formDetail?.style_exaggeration : 0,
          speaker_boost: formDetail?.speaker_boost ? formDetail?.speaker_boost : false
        }
        if (activeModal === 'vocal_vista_turbo') {
          params.language = selectedLanguage?.code;
        }
        if (params.model === 'sonic_sage' || params.model === 'coqui') {
          delete params.similarilty;
          delete params.stability;
          delete params.style_exaggeration;
          delete params.speaker_boost;
        }
      }
      setIsLoading(true)
      if (isTabActive === 'sts') {
        var { data } = await apiCall('post', apiEndPoint.SPEECHTOSPEECHGENERATION, form, header);
      } else {
        var { data } = await apiCall('post', apiEndPoint.SPEECHTOTEXTGENERATION, params, header);
      }
      if (data.status !== 200) {
      
        failedMessage(data?.message)
      } else {
        successMessage(data?.message);
        setPlayingVoice(data);
        togglePlayPause(data);
        localStorage.setItem('formDetails', JSON.stringify(data));
        setSelectdVoiceId(null)
        setSelectedItem('Select a Voice');
        setSelectedLanguage(null);
        onClose();
        getHistoryList();
        // if (buttonRef.current) {
        //   buttonRef.current.click();
        // }
      }
      setIsLoading(false)
    }
  }
  // }

  //voice play & playbar code 

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef(new Audio());

  useEffect(() => {
    const audio = audioRef.current;
    if (playingVoice) {
      audio.src = playingVoice?.preview_url;
      audio.play();
      setIsPlaying(true);

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', () => {
        setDuration(audio.duration);
      });

      return () => {
        audio.pause();
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [playingVoice]);

  const togglePlayPause = (voice) => {
    if (playingVoice?.generated_voice_id === voice?.generated_voice_id) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      setPlayingVoice(voice);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (value) => {
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const handleRewind = () => {
    audioRef.current.currentTime = Math.max(0, currentTime - 5);
  };

  const handleForward = () => {
    audioRef.current.currentTime = Math.min(duration, currentTime + 5);
  };

  const handleStop = () => {
    audioRef.current.pause();
    setIsPlaying(false);
    setPlayingVoice(null);
    setCurrentTime(0);
  };
  /*End-------------------- here*/


  // Speech to speech upload/ drag and drops and Record audio code 

  const [audioFile, setAudioFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const newAudioRef = useRef(null);
  const [isRecordedPlaying, setIsRecordedPlaying] = useState(false); // State for Play/Pause
  const defaultLanguage = languages[0];
  const flagBaseURL = 'https://flagcdn.com/w20/'; // URL for flag images

  // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (!isRecording && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setAudioFile({
          name: file.name,
          size: (file.size / 1024 / 1024).toFixed(2) + ' MB',
          // file: URL.createObjectURL(file), // Create a URL for the selected file
          file: file, // Create a URL for the selected file

        });
      }
    },
    maxSize: 50 * 1024 * 1024, // 50MB limit
    accept: 'audio/*',
    noClick: true, // Prevents dropzone area from triggering file selection on click
  });

  // Handle upload button click to open file selector
  const handleUploadClick = () => {
    open(); // Triggers the file selection dialog
  };

  // Recording logic
  const handleRecordStart = () => {
    setIsRecording(true);
    setAudioFile(null); // Clear any selected audio file
    setRecordingDuration(0);

    const interval = setInterval(() => {
      setRecordingDuration((prev) => prev + 1);
    }, 1000); // Simulate recording time

    // Simulate stop recording after 5 seconds
    setTimeout(() => {
      clearInterval(interval);
      // const recordedAudioBlob = new Blob(['Simulated Audio Content'], { type: 'audio/mp3' });
      const recordedAudio = {
        name: 'Recorded Audio',
        size: calculateSize(recordingDuration), // Dynamic size calculation
        // file: URL.createObjectURL(recordedAudioBlob), // Create an Object URL for the simulated audio Blob
        file: {}
      };
      setAudioFile(recordedAudio);
      setIsRecording(false);
    }, 5000); // Adjust to your recording duration
  };

  const calculateSize = (duration) => {
    // Simulate file size: 1 second = 0.3 MB
    return (duration * 0.3).toFixed(2) + ' MB';
  };

  const handleRemoveAudio = () => {
    setAudioFile(null);
    setIsRecordedPlaying(false);
  };

  // Play/Pause functionality
  const handlePlayPause = () => {
    if (isRecordedPlaying) {
      newAudioRef.current.pause();
    } else {
      newAudioRef.current.play();
    }
    setIsRecordedPlaying(!isRecordedPlaying);
  };

  return (
    <Box>
      <Box m={6} pt={{ base: "200px", md: "80px", xl: "100px" }}>
        <Box mb='2rem'>
          <Box my='1'>
            <Text fontSize="2xl" fontWeight="600">
              Speech Synthesis
            </Text>
          </Box>
          <Box>
            <Text fontSize="md" fontWeight="400">
              Generate your ideas into stunning visuals
            </Text>
          </Box>
        </Box >
        <Tabs>
          <TabList>
            <Tab fontSize={14} onClick={handleStop}>GENERATE</Tab>
            <Tab ref={buttonRef} fontSize={14} onClick={handleStop} >HISTORY</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box mt={10} position={'relative'}>
                <Card w={{ base: "100%", md: "75%", xl: "60%" }} m={'auto'}
                  direction={{ base: 'column', sm: 'row' }}
                  overflow='hidden'
                  variant='outline'
                >
                  <Box>
                    {/* <Box position={'absolute'} right={5}>
                      <Text fontSize="sm" fontWeight="400" >
                        Quota remaining: 10,000
                      </Text>
                    </Box> */}
                    <Tabs>
                      <TabList>
                        <Tab fontSize={14} onClick={() => { setIsTabActive('tts'); handleStop(); }} >TEXT TO SPEECH</Tab>
                        {selectModal === 'aipajti_multilingual' || selectModal === 'vocal_vista_turbo' || selectModal === '' ?
                          <Tab fontSize={14} onClick={() => { setIsTabActive('sts'); handleStop(); }}>SPEECH TO SPEECH</Tab> : ''}
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Box my={6}>
                            <Textarea placeholder='Start typing here or paste any text you want to turn into lifelike speech.' rows={4} value={formDetail?.text} onChange={(e) => setFormDetail({ ...formDetail, text: e.target.value })} />
                          </Box>
                        </TabPanel>

                        <TabPanel>
                          <Box textAlign={'center'}>
                            {/* <Dropzone
                                w={{ base: "100%", "2xl": "100%" }}
                                maxH={{ base: "200px", lg: "200px", "2xl": "250px" }}
                                minH={{ base: "200px", lg: "200px", "2xl": "250px" }}
                                content={
                                  <Box>
                                    <Icon as={MdUpload} w="40px" h="40px" color={brandColor} />
                                    <Flex justify="center" mx="auto" mb="12px">
                                    </Flex>
                                    <Box alignItems={'center'} justifyContent={'center'}>
                                      <Text fontSize="sm" fontWeight="400" >
                                        Drag and drop an audio file
                                      </Text>
                                      <Text mt={2} fontSize="xs" fontWeight="400" >
                                        Audio file up to 50mb
                                      </Text>
                                    </Box>
                                    <Box mt={4}>
                                      <Button borderRadius={50} onClick={onOpen1} variant='outline'>
                                        <Text fontSize="sm" fontWeight="400" >
                                          Upload audio
                                        </Text>
                                      </Button>
                                      <Button borderRadius={50} ml={4} onClick={onOpen2} variant='outline'>
                                        <Text fontSize="sm" fontWeight="400" >
                                          Record audio
                                        </Text>
                                      </Button>
                                    </Box>
                                  </Box>
                                }
                              /> */}
                            {!audioFile ? (
                              <Box
                                {...getRootProps()}
                                w={{ base: '100%', '2xl': '100%' }}
                                maxH={{ base: '200px', lg: '200px', '2xl': '250px' }}
                                minH={{ base: '200px', lg: '200px', '2xl': '250px' }}
                                border="2px dashed"
                                borderColor={isDragActive ? 'blue.300' : 'gray.300'}
                                borderRadius="md"
                                p={4}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              // bg="gray.800"
                              >
                                <input {...getInputProps()} />
                                <Box textAlign="center">
                                  <Icon as={MdUpload} w="40px" h="40px" color="gray.500" />
                                  <Flex justify="center" mx="auto" mb="12px"></Flex>
                                  <Box alignItems={'center'} justifyContent={'center'}>
                                    <Text fontSize="sm" fontWeight="400">
                                      {isDragActive ? "Drop the audio file here..." : "Drag and drop an audio file"}
                                    </Text>
                                    <Text mt={2} fontSize="xs" fontWeight="400">
                                      Audio file up to 50mb
                                    </Text>
                                  </Box>
                                  <Box mt={4}>
                                    <Button borderRadius={50} variant="outline" onClick={handleUploadClick}>
                                      <Text fontSize="sm" fontWeight="400">
                                        Upload audio
                                      </Text>
                                    </Button>
                                    {/* <Button
                                      borderRadius={50}
                                      ml={4}
                                      onClick={handleRecordStart}
                                      variant="outline"
                                      isLoading={isRecording}
                                    >
                                      <Text fontSize="sm" fontWeight="400">
                                        {isRecording ? 'Recording...' : 'Record audio'}
                                      </Text>
                                    </Button> */}
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                w={{ base: '100%', '2xl': '100%' }}
                                maxH={{ base: '200px', lg: '200px', '2xl': '250px' }}
                                minH={{ base: '200px', lg: '200px', '2xl': '250px' }}
                                border="2px dashed"
                                // borderColor="gray.300"
                                borderRadius="md"
                                p={4}
                                position="relative"
                              // bg="gray.800"
                              >
                                <Icon
                                  as={MdClose}
                                  w="24px"
                                  h="24px"
                                  color="gray.500"
                                  position="absolute"
                                  top="8px"
                                  right="8px"
                                  cursor="pointer"
                                  onClick={handleRemoveAudio}
                                />
                                <Icon as={audioFile.name === 'Recorded Audio' ? FaMicrophone : MdUpload} w="40px" h="40px" color="gray.500" />
                                <Box mt={2}>
                                  <Text fontSize="sm" fontWeight="400">
                                    {audioFile.name}
                                  </Text>
                                  <Text fontSize="xs" fontWeight="400" mt={1}>
                                    {audioFile.size}
                                  </Text>
                                  {/* Play/Pause button */}
                                  {/* <Button
                                    onClick={handlePlayPause}
                                    leftIcon={<Icon as={isRecordedPlaying ? MdPause : MdPlayArrow} />}
                                    variant="outline"
                                    mt={2}
                                  >
                                    {isRecordedPlaying ? 'Pause' : 'Play'}
                                  </Button> */}
                                  {/* Hidden audio element */}
                                  <audio ref={newAudioRef} src={audioFile.file} onEnded={() => setIsRecordedPlaying(false)} />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </TabPanel>

                      </TabPanels>
                    </Tabs>
                    <Box textAlign={'end'} my={6}>
                      <Flex alignItems={{ sm: 'left', md: 'center'}} justifyContent={'space-between'} direction={{ sm: 'column', md: 'row'}} gap={2}>
                        <Box mr={4}>
                          <Flex>
                            {activeModal === 'vocal_vista_turbo' ?
                              <Box mr={4}>
                                <Menu >
                                  <MenuButton w={170} as={Button} rightIcon={<ChevronDownIcon />} fontSize={14} fontWeight={400}>
                                    <Box display="flex" alignItems="center">
                                      {selectedLanguage ? (
                                        <>
                                          <Image
                                            src={`${flagBaseURL}${selectedLanguage.code}.png`} // Selected language flag
                                            alt={selectedLanguage.name}
                                            boxSize="24px"
                                            borderRadius="full"
                                            mr={2}
                                          />
                                          {selectedLanguage.name} {/* Selected language name */}
                                        </>
                                      ) : (
                                        'Language' // Default text when no language is selected
                                      )}
                                    </Box>
                                  </MenuButton>
                                  <MenuList height={200} overflowY={'auto'} width={"150px"}>
                                    {languages.map((lang) => (
                                      <MenuItem key={lang.code}
                                        onClick={() => setSelectedLanguage(lang)}>
                                        <Image
                                          src={`${flagBaseURL}${lang.code}.png`} // Flag for each language
                                          alt={lang.name}
                                          boxSize="24px"
                                          borderRadius="full"
                                          mr={2}
                                        />
                                        {lang.name}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </Menu>
                              </Box> : ''}
                            <Box>
                              <Menu>
                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} fontSize={14} fontWeight={400} w={170}>
                                  {selectedItem}
                                </MenuButton>
                                <MenuList maxHeight="250px" overflow="auto" width={"200px"} >
                                  <Box p={2}>
                                    <Input
                                      placeholder="Search for a voice..."
                                      value={searchTerm}
                                      onChange={handleSearchChange}
                                      color={textColor}
                                    />
                                  </Box>
                                  {!isEmpty(filteredVoices.Generated) &&
                                    (<MenuGroup title="Generated" textAlign={'start'}>
                                      {filteredVoices?.Generated?.map((voice) => (
                                        <MenuItem key={voice.generated_voice_id} onClick={() => handleVoiceSelect(voice)}>
                                          <IconButton
                                            icon={playingVoice?.generated_voice_id === voice.generated_voice_id && isPlaying ? <FaPause /> : <FaPlay />}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePlayPause(voice);
                                            }}
                                            size="sm"
                                            mr={2}
                                            aria-label={playingVoice === voice.generated_voice_id ? "Pause" : "Play"}
                                          />
                                          {truncateText(voice.voice_name, 20)}
                                        </MenuItem>
                                      ))}
                                    </MenuGroup>)}
                                  {!isEmpty(filteredVoices.Cloned) &&
                                    (<MenuGroup title="Cloned" textAlign={'start'}>
                                      {filteredVoices?.Cloned?.map((voice) => (
                                        <MenuItem key={voice.generated_voice_id} onClick={() => handleVoiceSelect(voice)}>
                                          <IconButton
                                            icon={playingVoice?.generated_voice_id === voice.generated_voice_id && isPlaying ? <FaPause /> : <FaPlay />}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePlayPause(voice);
                                            }}
                                            size="sm"
                                            mr={2}
                                            aria-label={playingVoice === voice.generated_voice_id ? "Pause" : "Play"}
                                          />
                                          {truncateText(voice.voice_name, 20)}
                                        </MenuItem>
                                      ))}
                                    </MenuGroup>)}
                                  {!isEmpty(filteredVoices.Default) &&
                                    (<MenuGroup title="Default" textAlign={'start'}>
                                      {filteredVoices?.Default?.map((voice) => (
                                        <MenuItem key={voice.generated_voice_id} onClick={() => handleVoiceSelect(voice)}>
                                          <IconButton
                                            icon={playingVoice?.generated_voice_id === voice.generated_voice_id && isPlaying ? <FaPause /> : <FaPlay />}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePlayPause(voice);
                                            }}
                                            size="sm"
                                            mr={2}
                                            aria-label={playingVoice === voice.generated_voice_id ? "Pause" : "Play"}
                                          />
                                          {truncateText(voice.voice_name, 20)}
                                        </MenuItem>
                                      ))}
                                    </MenuGroup>)}
                                </MenuList>
                              </Menu>

                            </Box>
                            <Button variant='outline' fontWeight={400} fontSize={14} borderRadius={50} ml={5} ref={btnRef} onClick={onOpen}>
                              Settings
                            </Button>
                            <Drawer
                              isOpen={isOpen}
                              placement='right'
                              onClose={onClose}
                              size='sm'
                              finalFocusRef={btnRef}
                            >
                              <DrawerOverlay />
                              <DrawerContent>
                                <Box m={5}>
                                  <Flex w="100%" alignItems={'center'}>
                                    <SettingsIcon />
                                    <Text ml={3}
                                      fontSize="md"
                                      fontWeight="500"
                                      lineHeight='20px'
                                    >
                                      Settings
                                    </Text>
                                  </Flex>
                                </Box>
                                <DrawerCloseButton />
                                <DrawerBody>
                                  {selectModal === 'aipajti_multilingual' || selectModal === 'vocal_vista_turbo' ?
                                    <Box p={4}>
                                      <Box display={'flex'} alignItems={'center'} mb={6}>
                                        <Button onClick={() => setSelectModal('')}>
                                          <ArrowBackIcon />
                                        </Button>
                                        <Text fontSize='md' fontWeight={500} ml={4}>
                                          {selectModal === 'aipajti_multilingual' ?
                                            "AI Pajti Multilingual Modal" :
                                            selectModal === 'vocal_vista_turbo' ?
                                              "AI Pajti Turbo Modal" : ''
                                          }
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Text fontSize='md' fontWeight={500} mb={4}>Stability</Text>
                                        <Flex direction='row' alignItems={'baseline'} justifyContent={'space-between'}>
                                          <Text fontSize='sm' fontWeight={500} mb={4}>More variable</Text>
                                          <Text fontSize='xs' fontWeight={500}>More stable</Text>
                                        </Flex>
                                        <Slider
                                          aria-label='slider-ex-1'
                                          // defaultValue={0}
                                          value={formDetail?.stability ? formDetail?.stability : 0}
                                          onChange={(e) => { setFormDetail({ ...formDetail, stability: e }) }}
                                          min={0}
                                          max={1}
                                          step={0.1}
                                          colorScheme={"teal"}
                                          onMouseEnter={() => setShowTooltip(true)}
                                          onMouseLeave={() => setShowTooltip(false)}
                                        >
                                          <SliderTrack>
                                            <SliderFilledTrack />
                                          </SliderTrack>
                                          <Tooltip
                                            hasArrow
                                            bg={"teal.500"}
                                            color='white'
                                            placement='top'
                                            isOpen={showTooltip}
                                            label={`${formDetail?.stability ? formDetail?.stability : '0'}`}
                                          >
                                            <SliderThumb />
                                          </Tooltip>
                                        </Slider>
                                      </Box>
                                      <Box my={6}>
                                        <Text fontSize='md' fontWeight={500} mb={4}>Similarity</Text>
                                        <Flex direction='row' alignItems={'baseline'} justifyContent={'space-between'}>
                                          <Text fontSize='sm' fontWeight={500} mb={4}>Low</Text>
                                          <Text fontSize='xs' fontWeight={500}>High</Text>
                                        </Flex>

                                        <Slider
                                          aria-label='slider-ex-1'
                                          // defaultValue={0}
                                          value={formDetail?.similarilty ? formDetail?.similarilty : 0}
                                          onChange={(e) => { setFormDetail({ ...formDetail, similarilty: e }) }}
                                          min={0}
                                          max={1}
                                          step={0.1}
                                          colorScheme={"teal"}
                                          onMouseEnter={() => setShowTooltip(true)}
                                          onMouseLeave={() => setShowTooltip(false)}
                                        >
                                          <SliderTrack>
                                            <SliderFilledTrack />
                                          </SliderTrack>
                                          <Tooltip
                                            hasArrow
                                            bg={"teal.500"}
                                            color='white'
                                            placement='top'
                                            isOpen={showTooltip}
                                            label={`${formDetail?.similarilty ? formDetail?.similarilty : '0'}`}
                                          >
                                            <SliderThumb />
                                          </Tooltip>
                                        </Slider>
                                      </Box>
                                      <Box my={6}>
                                        <Text fontSize='md' fontWeight={500} mb={4}>Style Exaggeration</Text>
                                        <Flex direction='row' alignItems={'baseline'} justifyContent={'space-between'}>
                                          <Text fontSize='sm' fontWeight={500} mb={4}>None</Text>
                                          <Text fontSize='xs' fontWeight={500}>Exaggerated</Text>
                                        </Flex>
                                        <Slider
                                          aria-label='slider-ex-1'
                                          // defaultValue={0}
                                          value={formDetail?.style_exaggeration ? formDetail?.style_exaggeration : 0}
                                          onChange={(e) => { setFormDetail({ ...formDetail, style_exaggeration: e }) }}
                                          min={0}
                                          max={1}
                                          step={0.1}
                                          colorScheme={"teal"}
                                          onMouseEnter={() => setShowTooltip(true)}
                                          onMouseLeave={() => setShowTooltip(false)}
                                        >
                                          <SliderTrack>
                                            <SliderFilledTrack />
                                          </SliderTrack>
                                          <Tooltip
                                            hasArrow
                                            bg={"teal.500"}
                                            color='white'
                                            placement='top'
                                            isOpen={showTooltip}
                                            label={`${formDetail?.style_exaggeration ? formDetail?.style_exaggeration : '0'}`}
                                          >
                                            <SliderThumb />
                                          </Tooltip>
                                        </Slider>
                                      </Box>
                                      <Box mt={6}>
                                        <FormControl display='flex' alignItems='center'>
                                          <FormLabel htmlFor='email-alerts' mb='0'>
                                            Speaker boost
                                          </FormLabel>
                                          <Switch id='email-alerts' onChange={(e) => setFormDetail({ ...formDetail, speaker_boost: e.target.checked })} isChecked={formDetail?.speaker_boost ? true : false} />
                                        </FormControl>
                                      </Box>
                                    </Box> :
                                    <>
                                      <Box mt={5} >
                                        <Text fontSize='sm' fontWeight={500} my={2}>Multilingual Model</Text>
                                        <Card borderRadius="xl"
                                          onClick={() => handleModal('aipajti_multilingual')}
                                          bg={activeModal === 'aipajti_multilingual' ? bgButton : 'navy.800'}
                                          _hover={activeModal === 'aipajti_multilingual' ? bgHover : "unset"}
                                          _focus={activeModal === 'aipajti_multilingual' ? bgFocus : "unset"}
                                          _active={activeModal === 'aipajti_multilingual' ? bgFocus : "unset"}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <Box>
                                            <Text fontSize='sm' fontWeight={500} my={2}>AIPajti multilingual model</Text>
                                            <Flex alignItems={'center'}>
                                              <Text fontSize='xs' fontWeight={400} my={2} opacity={.5}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Text>
                                            </Flex>
                                            <Flex>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                English
                                              </Button>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                Japanese
                                              </Button>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                Chinese
                                              </Button>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                +26 more
                                              </Button>
                                            </Flex>
                                          </Box>
                                        </Card>
                                      </Box>
                                      <Box mt={5}>
                                        <Text fontSize='sm' fontWeight={500} my={2}>Turbo Model</Text>
                                        <Card borderRadius="xl"
                                          onClick={() => handleModal('vocal_vista_turbo')}
                                          bg={activeModal === 'vocal_vista_turbo' ? bgButton : 'navy.800'}
                                          _hover={activeModal === 'vocal_vista_turbo' ? bgHover : 'unset'}
                                          _focus={activeModal === 'vocal_vista_turbo' ? bgFocus : 'unset'}
                                          _active={activeModal === 'vocal_vista_turbo' ? bgFocus : 'unset'}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <Box>
                                            <Text fontSize='sm' fontWeight={500} my={2}>AIPajti 2.5 Turbo</Text>
                                            <Flex alignItems={'center'}>
                                              <Text fontSize='xs' fontWeight={400} my={2} opacity={.5}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Text>
                                              <Popover>
                                                <PopoverContent>
                                                  <PopoverArrow />
                                                  <PopoverCloseButton />
                                                  <PopoverHeader sx={{ p: '10px !important' }}>Turbo Model Features</PopoverHeader>
                                                  <PopoverBody p={4}>
                                                    <Box>
                                                      <Text fontSize='md' fontWeight={500} mb={4}>Stability</Text>
                                                      <Flex direction='row' alignItems={'baseline'} justifyContent={'space-between'}>
                                                        <Text fontSize='sm' fontWeight={500} mb={4}>More variable</Text>
                                                        <Text fontSize='xs' fontWeight={500}>More stable</Text>
                                                      </Flex>
                                                      <Slider aria-label='slider-ex-1' defaultValue={30}>
                                                        <SliderTrack>
                                                          <SliderFilledTrack />
                                                        </SliderTrack>
                                                        <SliderThumb />
                                                      </Slider>
                                                    </Box>
                                                    <Box my={6}>
                                                      <Text fontSize='md' fontWeight={500} mb={4}>Similarity</Text>
                                                      <Flex direction='row' alignItems={'baseline'} justifyContent={'space-between'}>
                                                        <Text fontSize='sm' fontWeight={500} mb={4}>Low</Text>
                                                        <Text fontSize='xs' fontWeight={500}>High</Text>
                                                      </Flex>
                                                      <Slider aria-label='slider-ex-1' defaultValue={30}>
                                                        <SliderTrack>
                                                          <SliderFilledTrack />
                                                        </SliderTrack>
                                                        <SliderThumb />
                                                      </Slider>                                  </Box>
                                                    <Box my={6}>
                                                      <Text fontSize='md' fontWeight={500} mb={4}>Style Exaggeration</Text>
                                                      <Flex direction='row' alignItems={'baseline'} justifyContent={'space-between'}>
                                                        <Text fontSize='sm' fontWeight={500} mb={4}>None</Text>
                                                        <Text fontSize='xs' fontWeight={500}>Exaggerated</Text>
                                                      </Flex>
                                                      <Slider aria-label='slider-ex-1' defaultValue={30}>
                                                        <SliderTrack>
                                                          <SliderFilledTrack />
                                                        </SliderTrack>
                                                        <SliderThumb />
                                                      </Slider>                                  </Box>
                                                    <Box mt={6}>
                                                      <FormControl display='flex' alignItems='center'>
                                                        <FormLabel htmlFor='email-alerts' mb='0'>
                                                          Speaker boost
                                                        </FormLabel>
                                                        <Switch id='email-alerts' />
                                                      </FormControl>
                                                    </Box>
                                                  </PopoverBody>
                                                </PopoverContent>
                                              </Popover>
                                            </Flex>
                                            <Flex>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                English
                                              </Button>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                Japanese
                                              </Button>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                Chinese
                                              </Button>
                                              <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                +26 more
                                              </Button>
                                            </Flex>
                                          </Box>
                                        </Card>
                                      </Box>
                                      {isTabActive === 'tts' &&
                                        <>
                                          <Box mt={5}
                                          >
                                            <Text fontSize='sm' fontWeight={500} my={2}>Coqui Model</Text>
                                            <Card borderRadius="xl"
                                              onClick={() => handleModal('coqui')}
                                              bg={activeModal === 'coqui' ? bgButton : 'navy.800'}
                                              _hover={activeModal === 'coqui' ? bgHover : 'unset'}
                                              _focus={activeModal === 'coqui' ? bgFocus : 'unset'}
                                              _active={activeModal === 'coqui' ? bgFocus : 'unset'}
                                              sx={{ cursor: "pointer" }}
                                            >
                                              <Box>
                                                <Text fontSize='sm' fontWeight={500} my={2}>AIPajti Coqui Model</Text>
                                                <Flex alignItems={'center'}>
                                                  <Text fontSize='xs' fontWeight={400} my={2} opacity={.5}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Text>
                                                </Flex>
                                                <Flex>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    English
                                                  </Button>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    Japanese
                                                  </Button>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    Chinese
                                                  </Button>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    +26 more
                                                  </Button>
                                                </Flex>
                                              </Box>
                                            </Card>
                                          </Box>
                                          <Box mt={5}
                                          >
                                            <Text fontSize='sm' fontWeight={500} my={2}>AI Open Model</Text>
                                            <Card borderRadius="xl"
                                              onClick={() => handleModal('sonic_sage')}
                                              bg={activeModal === 'sonic_sage' ? bgButton : 'navy.800'}
                                              _hover={activeModal === 'sonic_sage' ? bgHover : 'unset'}
                                              _focus={activeModal === 'sonic_sage' ? bgFocus : 'unset'}
                                              _active={activeModal === 'sonic_sage' ? bgFocus : 'unset'}
                                              sx={{ cursor: "pointer" }}
                                            >
                                              <Box>
                                                <Text fontSize='sm' fontWeight={500} my={2}>AI Open Model</Text>
                                                <Flex alignItems={'center'}>
                                                  <Text fontSize='xs' fontWeight={400} my={2} opacity={.5}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Text>
                                                </Flex>
                                                <Flex>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    English
                                                  </Button>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    Japanese
                                                  </Button>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    Chinese
                                                  </Button>
                                                  <Button size='xs' fontWeight={400} fontSize={12} borderRadius={50} ml={2}>
                                                    +26 more
                                                  </Button>
                                                </Flex>
                                              </Box>
                                            </Card>
                                          </Box>
                                        </>
                                      }
                                    </>
                                  }
                                </DrawerBody>
                                <DrawerFooter>
                                  <Button variant='outline' fontWeight={400} fontSize={14} borderRadius={50} ml={2}>
                                    Reset                            </Button>
                                  <Button onClick={submitForm} fontWeight={400} fontSize={14} borderRadius={50} ml={2}>
                                    {isLoading ? <Spinner size={"md"} /> : 'Generate Speech'}
                                  </Button>
                                </DrawerFooter>
                              </DrawerContent>
                            </Drawer>
                          </Flex>
                        </Box>
                        <Box>
                          <Flex alignItems={'center'}>
                            {/* <Text fontWeight={400} fontSize={12} opacity={.6}>0 / 5000</Text> */}
                            <Button onClick={submitForm} fontWeight={400} fontSize={14} borderRadius={50} ml={2}> {isLoading ? <Spinner size={"md"} /> : 'Generate Speech'}
                            </Button>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </TabPanel>
            <TabPanel>
              <HistorySpeech voiceHistory={voiceHistory} getHistoryList={getHistoryList} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {playingVoice && (
          <Flex
            position="fixed"
            bottom="0"
            bg={menuBg}
            boxShadow="lg"
            p={4}
            zIndex="1000"
            w={{
              base: "100%",
              xl: "calc(100vw - 305px)",
              "2xl": "calc(100vw - 305px)",
            }}
            right={{ base: "0", md: "0", lg: "0", xl: "0" }}
          >
            <IconButton
              icon={<FaBackward />}
              onClick={handleRewind}
              aria-label="Rewind 5 seconds"
              mr={2}
              size="sm"
            />
            <IconButton
              icon={isPlaying ? <FaPause /> : <FaPlay />}
              onClick={() => togglePlayPause(playingVoice)}
              aria-label={isPlaying ? 'Pause' : 'Play'}
              mr={2}
              size="sm"
            />
            <IconButton
              icon={<FaForward />}
              onClick={handleForward}
              aria-label="Forward 5 seconds"
              mr={2}
              size="sm"
            />
            <Slider
              aria-label="Voice Progress"
              value={currentTime}
              min={0}
              max={duration}
              onChange={handleSeek}
              flex="1"
              mx={2}
            >
              <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="blue.400" />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text color="white" mr={2}>
              {Math.floor(currentTime / 60)}:{Math.floor(currentTime % 60).toString().padStart(2, '0')}
            </Text>
            <Text color="white" mr={2}>
              / {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}
            </Text>
            <IconButton
              icon={<FaTimes />}
              onClick={handleStop}
              aria-label="Close Playbar"
              size="sm"
            />
          </Flex>
        )}
      </Box>
    </Box>
  )
}
import React, { useState } from 'react';
import { Button, Box, Input, VStack, Text } from '@chakra-ui/react';
import { HiUpload } from 'react-icons/hi';
import { BsFolder2, BsFolder2Open } from 'react-icons/bs';

const AudioUploadPreview = () => {
  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setAudioFile(selectedFile);
      const objectUrl = URL.createObjectURL(selectedFile);
      setAudioUrl(objectUrl);
    }
  };

  return (
    <VStack spacing={4}>
      <Input
        type="file"
        accept="audio/*"
        display="none"
        id="audio-input"
        onChange={handleFileChange}
      />
      <label htmlFor="audio-input">
        <Button
          as="span"
          leftIcon={<BsFolder2Open />}
        >
          My Device
        </Button>
      </label>
      {audioUrl && (
        <Box mt={4} textAlign="center">
          <audio controls>
            <source src={audioUrl} type={audioFile.type} />
            Your browser does not support the audio element.
          </audio>
          <Text mt={2}>{audioFile.name}</Text>
        </Box>
      )}
    </VStack>
  );
};

export default AudioUploadPreview;

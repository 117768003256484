import React from 'react';
import { Box, List, ListItem, Text, Button, VStack, Image, useDisclosure, useColorModeValue, IconButton, MenuItem, MenuButton, Menu, MenuGroup, MenuList, MenuDivider, Avatar } from '@chakra-ui/react';
import { TbDotsVertical } from 'react-icons/tb';
import { ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import { MdAudioFile } from 'react-icons/md';
import { FaVideo } from 'react-icons/fa';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
const AudioList = ({ audioFiles }) => {
  const initialFocusRef = React.useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const songText = useColorModeValue("navy.700", "gray.200");
  const lyricsText = useColorModeValue("navy.700", "gray.400");

  const btnRef = React.useRef()
  return (
    <VStack align="start" spacing={4} p={4}>
      <Text fontSize="lg" fontWeight="bold">
        Uploaded Audio Files
      </Text>
      <List spacing={3} width="full">
        {audioFiles.map((file, index) => (
          <ListItem key={index} display="flex" alignItems="center" p={3} border="1px" borderColor="secondaryGray.800" borderRadius="md">
            <Avatar borderRadius={10} w={{ base: "48px", xl: "36px", "2xl": "48px" }}
              src={'https://images.unsplash.com/photo-1612367289874-0fba3b4a07dd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
            />
            <Box flex="1" ml={4} ref={btnRef} colorScheme='teal' onClick={onOpen}>
              <Text fontSize="md" fontWeight="medium">
                {file.name}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {file.size} bytes
              </Text>
            </Box>
            <Box>
              <Menu>
                <MenuButton  >
                  <TbDotsVertical />
                </MenuButton>
                <MenuList maxW={250}>
                  <MenuItem fontSize={14}>Reuse Prompt</MenuItem>
                  <MenuItem fontSize={14}>Edit Details</MenuItem>
                  <MenuDivider />
                  <Popover placement="left" initialFocusRef={initialFocusRef}
                    closeOnBlur={false}                >
                    <PopoverTrigger>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mx={3}>
                        <Text fontSize={14}>Download</Text>
                        <IconButton size="xs"
                          icon={<ChevronRightIcon />} aria-label={"Play"}
                        />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      {/* <PopoverCloseButton /> */}
                      {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
                      <PopoverBody>
                        <MenuItem fontSize={14} icon={<MdAudioFile />}>Audio</MenuItem>
                        <MenuItem fontSize={14} icon={<FaVideo />}>Video</MenuItem>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <MenuDivider />
                  <MenuItem fontSize={14} icon={<DeleteIcon />}>Move to Trash</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </ListItem>
        ))}
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody p={0}>
              <Image width={'100%'} maxH={250} objectFit={'cover'} src='https://images.unsplash.com/photo-1612367289874-0fba3b4a07dd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' me='auto' />
              <Box flex="1" p={5} ref={btnRef} colorScheme='teal' onClick={onOpen}>
                <Text fontSize="md" fontWeight="medium">
                  Can't Wait to See You
                </Text>
                <Text fontSize="sm" color={songText}>
                  disco groovy
                </Text>
                <Text mt={5} fontSize="sm" color={lyricsText}>
                  August 27, 2024 at 10:31 AM
                </Text>
                <Text mt={5} fontSize="md" color={songText}>
                  [Verse]
                  In the mirror light
                  Spinning all around
                  Heart is feeling bright
                  Feet can't touch the ground <br /> <br />
                  [Verse 2]
                  Music takes me high
                  Colors in the night
                  Stars up in the sky
                  Everything feels right <br /> <br />
                  [Chorus]
                  Can't wait to see you
                  Dancing the night away
                  Can't wait to hold you
                  In every way <br /> <br />
                  [Verse 3]
                  Lights are flashing bold  <br /> <br />
                  Rhythm makes me move   <br /> <br />
                  Gonna break the moldLights are flashing bold  <br /> <br />
                  Rhythm makes me move  <br /> <br />
                  Gonna break the moldLights are flashing bold
                  Rhythm makes me move
                  Gonna break the mold
                </Text>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </List>
    </VStack>
  );
};

export default AudioList;
import React from 'react';
import { Box, Heading, Text, Button, Container, Image, Flex } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import TickAnime from 'assets/img/layout/checkmark.gif';
import { useRouter } from 'components/use-hook';

const VerifiedUser = () => {
  const router = useRouter();
  return (
    <Container maxW="container.md" centerContent>
       <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh" // Full viewport height
    >
      <Box 
        p={5}
        borderWidth={1}
        borderRadius="xl"
        boxShadow="md"
        textAlign="center"
      >
        <Box my={8}>
          {/* <CheckCircleIcon color="forestgreen"  fontSize={50} /> */}
          <Image src={TickAnime} width={'50px'} m={'auto'} />
        </Box>
        <Heading size="lg" my={4}>
          Thank You for Signing Up!
        </Heading>
        <Text fontSize="lg" my={10}>
          Please check your email and verify your email id. Once you verified your email id then you can logged in to dashboard.
        </Text>
        <Button onClick={()=>router.push("/sign-in")} size="md" fontWeight={500} fontSize={14} colorScheme="green">
          Go to Login
        </Button>
      </Box>
      </Flex>
    </Container>
  );
};

export default VerifiedUser;
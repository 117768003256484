import * as React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Spacer, Text, Box, FormControl, FormLabel, Input, Button, ButtonGroup, useColorModeValue } from '@chakra-ui/react'
import Uploadset from '../uploadset';
import { useRouter } from 'components/use-hook';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { useState } from "react";
import { apiCall, successMessage, failedMessage } from 'utils/httpClient';
import apiEndPoint from 'utils/apiEndPoint';

export default function UploadStepper(props) {
   const {onClose, getAllDataset} = props
  const router = useRouter();
  const reduxDispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { userToken } = useSelector(state => state.login);
  const [datasetFormData, setDatasetFormdata] = useState({});
  console.log('datasetFormData: ', datasetFormData);
  const [errorMessage, setErrorMessage] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const validation = () => {
    let isError = true;
    let errorMessage = {};
    if (isEmpty(datasetFormData.datasetname)) {
      isError = false;
      errorMessage["datasetname"] = "Please fill the dataset name";

    } 
    if (isEmpty(datasetFormData.datasetdescription)) {
      isError = false;
      errorMessage["datasetdescription"] = "Please fill the description";
    }
    setErrorMessage(errorMessage);
    return isError;
  };

  const handleContinue = () => {
    if (validation()) {
      setActiveTab(1); // Navigate to Upload tab
    }
  };

  const handleCreateDataset = async()=>{
      const header = { "Authorization": `${userToken}` }
      const form = new FormData();
      form.append("dataset_name", datasetFormData?.datasetname);
      form.append("dataset_description", datasetFormData?.datasetdescription);
      if (datasetFormData?.uploaddatasetfile) {
        form.append("dataset_file", datasetFormData.uploaddatasetfile);
      }
      setSubmitLoading(true);
      const { data } = await apiCall('post', apiEndPoint.UPLOADDATASET, form, header);
      console.log('data88: ', data);
      if (data.status!==200) {
        failedMessage(data?.message);
      } else {
        successMessage(data?.message);
        getAllDataset();
        onClose();
      }
      setSubmitLoading(false);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs orientation='vertical' index={activeTab} onChange={(index) => setActiveTab(index)}>
        <TabList>
          <Tab>Setup</Tab>
          <Tab isDisabled={activeTab === 0}>Upload</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Text fontSize='md' fontWeight={500} my={5}>Configure Dataset</Text>
            <FormControl>
              <FormLabel>Name *</FormLabel>
              <Input 
              placeholder={errorMessage.datasetname ? errorMessage.datasetname : 'Enter name'}
              variant='search' 
              name="datasetname"
              value={datasetFormData?.datasetname}
              onChange={(e) => setDatasetFormdata({ ...datasetFormData, datasetname: e.target.value })}
              isInvalid={!!errorMessage.datasetname} 
              errorBorderColor='red.300'
              color={textColor}
              />              
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
              <Spacer my={4} />
              <FormLabel>Description</FormLabel>
              <Input 
              placeholder={errorMessage.datasetdescription ? errorMessage.datasetdescription : 'Enter description'} 
              variant='search'
              name="datasetdescription"
              value={datasetFormData?.datasetdescription}
              onChange={(e) => setDatasetFormdata({ ...datasetFormData, datasetdescription: e.target.value })}
              isInvalid={!!errorMessage.datasetdescription} 
              errorBorderColor='red.300'
              color={textColor}
              />              
              <ButtonGroup variant='outline' spacing='6' mt={10}>
                <Button onClick={onClose}>Cancel</Button>
                <Button colorScheme='blue' onClick={handleContinue}>Continue</Button>
              </ButtonGroup>
            </FormControl>
          </TabPanel>
          <TabPanel>
          <Uploadset datasetFormData={datasetFormData} setDatasetFormdata={setDatasetFormdata} onClose={onClose} handleCreateDataset={handleCreateDataset} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
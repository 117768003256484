import { ChevronDownIcon, HamburgerIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import { Avatar, Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Grid, GridItem, HStack, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Select, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Stack, Switch, Text, Textarea, Tooltip, useDisclosure, useNumberInput } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaMicrophone } from 'react-icons/fa'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useColorModeValue,
  ModalCloseButton,
} from '@chakra-ui/react'
import { BsFolder2, BsMusicNoteList, BsVoicemail } from 'react-icons/bs'
import { CiVoicemail } from 'react-icons/ci'
import AudioList from './components/audiolist'
import AudioUploadPreview from './fileupload'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import { HiPaperAirplane } from 'react-icons/hi2'
import { GiSurprised } from 'react-icons/gi'

export default function AiMusicGeneration() {
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [showAudioList, setShowAudioList] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const btnRef = React.useRef()
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 3,
      defaultValue: 3,
      min: 1,
      max: 5,
      precision: 2,
    })
  const [audioFiles] = useState([
    new File([''], 'audio1.mp3', { type: 'audio/mpeg', size: 123456 }),
    new File([''], 'audio2.wav', { type: 'audio/wav', size: 654321 }),
  ]);
  const bgButton = useColorModeValue("navy.50", "gray.600");

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()
  return (
    <Box>
      <Box m={6} pt={{ base: "200px", md: "80px", xl: "100px" }}>
        <Flex>
          <Box>
            <Text fontSize="2xl" fontWeight="500">
              AI Music Generation
            </Text>
          </Box>
          <Spacer />
        </Flex>
        <HStack spacing='24px' width={'100%'} alignItems={'baseline'} display={{ sm: 'block', md: 'flex' }}>
          <Box w={{ base: "100%", md: "40%", xl: '30%' }} height={'calc(100vh - 180px)'} overflowY={'auto'} pr={4}>
            <Box>
              <Stack spacing={2} display={{ sm: 'block', lg: 'flex' }} flexDirection={{ sm: 'column', md: 'row' }} alignItems='center' justifyContent={'start'} mt={5}>
                <Box onClick={onOpen2}>
                  <Switch id='email-aler' />
                </Box>
                <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0'>
                  Custom
                </FormLabel>
                <Button borderRadius={50} fontSize="sm" variant='outline' onClick={onOpen1}>Upload Audio</Button>
                <Box>
                  <Menu>
                    <MenuButton as={Button} >
                      v3.5
                    </MenuButton>
                    <MenuList maxW={250}>
                      <MenuGroup title='v3.5'>
                        <MenuItem fontSize={14}>Newest model, better
                          song structure, max 4
                          minutes</MenuItem>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuGroup title='v3'>
                        <MenuItem fontSize={14}>Broad, versatile, max 2
                          minutes</MenuItem>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuGroup title='v2'>
                        <MenuItem fontSize={14}>Vintage Suno model, max
                          1.3 minutes</MenuItem>
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                </Box>
              </Stack>
              <Box mt={8} mb={4}>
                <FormControl display='flex' alignItems='center' justifyContent={'space-between'}>
                  <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0'>
                    Song description<Tooltip label='Select the song' fontSize='xs'>
                      <QuestionOutlineIcon ml='1' fontSize='sm' />
                    </Tooltip>
                  </FormLabel>
                  <Box display={'flex'}>
                    <Switch id='email-alerts' /> <Text ml={4} fontSize="xs" fontWeight="500">
                      Instrumental
                    </Text>
                  </Box>
                </FormControl>
              </Box>
              <Textarea rows={6} placeholder='a smooth hip hop song about a literal banana' />

              {showBox && (
                <Box>
                  <Box mt={8} mb={4}>
                    <FormControl display='flex' alignItems='center' justifyContent={'space-between'}>
                      <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0'>
                        Lyrics<Tooltip label='Select the lyrics' fontSize='xs'>
                          <QuestionOutlineIcon ml='1' fontSize='sm' />
                        </Tooltip>
                      </FormLabel>
                      <Box display={'flex'}>
                        <Switch id='email-alerts' /> <Text ml={4} fontSize="xs" fontWeight="500">
                          Instrumental
                        </Text>
                      </Box>
                    </FormControl>
                  </Box>
                  <Box position="relative">
                    <Textarea rows={6}
                      placeholder="Enter your own lyrics or describe a song and click Write About..." />
                    <Button
                      position="absolute"
                      left="10px"
                      bottom="10px"
                      size="xs"
                      as="span"
                      leftIcon={<GiSurprised />}
                    >
                      Surprise me
                    </Button>
                  </Box>
                  <Box mt={8} mb={4}>
                    <FormControl display='flex' alignItems='center' justifyContent={'space-between'}>
                      <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0'>
                        Style of Music<Tooltip label='Select the style' fontSize='xs'>
                          <QuestionOutlineIcon ml='1' fontSize='sm' />
                        </Tooltip>
                      </FormLabel>
                    </FormControl>
                  </Box>
                  <Textarea rows={6} placeholder='Enter style of music' />
                  <Box mt={8} mb={4}>
                    <FormControl display='flex' alignItems='center' justifyContent={'space-between'}>
                      <FormLabel fontSize="sm" htmlFor='email-alerts' mb='0'>
                        Title<Tooltip label='Enter a title' fontSize='xs'>
                          <QuestionOutlineIcon ml='1' fontSize='sm' />
                        </Tooltip>
                      </FormLabel>
                    </FormControl>
                  </Box>
                  <Textarea rows={6} placeholder='Type your message here... ' />
                </Box>
              )}
              <Box my={8} display={'flex'} gap={4}>
                <Button fontWeight={500} fontSize={15} width={150} bg={bgButton} >
                  Cancel
                </Button>
                <Button fontWeight={500} fontSize={15} width="full" leftIcon={<BsMusicNoteList />} bgGradient='linear(to-l, #7928CA, #FF0080)'>
                  Create
                </Button>
              </Box>
            </Box>
          </Box>
          <Box w={{ base: "100%", md: "60%", xl: '70%' }}>
            <Modal isOpen={isOpen1} onClose={onClose1} isCentered size={'xl'}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Upload Audio</ModalHeader>
                <ModalCloseButton />
                <ModalBody sx={{ position: 'relative' }}>
                  <Box my={8}>
                    <Text fontSize='md' fontWeight={400} my={2}>Drop files here, browse files or import from:</Text>
                  </Box>
                  <Box my={4} textAlign={'center'}>
                    <AudioUploadPreview />
                  </Box>
                  <Box my={4} textAlign={'center'}>
                    <Button as="span" leftIcon={<FaMicrophone />} variant="ghost" onClick={onToggle}>
                      Audio
                    </Button>
                    <Popover
                      returnFocusOnClose={false}
                      isOpen={isOpen}
                      onClose={onClose}
                      placement="auto"
                      closeOnBlur={false}
                    >
                      <PopoverContent width="94%" mt={5}>
                        <PopoverHeader fontWeight='semibold'>Import from Audio</PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Box textAlign={'center'}>
                            <Button><FaMicrophone fontSize={26} /></Button>
                          </Box>
                          <Box my={8}>
                            <Text fontSize='lg' fontWeight={500} my={2}>
                              Microphone Not Available
                            </Text>
                          </Box>
                          <Box>
                            <Text fontSize='md' fontWeight={400} my={2}>
                              In order to record audio, please connect a microphone or another audio input device
                            </Text>
                          </Box>
                        </PopoverBody>
                        <PopoverFooter display='flex' justifyContent='flex-end'>
                          <Box size='sm'>
                            <Button size='sm' as="span" variant='outline'>Cancel</Button>
                          </Box>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </Box>
                  <Box mt={10}>
                    <Text fontSize='sm' fontWeight={400} opacity={.8} my={2}>Must be original audio between 6 and 60 seconds long. Audio with
                      vocals detected will be made private-only.</Text>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button variant='ghost' onClick={onClose1}>
                    Cancel
                  </Button>
                  <Button onClick={() => { setShowAudioList((prev) => !prev); onClose1() }}
                  >
                    {showAudioList ? 'Continue' : 'Upload'}
                  </Button>                </ModalFooter>
              </ModalContent>
            </Modal>
            <Modal isOpen={isOpen2} onClose={onClose2} isCentered size={'xl'}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Welcome to Custom Mode</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box my={8}>
                    <Text fontSize={15} fontWeight={500} my={2}>Start with Lyrics:                     <span style={{ fontSize: "14px", fontWeight: 400, marginLeft: 2 }}>Write down your thoughts, or click "Make Random Lyrics" for
                      spontaneous creativity. Prefer no words? Opt for "Instrumental" and let the tunes express themselves.</span></Text>
                  </Box>
                  <Box my={8}>
                    <Text fontSize={15} fontWeight={500} my={2}>Choose a Style: <span style={{ fontSize: "14px", fontWeight: 400, marginLeft: 2 }}>Type your "Style of Music" to set the vibe, mood, tempo and voice. Not sure? "Use Random Style" might surprise you with the perfect genre.</span></Text>
                  </Box>
                  <Box my={8}>
                    <Text fontSize={15} fontWeight={500} my={2}>Extend Your Song:                 <span style={{ fontSize: "14px", fontWeight: 400, marginLeft: 2 }}>Want to go longer? Click "Extend" on the song's row (or under the on mobile), enter additional lyrics to extend the song based on your previous verses, select the desired time to extend your song from, and press Continue. Once the extended songs have finished creating, click "." and "Get Full Song" to get the entire completed song.</span></Text>
                  </Box>
                  <Box my={8}>
                    <Text fontSize={15} fontWeight={500} my={2}>Unleash Your Creativity:<span style={{ fontSize: "14px", fontWeight: 400, marginLeft: 2 }}> Dive into the world of music-making and let your imagination run wild. Happy composing!</span></Text>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button
                    ml={4}
                    onClick={() => { setShowBox((prev) => !prev); onClose2() }}
                  >
                    {showBox ? 'Close' : 'Get Started'}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            {showAudioList && <AudioList audioFiles={audioFiles} />}
          </Box>
        </HStack>
      </Box>
    </Box>
  )
}
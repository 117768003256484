// const DEV_URL = 'http://localhost:5000'
const DEV_URL = 'https://api.aipajti.com/'
const PROD_URL = 'https://api.aipajti.com/'
// const PROD_URL = 'http://3.110.195.134:5000'
const BASE_URLNEW = process.env.NODE_ENV === 'development' ? DEV_URL : PROD_URL
// const REDIRECTURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://holochronai.com/'
const REDIRECTURLNEW = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://aipajti.com'
const ENCODE_URL_DEV = "http%3A%2F%2Flocalhost%3A3000" // local url
const ENCODE_URL_PROD = "https%3A%2F%2Faipajti.com" // server url
const GOOGLE_CLIENT_ID = "978774821575-e8up6vau3pnuu4gasp7oo4r8ei3ngeg1.apps.googleusercontent.com"
const GOOGLE_REDIRECT_URL = process.env.NODE_ENV === "development" ? ENCODE_URL_DEV : ENCODE_URL_PROD

const doteenv = {
    BASE_URL: BASE_URLNEW,
    REDIRECTURL: REDIRECTURLNEW,
    GOOGLE_SIGNUP_LINK: `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&client_id=${GOOGLE_CLIENT_ID}&prompt=select_account&redirect_uri=${GOOGLE_REDIRECT_URL}&response_type=code&scope=email%20profile%20openid&openid.realm&service=lso&o2v=1&theme=glif&flowName=GeneralOAuthFlow&state=`,
};
export default doteenv;
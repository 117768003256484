import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Avatar,
  useToast,
  Container,
  Flex,
  Center,
  Heading,
  Text,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import React, { useState } from "react";
// import { useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import apiEndPoint from "utils/apiEndPoint";
import { failedMessage } from "utils/httpClient";
import { successMessage } from "utils/httpClient";
import { apiCall } from "utils/httpClient";
import { isEmpty } from "utils/utils";
const ChangePassword = () => {
  const { userToken } = useSelector(state => state.login);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const toast = useToast();
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [password, setPassword] = useState(null);
  const [userPassword, setUserPassword] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validation = () => {
    let isError = true;
    let errorMessage = {};

    if (isEmpty(userPassword?.old_password)) {
      isError = false;
      errorMessage["old_password"] = "Enter Old Password";
    }
    if (isEmpty(userPassword?.new_password)) {
      isError = false;
      errorMessage["new_password"] = "Enter New password";
    }
    setErrorMessage(errorMessage);
    return isError;
  };

  const handleResetPassword = async () => {
    if (validation()) {
      setIsLoading(true);
      const header = { "Authorization": `${userToken}` }
      const params = {...userPassword, confirm_password : userPassword?.new_password}
      const { data } = await apiCall('post', apiEndPoint.CHANGEPASSWORD, params, header);
      if (data.status !== 200) {
        failedMessage(data.message);
      } else {
        successMessage(data.message)
      }
      setIsLoading(false);
    }
  };

  return (
    <Box borderWidth={1} borderRadius="xl" p={5}>
      {/* <Text  fontWeight={600} mb={4}>
          Edit Profile
        </Text> */}
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            display='flex'>
            Old Password <Text color={brandStars}> *</Text>
          </FormLabel>
          <InputGroup size='md'>
            <Input
              isRequired={true}
              fontSize='sm'
              placeholder='Min. 8 characters'
              mb='24px'
              size='lg'
              type={show ? "text" : "password"}
              variant='auth'
              name="old_password"
              value={userPassword?.old_password}
              onChange={(e) => { setUserPassword({ ...userPassword, old_password: e.target.value }); }}
              onError={isEmpty(userPassword?.old_password) && !isEmpty(errorMessage?.old_password) && true}
            />
            <InputRightElement display='flex' alignItems='center' mt='4px'>
              <Icon
                color={textColorSecondary}
                _hover={{ cursor: "pointer" }}
                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={handleClick}
              />
            </InputRightElement>
          </InputGroup>
          <FormLabel
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            display='flex'>
            New Password <Text color={brandStars}> *</Text>
          </FormLabel>
          <InputGroup size='md'>
            <Input
              isRequired={true}
              fontSize='sm'
              placeholder='Min. 8 characters'
              mb='24px'
              size='lg'
              type={show ? "text" : "password"}
              variant='auth'
              name="new_password"
              value={userPassword?.new_password}
              onChange={(e) => { setUserPassword({ ...userPassword, new_password: e.target.value }); }}
              onError={isEmpty(userPassword?.new_password) && !isEmpty(errorMessage?.new_password) && true}
            />
            <InputRightElement display='flex' alignItems='center' mt='4px'>
              <Icon
                color={textColorSecondary}
                _hover={{ cursor: "pointer" }}
                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={handleClick}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Box>
          <Button onClick={handleResetPassword} variant='brand' size="md" fontWeight={500} fontSize={14}>
            Change Password
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default ChangePassword;

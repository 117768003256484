import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    HStack,
    Icon,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Select,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { ImUpload } from "react-icons/im";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'
import { IoCloudUploadOutline, IoCopyOutline } from "react-icons/io5";
// Assets
import { MdUpload } from "react-icons/md";
import { RiFileUploadLine } from "react-icons/ri";
import Dropzone from "views/admin/profile/components/Dropzone";
import { Tab, TabList, TabPanel, TabPanels, Tabs, } from '@chakra-ui/react'
import UploadCardBox from "./uploadcardbox";
import { TbFileFunction } from "react-icons/tb";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { isEmpty } from "utils/utils";
import { FiTrash2 } from "react-icons/fi";
import { truncateText } from "utils/utils";

export default function Uploadset(props) {
    // const { used, total,  ...rest } = props;
    const { datasetFormData, setDatasetFormdata, onClose, handleCreateDataset } = props

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";

    function formatFileSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '12 Bytes';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    }

    const handleDatasetFileUpload = (file) => {

        console.log('e.target.files[0]: ', file);
        setDatasetFormdata({
            ...datasetFormData,
            uploaddatasetfile: file
        });
    };

    return (
        <Box>
            <Box>
                <Text fontSize='md' fontWeight={500} my={5}>Configure Data Sources</Text>
                <Box my={2}>
                    <Alert status='info' borderRadius={5}>
                        <AlertIcon />
                        This step is required. You need to add some data to the dataset.
                    </Alert>
                </Box>
                <Box my={8}>
                    <Tabs>
                        <TabList>
                            <Tab>Files</Tab>
                            {/* <Tab>Web Pages</Tab>
                            <Tab>Text</Tab>
                            <Tab>SQL Databases</Tab> */}
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Flex justifyContent={'space-around'}>
                                    {
                                        datasetFormData?.uploaddatasetfile && !isEmpty(datasetFormData?.uploaddatasetfile) ?
                                            <>
                                                {/* <HStack textAlign={"center"} display={"flex"} alignItems={"center"}>
                                                    <Box display={'flex'} gap={2} bgColor={boxBg} p={2} borderRadius="md">
                                                        <TbFileFunction />
                                                        <Text fontSize="sm">{datasetFormData?.uploaddatasetfile?.name}</Text>
                                                    </Box>
                                                    <Box ml={'auto'}>
                                                        <DeleteIcon fontSize={12} onClick={() => {
                                                            setDatasetFormdata({
                                                                ...datasetFormData,
                                                                uploaddatasetfile: null
                                                            });
                                                        }} />
                                                    </Box>
                                                
                                                </HStack> */}
                                                <Box
                                                    border={1}
                                                    borderRadius="md"
                                                    p={5}
                                                    bgColor={boxBg}
                                                    width="100%"
                                                >
                                                    <Flex align="center" justify="space-between">
                                                        <Flex align="center">
                                                            <TbFileFunction />
                                                            <Text fontSize="sm" ml={2}>{truncateText(datasetFormData?.uploaddatasetfile?.name, 25)}</Text>
                                                        </Flex>
                                                        <Text fontSize="sm" color="gray.500">
                                                            {formatFileSize(datasetFormData?.uploaddatasetfile?.size)}
                                                        </Text>
                                                        <DeleteIcon fontSize={12} onClick={() => {
                                                            setDatasetFormdata({
                                                                ...datasetFormData,
                                                                uploaddatasetfile: null
                                                            });
                                                        }} />
                                                    </Flex>
                                                    <Box>
                                                        <Progress width={'100%'} colorScheme="brandScheme" size="xs" value={100} mt={2} />
                                                    </Box>
                                                </Box>
                                            </> :
                                            <Card>
                                                <Text my={4} textAlign={'center'} fontSize={'1xl'} fontWeight={'500'}>Upload Dataset Files</Text>
                                                <Box textAlign={'center'}>
                                                    <Dropzone
                                                        w={{ base: "100%", "2xl": "100%" }}
                                                        maxH={{ base: "100px", lg: "120px", "2xl": "140px" }}
                                                        minH={{ base: "100px", lg: "120px", "2xl": "140px" }}
                                                        content={
                                                            <Box>
                                                                <Icon as={MdUpload} w="40px" h="40px" color={brandColor} />
                                                                <Flex justify="center" mx="auto" mb="12px">
                                                                </Flex>
                                                                <Flex alignItems={'center'} justifyContent={'center'} my={4}>
                                                                    <ImUpload fontSize={14} />
                                                                    <Text ml={3} fontSize="xs" fontWeight="400" >
                                                                        You can Select or Drag & Drop files here..
                                                                    </Text>
                                                                </Flex>
                                                            </Box>
                                                        }
                                                        onFileUpload={(file) => handleDatasetFileUpload(file)}
                                                    />
                                                    {/* <Button leftIcon={<RiFileUploadLine />} variant='outline' mt={4} fontSize='14' fontWeight='400'>
                                                Choose File
                                            </Button> */}
                                                </Box>
                                            </Card>
                                    }

                                </Flex>
                                {/* <UploadCardBox/> */}
                                <ButtonGroup variant='outline' spacing='6' mt={10}>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button colorScheme='blue' onClick={handleCreateDataset}>Create Dataset</Button>
                                </ButtonGroup>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </Box>
            </Box>
        </Box>
    );
}
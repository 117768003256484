import {
    Box,
    Button,
    Divider,
    Flex,
    Text,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useColorModeValue,
    useDisclosure,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Input,
    Checkbox,
    HStack,
    Spinner,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import { ImUpload } from "react-icons/im";
import { IoCloseCircle, IoCloseCircleOutline, IoCloudUploadOutline, IoCopyOutline, IoFishSharp, IoLogoAmplify } from "react-icons/io5";
// Assets
import { MdAlternateEmail, MdCheckCircle, MdSettings, MdTranslate, MdUpload } from "react-icons/md";
import { RiFileUploadLine } from "react-icons/ri";
import Dropzone from "views/admin/profile/components/Dropzone";
import { GiCircularSaw, GiDolphin, GiTiger } from "react-icons/gi";
import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
} from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { FaFileAudio, FaLanguage } from "react-icons/fa";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { isEmpty } from "utils/utils";
import { CgMusic } from "react-icons/cg";
import { truncateText } from "utils/utils";


export default function AddTranscribeFile(props) {
    const { isOpen, onClose, allParameter, parameterLoading, formDetail, setFormDetail, handleAddAudioTranscribe, submitLoading } = props;
    console.log('allParameter: ', allParameter);
    console.log('formDetail:22 ', formDetail);
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    // const { used, total, ...rest } = props;

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [showRow, setShowRow] = useState(false);
    const [selectedGender, setSelectedGender] = useState('Select gender');

    return (
        <Box>
            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader pb={0}>
                        <Flex justifyContent={'center'} alignItems={'center'}>
                            <IoCloudUploadOutline fontSize={18} />
                            <Text ml={2} fontSize="16" fontWeight="600" >
                                Transcribe Files
                            </Text>
                        </Flex>
                        <Flex alignItems={'center'} justifyContent={'space-between'} mt={6}>
                            <Text ml={3} fontSize="sm" fontWeight="400" >
                                Audio / Video Files
                            </Text>
                            <IoCopyOutline fontSize={14} />
                        </Flex>
                    </ModalHeader>
                    <ModalBody>
                        {formDetail?.audio_sample && !isEmpty(formDetail?.audio_sample) ?
                            <HStack textAlign={"center"} display={"flex"} alignItems={"center"} my={3}>
                                <Box display={'flex'} gap={2} bgColor={boxBg} p={2} borderRadius="md" alignItems={"center"}>
                                    <CgMusic />
                                    <Text fontSize="sm">{truncateText(formDetail?.audio_sample?.name, 25)}</Text>
                                </Box>
                                <Box ml={'auto'}>
                                    <IoCloseCircle fontSize={20} cursor={"pointer"} onClick={() => {
                                        setFormDetail({
                                            ...formDetail,
                                            audio_sample: null
                                        });

                                    }} />
                                </Box>
                            </HStack>
                            :
                            <Dropzone
                                maxH={{ base: "200px", lg: "250px", "2xl": "150px" }}
                                minH={{ base: "200px", lg: "250px", "2xl": "150px" }}
                                content={
                                    <Box>
                                        <Flex justify="center" mx="auto" mb="12px">
                                        </Flex>
                                        <Flex alignItems={'center'} justifyContent={'center'}>
                                            <ImUpload fontSize={14} />
                                            <Text ml={3} fontSize="sm" fontWeight="500" >
                                                Drag & Drop
                                            </Text>
                                        </Flex>
                                        <Text my={4} fontSize="xs" fontWeight="400" >
                                            MP3, WAV
                                        </Text>
                                        <Button variant='outline' mt={1} size="sm" fontWeight='400'>
                                            Browse Files
                                        </Button>
                                    </Box>
                                }
                                onFileUpload={(file) => setFormDetail({
                                    ...formDetail,
                                    audio_sample: file
                                })}
                            />
                        }
                        <Box my={6}>
                            <Text fontSize={'12'} fontWeight="400" mb={1}>
                                Audio Name
                            </Text>
                            <Input color={textColor} placeholder='Enter audio name'
                                onChange={(e) => setFormDetail({ ...formDetail, name: e.target.value })}
                                value={formDetail?.name} />
                        </Box>
                        <Text my={1} mt={4} fontSize="xs" fontWeight="400" >
                            Audio Language
                        </Text>
                        <Menu width="100%">
                            <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                                {formDetail?.language
                                    ? allParameter?.languages?.find(lang => lang.value === formDetail.language)?.name
                                    : "Select Language"
                                }
                            </MenuButton>
                            <MenuList minH={100} maxH={300} overflowY={'auto'}>
                                {!isEmpty(allParameter) && !isEmpty(allParameter?.languages) ?
                                    allParameter?.languages.map((language, i) => (
                                        <>
                                            <MenuItem fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, language: e.target.value })} value={language?.value} key={i} >{language?.name}</MenuItem></>))
                                    :
                                    <MenuItem fontSize={14}>No Language found!</MenuItem>
                                }
                            </MenuList>
                        </Menu>
                        <Text my={1} mt={5} fontSize="xs" fontWeight="400" >
                            Transcription Mode
                        </Text>
                        <Flex>
                            <Card p={2}>
                                <Flex divider={<Divider orientation='vertical' />} justifyContent={'space-around'} textAlign={'center'}>
                                    <Box cursor={'pointer'} p={4} w={'100%'} borderRadius={15}
                                        _hover={bgHover}
                                        onClick={(e) => setFormDetail({ ...formDetail, model: 'aipajti_asr' })}
                                        bgColor={formDetail?.model === 'aipajti_asr' ? 'whiteAlpha.50' : 'unset'}
                                    >
                                        <IoLogoAmplify style={{ margin: 'auto' }} />
                                        <Text fontSize='sm' >
                                            AIPajti ASR
                                        </Text>
                                        <Text fontSize='xs'>
                                            Fastest
                                        </Text>
                                    </Box>
                                    <Box cursor={'pointer'} p={4} w={'100%'} borderRadius={15}
                                        _hover={bgHover}
                                        onClick={(e) => setFormDetail({ ...formDetail, model: 'sonic_scribe' })}
                                        bgColor={formDetail?.model === 'sonic_scribe' ? 'whiteAlpha.50' : 'unset'}
                                    >
                                        <FaFileAudio style={{ margin: 'auto' }} />
                                        <Text fontSize='sm'>
                                            Sonic Scribe
                                        </Text>
                                        <Text fontSize='xs'>
                                            (OpenAI ASR)
                                        </Text>
                                    </Box>
                                </Flex>
                            </Card>
                        </Flex>
                        <Flex my={4}>
                            <Checkbox
                                isChecked={formDetail?.english_transcription}
                                onChange={(e) => setFormDetail({ ...formDetail, english_transcription: e.target.checked })} />
                            <Box>
                                <Button leftIcon={<MdTranslate style={{ fontSize: '16px' }} />} variant='none'>
                                    <Text fontSize="sm" fontWeight="400" >
                                        Transcribe to English
                                    </Text>
                                </Button>
                                <Text fontSize="11" ml={4} fontWeight="400" >
                                    Transcribe the original audio language directly to English.
                                </Text>
                            </Box>
                        </Flex>
                        {/* <Accordion allowToggle mt={4}>
                          <AccordionItem border={0}>
                            <Text >
                              <AccordionButton fontSize={12}>
                                <Box as='span' flex='1' textAlign='left'>
                                  Speaker Recognition & More Settings
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </Text>
                            <AccordionPanel pb={2}>
                              <List spacing={3}>
                                <ListItem>
                                  <ListIcon as={MdCheckCircle} color='green.500' />
                                  Lorem ipsum dolor sit amet,
                                </ListItem>
                                <ListItem>
                                  <ListIcon as={MdCheckCircle} color='green.500' />
                                  Assumenda, quia temporibus
                                </ListItem>
                                <ListItem>
                                  <ListIcon as={MdCheckCircle} color='green.500' />
                                  Quidem, ipsam illum quis se
                                </ListItem>
                                <ListItem>
                                  <ListIcon as={MdSettings} color='green.500' />
                                  Quidem, ipsam illum quis
                                </ListItem>
                              </List>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button isFullWidth onClick={handleAddAudioTranscribe} >
                            {submitLoading ? <Spinner /> :
                                <Text fontSize="sm" fontWeight="400" >
                                    TRANSCRIBE
                                </Text>}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
};

import { AddIcon, ArrowForwardIcon, ChevronDownIcon, ChevronRightIcon, CloseIcon, DeleteIcon, InfoIcon, SettingsIcon, SpinnerIcon } from '@chakra-ui/icons'
import {
  AspectRatio, Box, Icon, Button, ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Flex, FormControl, FormLabel, Image, Input, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useNumberInput,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  Select,
  ModalFooter,
  SimpleGrid,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack, Spinner
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
} from '@chakra-ui/react'
import {
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import Card from 'components/card/Card';
import { IoAddCircleOutline } from 'react-icons/io5';
import { ImLab } from "react-icons/im";
import { GiEmptyWoodBucketHandle, GiGraduateCap, GiRapidshareArrow, GiSoundOn } from "react-icons/gi";
import { IoLockClosed } from "react-icons/io5";
import { Progress } from '@chakra-ui/react';
import { GiSpeaker } from "react-icons/gi";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline, MdOutlineAudiotrack, MdUpload } from "react-icons/md";
import IconBox from 'components/icons/IconBox';
import MiniStatistics from "components/card/MiniStatistics";
import { FaVideo } from 'react-icons/fa';
import { FcFilmReel } from 'react-icons/fc';
import Dropzone from "views/admin/profile/components/Dropzone";
import { RiVideoUploadLine } from "react-icons/ri";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import AudioDubblingfile from './components';
import { useSelector } from 'react-redux';
import { apiCall } from 'utils/httpClient';
import apiEndPoint from 'utils/apiEndPoint';
import { isEmpty } from 'utils/utils';
import { useDropzone } from 'react-dropzone';
import { failedMessage } from 'utils/httpClient';
import { successMessage } from 'utils/httpClient';
import NoDataFound from '../../assets/img/no_found.png';
import { warnMessage } from 'utils/httpClient';

export default function Dubbling(props) {
  const { used, total, ...rest } = props;
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const { isOpen: isOpenModal1, onOpen: onOpenModal1, onClose: onCloseModal1 } = useDisclosure();
  const { isOpen: isOpenModal2, onOpen: onOpenModal2, onClose: onCloseModal2 } = useDisclosure();
  const { isOpen: isOpenModal3, onOpen: onOpenModal3, onClose: onCloseModal3 } = useDisclosure();

  const { userToken } = useSelector(state => state.login)
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [formDetail, setFormDetail] = useState(null);
  console.log('formDetail: ', formDetail);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [parameterLoading, setParameterLoading] = useState(false);
  const [allParameter, setAllParameter] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [allDubbedFile, setAllDubbedFile] = useState(null);

  const getdubbingparameter = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setParameterLoading(true);
    const { data } = await apiCall('get', apiEndPoint.GETDUBBINGPARAMETER, params, header);
    if (data.status !== 200) {
      setAllParameter(null);
    } else {
      setAllParameter(data?.data);
    }
    setParameterLoading(false);
  }

  const getalldubbedfile = async () => {
    const params = {};
    const header = { "Authorization": `${userToken}` }
    setParameterLoading(true);
    const { data } = await apiCall('get', apiEndPoint.GETDUBBEDFILE, params, header);
    if (data.status !== 200) {
      setAllDubbedFile(null);
    } else {
      setAllDubbedFile(data?.dubbed_files);
    }
    setParameterLoading(false);
  }

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    // Validate file size and type
    const validFileTypes = ['audio/mp3', 'audio/wav', 'video/mp4', 'video/mkv'];
    if (!validFileTypes.includes(file.type)) {
      failedMessage('Please upload a valid MP3, WAV, or MP4, MKV file');
      return;
    }

    if (file.size > 100 * 1024 * 1024) { // 100 MB size limit
      failedMessage('File size exceeds the limit of 100MB');
      return;
    }

    // Store file in state and formDetail
    setSelectedFile(file);
    setFormDetail({ ...formDetail, audio_video_file: file });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'audio/*': ['.mp3', '.wav'],
      'video/*': ['.mp4'],
    },
    maxSize: 100 * 1024 * 1024, // 100 MB limit
  });

  // Helper function to format and validate time input
  const formatTime = (value) => {
    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, '');

    // Pad hours, minutes, and seconds to ensure 2 digits
    let hours = value.substring(0, 2);
    let minutes = value.substring(2, 4);
    let seconds = value.substring(4, 6);

    if (hours.length > 2) {
      minutes = hours.substring(2) + minutes;
      hours = hours.substring(0, 2);
    }
    if (minutes.length > 2) {
      seconds = minutes.substring(2) + seconds;
      minutes = minutes.substring(0, 2);
    }

    // Format to hh:mm:ss
    hours = hours.padStart(2, '0');
    minutes = minutes.padStart(2, '0');
    seconds = seconds.padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleTimeChange = (e, field) => {
    const value = e.target.value;

    // Format the value and update state
    setFormDetail(prevFormDetail => ({
      ...prevFormDetail,
      [field]: formatTime(value)
    }));
  };

  const validation = () => {
    let isError = true;

    if (!formDetail?.name || isEmpty(formDetail?.name)) {
      isError = false;
      failedMessage("Please Enter name ")
    }
    if (!formDetail?.audio_video_file || isEmpty(formDetail?.audio_video_file)) {
      isError = false;
      failedMessage("Please select any audio/video file")
    }
    if ((!formDetail?.source_language || isEmpty(formDetail?.source_language)) && (!formDetail?.target_language || isEmpty(formDetail?.target_language))) {
      isError = false;
      failedMessage("Please select source language and target language")
    }
    return isError;
  }

  // Function to convert time in HH:MM:SS format to seconds
  function convertTimeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  const handleCreateDubbing = async () => {
    if (validation()) {
      const header = { "Authorization": `${userToken}` }
      const form = new FormData();
      form.append("name", formDetail?.name);
      form.append("source_lang", formDetail?.source_language ? formDetail?.source_language : 'auto');
      form.append("target_lang", formDetail?.target_language);
      form.append("start_time", formDetail?.start_time ? convertTimeToSeconds(formDetail?.start_time) : 0);
      form.append("end_time", formDetail?.end_time ? convertTimeToSeconds(formDetail?.end_time) : 0);
      form.append("num_speakers", formDetail?.speaker_numbers ? formDetail?.speaker_numbers : 0);
      if (selectedFile?.type == 'video/mp4' || selectedFile?.type == 'video/mkv') {
        // form.append("watermark", formDetail?.watermark === false ? false : true);
        form.append("watermark", false);
      }
      if (selectedFile?.type === 'audio/mp3' || selectedFile?.type === 'audio/wav') {
        form.append("drop_background_audio", formDetail?.drop_background_audio === false ? false : true);
      }
      if (selectedFile?.type == 'video/mp4' || selectedFile?.type == 'video/mkv') {
        form.append("highest_resolution", formDetail?.video_resolution);
      }
      if (formDetail?.audio_video_file) {
        form.append("file", formDetail?.audio_video_file);
      }
      setSubmitLoading(true);
      warnMessage("Please wait, Dubbing is in progress. It will take few moments. Dubbed File will show below in list.");
      const { data } = await apiCall('post', apiEndPoint.CREATEDUBBING, form, header);
      if (data.status !== 200) {
        
        failedMessage(data?.message);
      } else {
        successMessage(data?.message);
        setFormDetail(null);
        getalldubbedfile();
      }
      setSubmitLoading(false);
    }
  }


  const handleDelete = async (selectedItem) => {
    const header = { "Authorization": `${userToken}` }
    const params = { action: 'delete', dubbing_id: selectedItem?.dubbing_id }
    const { data } = await apiCall('post', apiEndPoint.DELETEDUBBEDFILE, params, header);
    if (data.status!==200) {
      
      failedMessage(data?.message);
    } else {
      successMessage(data?.message);
      setSelectedItem(null);
      onCloseModal2();
      onCloseModal3();
      getalldubbedfile();
    }
  };

  useEffect(() => {
    getdubbingparameter();
    getalldubbedfile();
  }, []);


  return (
    <Box>
      <Box m={6} pt={{ base: "200px", md: "80px", xl: "100px" }}>
        <Box my='1'>
          <Text fontSize="2xl" fontWeight="600">
            Dubbing
          </Text>
          <Text fontSize="md" fontWeight="400">
            Translate your content across 29 languages in seconds with voice translation, speaker detection, and audio dubbing.
          </Text>
        </Box>
        <Divider my={6} />
        {/* <Card>
          <Flex alignItems={'center'}>
            <InfoIcon fontSize={18} />
            <Text ml={4} fontSize="lg" fontWeight="400">
              What can you use dubbing for?
            </Text>
          </Flex>
          <Box my={2}>
            <Text fontSize="sm" fontWeight="400">
              Automated Dubbing or Video Translation is a process for translating and replacing the original audio of a video with a new language, while preserving the unique characteristics of the original speakers' voices.
            </Text>
          </Box>
          <Flex gap={5}>
            <Box my={6} width={'25%'}>
              <Card bg={boxBg}>
                <Box p={3} bg={boxBg} borderRadius={5} width={'fit-content'}>
                  <FaVideo />
                </Box>
                <Box my={4}>
                  <Text fontSize="md" fontWeight="500">
                    Social Media Content
                  </Text>
                  <Box mt={2}>
                    <Text noOfLines={2} fontSize="sm" fontWeight="400" opacity={.6}>
                      Reach a wider audience overnight by dubbing video content across YouTube, TikTok, Instagram, and more.
                    </Text>
                  </Box>
                </Box>
              </Card>
            </Box>
            <Box my={6} width={'25%'}>
              <Card bg={boxBg}>
                <Box p={3} bg={boxBg} borderRadius={5} width={'fit-content'}>
                  <FcFilmReel />
                </Box>
                <Box my={4}>
                  <Text fontSize="md" fontWeight="500">
                    Media & Entertainment
                  </Text>
                  <Box mt={2}>
                    <Text noOfLines={2} fontSize="sm" fontWeight="400" opacity={.6}>
                      Bring foreign films and tv shows to life by dubbing them
                      into many languages without losing the original feel of the characters' voices.
                    </Text>
                  </Box>
                </Box>
              </Card>
            </Box>
            <Box my={6} width={'25%'}>
              <Card bg={boxBg}>
                <Box p={3} bg={boxBg} borderRadius={5} width={'fit-content'}>
                  <GiSoundOn />
                </Box>
                <Box my={4}>
                  <Text fontSize="md" fontWeight="500">
                    Marketing
                  </Text>
                  <Box mt={2}>
                    <Text noOfLines={2} fontSize="sm" fontWeight="400" opacity={.6}>
                      Seamlessly localize marketing materials and engage a
                      global audience in their native language.
                    </Text>
                  </Box>
                </Box>
              </Card>
            </Box>
            <Box my={6} width={'25%'}>
              <Card bg={boxBg}>
                <Box p={3} bg={boxBg} borderRadius={5} width={'fit-content'}>
                  <GiGraduateCap />
                </Box>
                <Box my={4}>
                  <Text fontSize="lg" fontWeight="400">
                    Education & E-Learning
                  </Text>
                  <Box mt={2}>
                    <Text noOfLines={2} fontSize="sm" fontWeight="400" opacity={.6}>
                      Make education inclusive and accessible by dubbing online course content, lectures, and documentaries.
                    </Text>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Flex>
        </Card> */}
        <Card mt='2rem'>
          <Box>
            <Text fontSize={'12'} fontWeight="400" mb={1}>
              Name
            </Text>
            <Input placeholder='Enter name' color={textColorPrimary} value={formDetail?.name || ''} onChange={(e) => setFormDetail({ ...formDetail, name: e.target.value })} />
          </Box>
          <Box my={6}>
            <Flex w={'100%'} gap={5}>
              <Box w={'50%'}>
                <Text fontSize={'12'} fontWeight="400">
                  Source Language
                </Text>
                <Box mt={2}>
                  <Menu width="100%">
                    <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                      {formDetail?.source_language
                        ? allParameter?.source_lang?.find(lang => lang.value === formDetail.source_language)?.name
                        : "Detect"
                      }
                    </MenuButton>
                    <MenuList minH={100} maxH={300} overflowY={'auto'}>
                      {!isEmpty(allParameter) && !isEmpty(allParameter?.source_lang) ?
                        allParameter?.source_lang.map((language, i) => (
                          <>
                            <MenuItem id={i} fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, source_language: e.target.value })} value={language?.value} key={i} >{language?.name}</MenuItem></>))
                        :
                        <MenuItem fontSize={14}>No Source Language found!</MenuItem>
                      }
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
              <Box w={'50%'}>
                <Text fontSize={'12'} fontWeight="400">
                  Target Language(s) *
                </Text>
                <Box mt={2}>
                  <Menu width="100%">
                    <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                      {formDetail?.target_language
                        ? allParameter?.target_lang?.find(lang => lang.value === formDetail.target_language)?.name
                        : "Select Language"
                      }
                    </MenuButton>
                    <MenuList minH={100} maxH={300} overflowY={'auto'}>
                      {!isEmpty(allParameter) && !isEmpty(allParameter?.target_lang) ?
                        allParameter?.target_lang.map((language, i) => (
                          <>
                            <MenuItem fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, target_language: e.target.value })} value={language?.value} key={i} >{language?.name}</MenuItem></>))
                        :
                        <MenuItem fontSize={14}>No  Target Language found!</MenuItem>
                      }
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box my={6}>
            <Tabs variant='soft-rounded' fontWeight={400}>
              <TabList sx={{ border: '1px solid', borderRadius: 50, padding: 2 }} variant='soft-rounded'>
                <Tab fontWeight={500}>Upload</Tab>
                <Tab fontWeight={500}>Youtube</Tab>
                <Tab fontWeight={500}>TikTok</Tab>
                <Tab fontWeight={500}>Other URL</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box my={2}>
                    {/* Show selected file */}


                    {/* <Text fontSize={'12'} fontWeight="400">
              Select a Source *
            </Text>
            <Box my={2}>
              <Button borderRadius={50} variant='outline'>
                <Text fontSize="sm" fontWeight="400" >
                  Upload
                </Text>
              </Button>
            </Box> */}

                    {/* Dropzone */}


                    {selectedFile && formDetail?.audio_video_file ? (
                      <Box
                        border="2px dashed"
                        p={4} bg={bgButton}
                        borderRadius="md"
                        cursor="pointer"
                        display={'flex'}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {selectedFile?.type == 'video/mp4' || selectedFile?.type == 'video/mkv' ?
                          <Box>
                            <video src={URL.createObjectURL(selectedFile)} controls />
                            <DeleteIcon onClick={() => setSelectedFile(null)} fontSize={25} mt={2} float={"right"} />
                          </Box> :
                          <HStack width={"100%"} justifyContent={"space-between"}>
                            <Text fontSize="sm">
                              {selectedFile.name} ({(selectedFile.size / (1024 * 1024)).toFixed(2)} MB)
                            </Text>
                            <DeleteIcon onClick={() => setSelectedFile(null)} />
                          </HStack>
                        }
                      </Box>
                    ) :

                      <Box
                        {...getRootProps()}
                        border="2px dashed"
                        p={4} bg={bgButton}
                        borderRadius="md"
                        cursor="pointer"
                        w={{ base: "100%", "2xl": "100%" }}
                        maxH={{ base: "180px", lg: "180px", "2xl": "200px" }}
                        minH={{ base: "180px", lg: "180px", "2xl": "200px" }}
                      >
                        <input {...getInputProps()} />
                        <Box textAlign={'center'} >
                          <Icon as={RiVideoUploadLine} w="40px" h="40px" color={brandColor} />
                          <Flex justify="center" mx="auto" mb="12px"></Flex>
                          <Box alignItems={'center'} justifyContent={'center'}>
                            <Text fontSize="sm" fontWeight="400">
                              Click to upload a file or drag and drop
                            </Text>
                            <Text mt={2} fontSize="xs" fontWeight="400" opacity={.5}>
                              Audio or Video files, up to 100MB or 15 min...
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    }

                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box mt={6}>
                    <Text fontSize={'12'} fontWeight="400" mb={1}>
                      YouTube URL
                    </Text>
                    <Input placeholder='https://www.youtube.com/' color={textColorPrimary} />
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box mt={6}>
                    <Text fontSize={'12'} fontWeight="400" mb={1}>
                      TikTok URL
                    </Text>
                    <Input placeholder='https://www.tiktok.com/' color={textColorPrimary} />
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box mt={6}>
                    <Text fontSize={'12'} fontWeight="400" mb={1}>
                      Other URL
                    </Text>
                    <Input placeholder='https://www.abcdemo.com/' color={textColorPrimary} />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
          {/* <Box my={2}>
            {selectedFile?.type === 'video/mp4' || selectedFile?.type === 'video/mkv' ?
              <Flex justifyContent={'space-between'}>
                <Box>
                  <Flex gap={5}>
                    <Text fontSize={'12'} fontWeight="400">
                      Add watermark to reduce character usage by 33%:
                    </Text>
                    <InfoIcon />
                  </Flex>
                </Box>
                <Box>
                  <Checkbox defaultChecked isChecked={formDetail?.watermark}
                    onChange={(e) => setFormDetail({ ...formDetail, watermark: e.target.checked })}>
                  </Checkbox>
                </Box>
              </Flex> : ''
            }
          </Box> */}
          <Box my={6}>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      Advanced Settings
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box>
                    <Box my={6}>
                      <Flex justifyContent={'space-between'} alignItems={'center'}>
                        <Box>
                          <Flex gap={5}>
                            <Text fontSize={'12'} fontWeight="400">
                              Number of speakers:
                            </Text>
                            <InfoIcon />
                          </Flex>
                        </Box>
                        <Box>
                          <Menu width="100%">
                            <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                              {formDetail?.speaker_numbers
                                ? allParameter?.num_speakers?.find(speaker => speaker.value == formDetail.speaker_numbers)?.name
                                : "Select"
                              }
                            </MenuButton>
                            <MenuList minH={100} maxH={300} overflowY={'auto'}>
                              {!isEmpty(allParameter) && !isEmpty(allParameter?.num_speakers) ?
                                allParameter?.num_speakers.map((speaker, i) => (

                                  <MenuItem fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, speaker_numbers: e.target.value })} value={speaker?.value} key={i} >{speaker?.name}</MenuItem>))
                                :
                                <MenuItem fontSize={14}>No Speakers found!</MenuItem>
                              }
                            </MenuList>
                          </Menu>
                        </Box>
                      </Flex>
                    </Box>
                    <Box my={6}>
                      {selectedFile?.type === 'video/mp4' || selectedFile?.type === 'video/mkv' ?
                        <Flex justifyContent={'space-between'}>
                          <Box>
                            <Flex gap={5}>
                              <Text fontSize={'12'} fontWeight="400">
                                Video Resolution:
                              </Text>
                              <InfoIcon />
                            </Flex>
                          </Box>
                          <Box>
                            <Menu width="100%">
                              <MenuButton width="100%" variant="outline" borderRadius={8} fontSize={13} fontWeight={500} as={Button} rightIcon={<ChevronDownIcon />}>
                                {formDetail?.video_resolution
                                  ? allParameter?.highest_resolution?.find(resolution => resolution.value == formDetail.video_resolution)?.name
                                  : "Select"
                                }
                              </MenuButton>
                              <MenuList>
                                {!isEmpty(allParameter) && !isEmpty(allParameter?.highest_resolution) ?
                                  allParameter?.highest_resolution.map((resolution, i) => (
                                    <MenuItem fontSize={14} onClick={(e) => setFormDetail({ ...formDetail, video_resolution: e.target.value })} value={resolution?.value} key={i} >{resolution?.name}</MenuItem>))
                                  :
                                  <MenuItem fontSize={14}>No Video Resolution found!</MenuItem>
                                }
                                <Text fontSize={"12px"} fontWeight="400" mt={1} p={2}>
                                  *If you want Highest resoluion choose True, Medium will be False!
                                </Text>
                              </MenuList>
                            </Menu>
                          </Box>
                        </Flex> : ''
                      }
                    </Box>
                    <Box>
                      <Text fontSize={'12'} fontWeight="400">
                        Extract a time range for dubbing:
                      </Text>
                      <Flex gap={5} mt={2}>
                        <Input placeholder='Start Time (HH:MM:SS)'
                          value={formDetail?.start_time}
                          onChange={(e) => handleTimeChange(e, 'start_time')}
                          color={textColorPrimary}
                        />
                        <Input placeholder=' End Time (HH:MM:SS)'
                          value={formDetail?.end_time}
                          onChange={(e) => handleTimeChange(e, 'end_time')}
                          color={textColorPrimary}
                        />
                      </Flex>
                    </Box>
                    {selectedFile?.type === 'audio/mp3' || selectedFile?.type === 'audio/wav'  ?
                      <Box my={6}>
                        <Flex justifyContent={'space-between'}>
                          <Box>
                            <Flex gap={5}>
                              <Text fontSize={'12'} fontWeight="400">
                                drop_background_audio:
                              </Text>
                              <InfoIcon />
                            </Flex>
                          </Box>
                          <Box>
                            <Checkbox defaultChecked isChecked={formDetail?.drop_background_audio}
                              onChange={(e) => setFormDetail({ ...formDetail, drop_background_audio: e.target.checked })} ></Checkbox>
                          </Box>
                        </Flex>
                      </Box> : ''
                    }
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Button isDisabled={submitLoading} onClick={handleCreateDubbing}>
            {submitLoading ? <Spinner size={'md'} /> :
              <Text fontSize="sm" fontWeight="400" >
                Create
              </Text>}
          </Button>
          {/* <Text fontSize={'12'} fontWeight="400" mt={5} opacity={.5}>
              This dub will cost approximately 2000 characters.
          </Text> */}
        </Card>
        <Card mt="2rem">
          <Text fontSize="xl" fontWeight="600">
            Dubbed Files
          </Text>
          {isEmpty(allDubbedFile) ?
            <center>
              <Image src={NoDataFound} width={200} />
              <Text fontSize={14}> No dubbed file found. Please create any dubbing file. </Text>
            </center>
            :
            <AudioDubblingfile allDubbedFile={allDubbedFile} setAllDubbedFile={setAllDubbedFile} allParameter={allParameter} handleDelete={handleDelete} setSelectedItem={setSelectedItem} selectedItem={selectedItem} isOpenModal2={isOpenModal2} onOpenModal2={onOpenModal2} onCloseModal2={onCloseModal2} isOpenModal3={isOpenModal3} onOpenModal3={onOpenModal3} onCloseModal3={onCloseModal3} />
          }
        </Card>
      </Box>
      <Modal isOpen={isOpenModal1} onClose={onCloseModal1} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize={'15'} fontWeight="400" >
              Type of voice to create
            </Text>
          </ModalHeader>
          <ModalBody>
            <Card>
              <Flex alignItems={'center'}>
                <ImLab />
                <Text fontSize="16" fontWeight="500" ml={2} >
                  Voice Design
                </Text>
              </Flex>
              <Box my={4}>
                <Text fontSize="13" fontWeight="400" opacity={.8}>
                  Design entirely new voices by adjusting their parameters. Every voice you create is randomly generated and is entirely unique even if the same settings are applied.
                </Text>
              </Box>
            </Card>
            <Card mt={4}>
              {/* <Flex alignItems={'center'}>
                    <GiRapidshareArrow />
                    <Text fontSize="16" fontWeight="500" ml={2} >
                      Instant Voice Cloning
                    </Text>
                  </Flex> */}
              <Flex alignItems={'center'} my={2}>
                <IoLockClosed fontSize="12" />
                <Text fontSize="13" fontWeight="400" ml={2} >
                  Starter + only. Subscribe?
                </Text>
              </Flex>
              <Box my={4}>
                <Text fontSize="13" fontWeight="400" opacity={.8}>
                  Clone a voice from a clean sample recording. Samples should contain 1 speaker and be over 1 minute long and not contain background noise.
                </Text>
              </Box>
            </Card>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
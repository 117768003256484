import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Routes,
  Navigate,
  BrowserRouter,
  useLocation,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import PricingPage from "./layouts/pricing";
import { Router } from "react-router-dom";
import SignUp from "views/auth/signup";
import DataSets from "layouts/datasets";
import SignIn from "views/auth/signIn";
import PriceList from "layouts/pricing/pricinglist";
import UserBilling from "layouts/userprofile/components/userbilling";
import Voices from "layouts/voices";
import Dashboard from "./layouts/admin";
import Notefound from "components/notFound";
import UserProfile from "layouts/userprofile";
import EditProfile from "layouts/userprofile/components/editprofile";
import VerifiedUser from "views/auth/verified";
import { useSelector } from "react-redux";

export default function Navigation() {
  // const { pathname } = useLocation();
  const [authToken, setAuthToken] = useState(null);
  const { userToken } = useSelector((state) => state.login);
  console.log("userToken: ", userToken);
  const landingPage = [
    "/user",
    "/editor",
    "/products",
    "/blog",
    "/login",
    "/signup",
    "/sign-in",
    "/dashboard",
    "/aispeechgeneration",
    "/aimusicgeneration"
  ];

  return (
    <React.StrictMode>
      {userToken != null ? (
        <Routes>
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/datasets" element={<Dashboard />} />
          <Route path="/pricing" element={<Dashboard />} />
          <Route path="/voiceclone" element={<Dashboard />} />
          <Route path="/user-profile" element={<Dashboard />} />
          <Route path="/edit-profile" element={<Dashboard />} />
          <Route path="/user-billing" element={<Dashboard />} />
          <Route path="/aispeechgeneration" element={<Dashboard />} />
          <Route path="/aivideogeneration" element={<Dashboard />} />
          <Route path="/audio-transcription" element={<Dashboard />} />
          <Route path="/image-generation" element={<Dashboard />} />
          <Route path="/pfeed" element={<Dashboard />} />
          <Route path="/dubbing" element={<Dashboard />} />
          <Route path="/aimusicgeneration" element={<Dashboard />} />
          <Route path="/" element={<Navigate to="/admin" replace />} />
          <Route path="*" element={<Navigate to="/admin" replace />} />
        </Routes>
      ) : (

        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/verify-email" element={<VerifiedUser />} />
          <Route path="*" element={<Navigate to="/sign-in" replace />} />
        </Routes>
      )}

      {/* <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/admin" element={<AdminLayout />} />
          <Route path="/datasets" element={<Dashboard />} />
          <Route path="/pricing" element={<Dashboard />} />
          <Route path="/voiceclone" element={<Dashboard />} /> */}

      {/* ///////////// */}
      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      {/* <Route path="/admin" element={<AdminLayout />} /> */}
      {/* <Route path="/datasets" element={<DataSets />} /> */}
      {/* <Route path="/pricing" element={<PricingPage />} /> */}
      {/* <Route path="/voiceclone" element={<Voices />} /> */}

      {/* <Route
        path="/auth"
        element={<Navigate to="/auth/sign-up/default" replace />}
      /> */}
      {/* <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes> */}
    </React.StrictMode>
  );
}
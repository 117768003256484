import axios from 'axios';
import {Flip, toast } from 'react-toastify';
import doteenv from './doteenv';
import { isEmpty } from './utils';
import { userLogout } from 'reduxStore/action/authAction';
import store from 'reduxStore/store';

const httpClient = axios.create({
  baseURL: doteenv.BASE_URL,
});

export function setDefaultHeader(headers, value) {
  httpClient.defaults.headers.common[headers] = value;
}

const encodeNewlines = (text) => text.replace(/\n/g, '\\n');
const decodeNewlines = (text) => text.replace(/\\n/g, '\n');

const isObjectId = (value) => {
  return typeof value === 'string' && value.length === 24 && /^[a-fA-F0-9]{24}$/.test(value);
};

const extractStrings = (obj, path = '', result = {}) => {
  for (const key in obj) {
    if (typeof obj[key] === 'string' && !isObjectId(obj[key])) {
      result[path ? `${path}.${key}` : key] = encodeNewlines(obj[key]);
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        if (typeof item === 'string' && !isObjectId(item)) {
          result[path ? `${path}.${key}[${index}]` : `${key}[${index}]`] = encodeNewlines(item);
        } else if (typeof item === 'object' && item !== null) {
          extractStrings(item, `${path ? `${path}.` : ''}${key}[${index}]`, result);
        }
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      extractStrings(obj[key], path ? `${path}.${key}` : key, result);
    }
  }
  return result;
};

const injectStrings = (obj, strings, path = '') => {
  for (const key in obj) {
    const currentPath = path ? `${path}.${key}` : key;
    if (typeof obj[key] === 'string' && !isObjectId(obj[key])) {
      obj[key] = decodeNewlines(strings[currentPath]);
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        if (typeof item === 'string' && !isObjectId(item)) {
          obj[key][index] = decodeNewlines(strings[`${currentPath}[${index}]`]);
        } else if (typeof item === 'object' && item !== null) {
          injectStrings(item, strings, `${currentPath}[${index}]`);
        }
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      injectStrings(obj[key], strings, currentPath);
    }
  }
};

const extractStringsFromFormData = (formData) => {
  const result = {};
  for (const [key, value] of formData.entries()) {
    if (typeof value === 'string' && !isObjectId(value)) {
      result[key] = encodeNewlines(value);
    }
  }
  return result;
};

const injectStringsIntoFormData = (formData, strings) => {
  const newFormData = new FormData();
  for (const [key, value] of formData.entries()) {
    if (strings[key]) {
      newFormData.append(key, decodeNewlines(strings[key]));
    } else {
      newFormData.append(key, value);
    }
  }
  return newFormData;
};

// const translateObject = async (obj) => {
//   if (isEmpty(obj)) return {};
//   const stringsToTranslate = extractStrings(obj);
//   const paths = Object.keys(stringsToTranslate);
//   const texts = paths.map((path) => stringsToTranslate[path]);
//   const translatedTexts = await translateText(texts);
//   const translatedStrings = paths.reduce((acc, path, index) => {
//     acc[path] = translatedTexts[index];
//     return acc;
//   }, {});

//   const translatedObj = JSON.parse(JSON.stringify(obj)); // Deep copy the original object
//   injectStrings(translatedObj, translatedStrings);

//   return translatedObj;
// };

// const translateFormData = async (formData) => {
//   if (!formData || !(formData instanceof FormData)) return formData;
//   const stringsToTranslate = extractStringsFromFormData(formData);
//   const texts = Object.values(stringsToTranslate);

//   const translatedTexts = await translateText(texts);

//   const translatedStrings = Object.keys(stringsToTranslate).reduce((acc, key, index) => {

//     // if(!isEmpty(obj?.state_of_mind) && obj?.state_of_mind.length > 0 && obj?.state_of_mind[]){

//     // }
//     acc[key] = translatedTexts[index];
//     return acc;
//   }, {});

//   return injectStringsIntoFormData(formData, translatedStrings);
// };

export async function apiCall(
  method,
  url,
  data,
  header = { 'Content-Type': 'application/json', }
) {
  try {
    const controller = new AbortController();
    let headers = {
      ...header,
      'access-control-allow-origin': '*',
      // "Authorization":  `Bearer ${response?.userToken}`,
    };

    let translatedData = data;
    
    // if (data instanceof FormData) { 
    //   translatedData = await translateFormData(data);
    // } else {
    //   translatedData = await translateObject(data);
    // }
   

    let res = await httpClient({
      method,
      url,
      data: translatedData,
      headers: headers,
      signal: controller.signal,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    // const errorCodes = [404, 500, 400];
    const errorCodes = [404, 500];
    let errorResponse = {
      data: { error: true, message: 'Internal server error. Please try again!' },
    };
    if (error.response && !errorCodes.includes(error.response.status)) {
      errorResponse = error.response;
    }
    if (error.response && error.response.status === 501) {
      store.dispatch(userLogout());
      window.location.assign('/sign-in');
      errorResponse = error.response;
    }
    return errorResponse;
  } finally {
  }
}
export const successMessage = (msg) => {
  toast.success(msg);
};
export const failedMessage = (msg) => {
  toast.error(msg);
};
export const warnMessage = (msg) => {
  toast.warn(msg);
};
export const infoMessage = (msg) => {
  toast.info(msg);
};
